
import { Alert, Snackbar } from "@mui/material"
import React, { useContext } from "react"
import { ApplicationContext } from "../../context/applicationCtx";
export default function ApplicationWrappers ({children}){
 return(
    <>
    {children}
    <GloablSnackBar/>
    </>
 )
}

const GloablSnackBar = ()=>{
    const { globalSnackbar,setGLobalSnackBar } = useContext(ApplicationContext);
    return(
        <Snackbar
        autoHideDuration={3000}
        sx={{zIndex:'99999'}}
        onClose={() =>
          setGLobalSnackBar({
            open: false,
            title: "",
            severity: "",
          })
        }
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={globalSnackbar?.open}
      >
        <Alert
          severity={globalSnackbar?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {globalSnackbar?.title}
        </Alert>
      </Snackbar>
    )
}