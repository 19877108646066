import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ApplicationContext } from "../../context/applicationCtx";
import { stringAvatar } from "../../utility/stringManipulation";

function AvatarComp() {
  const { getUserDetail_CTX, setActiveModule } = useContext(ApplicationContext);
  return (
    // avatar for user
    <Box
      onClick={() => {
        setActiveModule(3);
        localStorage.setItem("state", 3);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Tooltip
        placement="right"
        title={<Typography sx={{ fontSize: "12px" }}>Profile</Typography>}
      >
        <Avatar
          {...stringAvatar(
            `${getUserDetail_CTX.firstName} ${getUserDetail_CTX.lastName}`
          )}
          sx={{ height: "6vh", width: "6vh", background: "red",textTransform:"uppercase" }}
        />
      </Tooltip>
    </Box>
  );
}

export default AvatarComp;