import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainApp from "../pages/auth/main-app";
import Page404 from "../pages/404Page";
import SignIn from "../pages/signin";
import MainSuper from "../pages/auth/main-superadmin";
import Register from "../pages/register";
import ForgotPassword from "../pages/forgotpassword";
import { CONSOLE_MODE } from "../constants/api";
import PurchaseReview, { PurchaseRedirect } from "../components/license/purchaseReview/purchaseReview";

// Non-Working Routes Packages

/** import Register from '../pages/register';
import AuthMain from '../pages/auth/main';
import LicenseStep from '../pages/get-started/license';
import ForgotPassword from '../pages/forgotpassword';
import ResetPassword from '../pages/resetpassword';
import SecureAccount from '../pages/secureaccount';
import BasicTable from '../components/license';
import NavigationRail from '../components/navigation-rail/NavigationRail';
import PaymentMethod from '../components/payment-method';
import OtpStep from '../pages/verification';
import UserDetails from '../components/user-details';
import VerifyForgotPassword from '../pages/verify-forgotPassword'; **/

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
export default function RoutesList(props) {
  if (CONSOLE_MODE.mode === "production") {
    console.log = function () {};
  }
  return (
    <Routes>
      {/* Non-Working Routes! Can be used for direct access to make changes to pages */}
      {/* <Route exact path='/navigation-rail' element={<NavigationRail/>} />
            <Route exact path='/verification' element={<OtpStep/>} />
             <Route exact path='/table' element={<BasicTable/>} />
             <Route exact path='/payment-method' element={<PaymentMethod/>} />
            <Route exact path='/secure-account' element={<SecureAccount/>} />
            <Route exact path='/reset-password' element={<ResetPassword/>} />
            <Route exact path='/register' element={<Register/>} />
            <Route exact path='/authenticating' element={<AuthMain />} />
            <Route exact path='/licence' element={<LicenseStep />} />
            <Route exact path='/user-details' element={<UserDetails/>} />
            <Route exact path='/verify-forgotPassword' element={<VerifyForgotPassword/>} /> */}

      {/* Working Routes */}
      <Route exact path="/login" element={<SignIn />} />
      <Route exact path="/licence" element={<MainApp />} />
      <Route path="/licence/purchasereview" element={<PurchaseReview />} />
      <Route exact path="/licence/purchaseredirect" element={<PurchaseRedirect />} />
      <Route exact path="/superadmin" element={<MainSuper />} />
      <Route path="*" element={<Page404 />} />
      <Route path="" element={<Navigate replace to="/login" />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}
