import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    status:{
        muted: '#666666',
        error:"#781023"
    },
    palette: {
        type: 'light',
        primary: {
            main: '#4D4DFF',
            error: "#781023"
        },
        secondary: {
            main: '#9428FF',
        },
        
    },
});

export default theme;

