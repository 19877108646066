import { Box, Typography } from "@mui/material"
import React from "react"
export default function NoDataAvailable({message}){
    return(
        <Box
        sx={{
          overflow: "hidden",
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className="emptyCellTxt"
          variant="h3"
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: "29px",
            zIndex: "11",
            background: "#F4F7FC",
            padding: "20px",
            borderRadius: "15px",
          }}
        >
          {message}
        </Typography>
      </Box>
    )
}