import axios from "axios";
import { API, API_HOST, API_METHOD } from "../../constants/api";
import { HttpRequest } from "../../utility/http-request";

export const RegisterUser = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.account + API.register,
      body: body,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const SignInUser = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.account + API.authenticate,
      body: body,
      isAuthenticated: false,

      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const  ForgotPasswordUser = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.account + API.initforgotPassword,
      body: body,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const resendVerifyCode = async (body,token) => {
  console.log(token,'controllerToken')
  try {
    let response = await axios({
      method: API_METHOD.get,
      url: API_HOST.hostUrl + API.user + API.resentVerifyCode,
      // data:body,
      headers: {
        Authorization:
          token
            ? `Bearer ${token}`
            : `${null}`,
      },
    });
    console.log(response,'controllerResp')
    return response;
  } catch (error) {
    console.log(error.response,'controllerError')
    return error.response;
  }
};

export const VerifyUser = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.account + API.verifyforgotPasswordCode,
      body: body,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const ConfirmPasswordUser = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.account + API.confirmForgotPassword,
      body: body,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const verifyOtps = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.user,
      body: body,
      isAuthenticated: true,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
          console.log(res.status);
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getUserDetails = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.get,
      url: API_HOST.hostUrl + API.user,
      body: body,
      isAuthenticated: true,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })

      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
          console.log(res.status);
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const changePassword = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.user + API.changePassword,
      body: body,
      isAuthenticated: true,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
          console.log(res.status);
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const updateUserDetails = async function (body) {
  try {
    let httpRequest = new HttpRequest({
      method: API_METHOD.put,
      url: API_HOST.hostUrl + API.user,
      body: body,
      isAuthenticated: true,
      // contentType: ContentType.formData,
    });

    let res = null;
    await httpRequest
      .sendRequest()
      .then(function (data) {
        res = data;
      })
      .catch(function (apiError) {
        if (apiError.response) {
          res = apiError.response;
          console.log(res.status);
        } else {
          new Error(apiError);
        }
      });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const verifyOtp = async (body,token) => {
  try {
    let response = await axios({
      method: API_METHOD.post,
      url: API_HOST.hostUrl + API.user,
      data:body,
      headers: {
        Authorization:
          token
            ? `Bearer ${token}`
            : `${null}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const superAdminList = async () => {
  try {
    let response = axios({
      method: API_METHOD.get,
      url: API_HOST.hostUrl + API.user + API.superadmin,
      // data:body,
      headers: {
        Authorization:
        localStorage.getItem("super-key") !== null
        ? `Bearer ${localStorage.getItem("super-key")}`
        : `${null}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
