import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { ForgotPasswordUser } from "../../services/user-controller";
import { StatusCodes } from "http-status-codes";
import { ApplicationContext } from "../../context/applicationCtx";
import validator from "validator";
import VerifyForgotPassword from "../verify-forgotPassword";
import ResetPassword from "../resetpassword";
import { useNavigate } from "react-router-dom";
import {
  ButtonMaterial,
  TextFieldTA,
  text_col,
} from "../../constants/constStyles";
export default function ForgotPassword() {
  const forgotQuery = useMediaQuery("(max-width:660px)");

  const { setUserModule, getUserModule, setPassword_CTX } =
    useContext(ApplicationContext);
  const [error, setError] = useState();
  const [username, setUsername] = useState(null);
  const [usernameChange, setUsernameChange] = useState({
    hasError: false,
    erroMsg: "",
  });
  const [getLoaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();

  const routeChange = () => {
    // setUsername('')
    navigate("/login");
    // setUserModule(0);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setUsername(e.target.value);
  };
  const verifyInputs = async function () {
    // console.log(forgotpassword)
    if (!username) {
      setUsername("");
      // setError('')
      // setUsernameChange({...usernameChange,erroMsg:'Fields cant be empty',hasError:false})
    }
    // else{

    if (validator.isEmail(username)) {
      // setUsernameChange(false)
      // setUsernameChange({...usernameChange,erroMsg:'',hasError:false})
      try {
        setLoaderState(true);

        let response = await ForgotPasswordUser({
          username: username,
        });
        if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setLoaderState(false);
          setError("Something went wrong");
        }
        if (!response) {
          // alert('Something went wrong!')
          setError(`**Unable to connect to server!**`);
          setLoaderState(false);
        }
        if (response.status === StatusCodes.OK) {
          // navigate('/verify-forgotpassword');
          setPassword_CTX(username);
          setUsername(null);
          setUserModule(1);
          // console.log(response,'response')
          setLoaderState(false);
        } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
          setError("**Fields Cant Be empty**");
          setLoaderState(false);
        }
        console.log(response);
        if (response.status === StatusCodes.OK) {
          console.log(response);
        } else {
          throw response;
        }
      } catch (err) {
        // console.log("ERR =>",err);
        setLoaderState(false);
        if (err.data) {
          // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
        } else {
          if (err.errorMsg) {
            // setStepLicenseKey(err)
            console.log(err.errorMsg);
          } else {
            console.error("UNKNOWN ERROR", err);
          }
        }
      }
    } else {
      // setError('Fields cant be empty')
      setUsernameChange({ ...usernameChange, erroMsg: "", hasError: true });
    }
    // }
  };
  return (
    <>
      {getUserModule === 0 ? (
        <>
          {" "}
          <Box
            className="mainBoxReg"
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: forgotQuery ? "column" : "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FAFAFA",
              // gap: "1%",
              // paddingTop: "1%",
            }}
          >
            <Box
              className="mainBoxReg"
              sx={{
                width: forgotQuery ? "91%" : "77%",
              height: "558px",
              // height: "77%",
                display: "flex",
                flexDirection: forgotQuery ? "column" : "row-reverse",
                alignItems: "center",
                background: "white",
                borderRadius: "19px",
                overflow: "hidden",
                boxShadow: "0px 1px 22px 0px #e9e9e9",
                // gap: "1%",
                // position:'relative'
                // paddingTop: "1%",
              }}
            >
              <Box
                className="respWidth"
                sx={{
                  display: "flex",
                  width: forgotQuery ? "100%" : "55%",
                  height: "100%",
                  // position: "absolute",
                  textAlign: "center",
                  overflow: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  width={forgotQuery ? "93%" : "60%"}
                  height="60%"
                  display="flex"
                  alignItems="center"
                  // justifyContent='center'
                  flexDirection="column"
                  gap="22px"
                >
                  <Typography
                    mt={5}
                    variant="h3"
                    component="h2"
                    sx={{
                      fontWeight: "medium",
                      fontSize: "45px",
                      color: text_col.col_primary,
                    }}
                  >
                    Forgot Password
                  </Typography>

                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      sx={{
                        color: "red",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: "2px",
                        fontSize: "10px",
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    width="100%"
                    gap="17px"
                    paddingBottom="20px"
                    flexDirection="column"
                  >
                    <TextFieldTA
                      onBlur={handleChange}
                      onChange={(e) =>
                        username === null ? setUsername(e.target.value) : ""
                      }
                      error={
                        username === null
                          ? false
                          : username === ""
                          ? true
                          : !validator.isEmail(username)
                          ? true
                          : false
                      }
                      helperText={
                        <Typography sx={{ fontSize: "10px", color: "red" }}>
                          {username === null
                            ? ""
                            : username === ""
                            ? "Fields cant be empty"
                            : !validator.isEmail(username)
                            ? "Email is not valid"
                            : ""}
                        </Typography>
                      }
                      id="outlined-basic"
                      placeholder="Email*"
                      variant="outlined"
                      sx={{ width: "100%%" }}
                    />

                    <ButtonMaterial
                      onClick={verifyInputs}
                      variant="contained"
                      disabled={getLoaderState ? true : false}
                      disableElevation
                      sx={{
                        pr: 2,
                        width: "100%",
                      }}
                    >
                      SEND VERIFICATION EMAIL{" "}
                      {getLoaderState ? (
                        <CircularProgress
                          size="1.2em"
                          sx={{
                            position: "absolute",
                            right: "0",
                            mr: 2,
                            color: "white",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </ButtonMaterial>
                    <Button
                      onClick={routeChange}
                      variant="outlined"
                      color="error"
                      disableElevation
                      sx={{
                        pr: 2,
                        width: "100%",
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                // className="respright"
                sx={{
                  width: "45%",
                  height: "100%",
                  // position: "absolute",
                  // marginLeft: "50%",
                  backgroundColor: text_col.col_primary,
                  textAlign: "center",
                  display: forgotQuery ? "none" : "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  // backgroundImage:'url(https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-3.svg)',backgroundSize:'cover'
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href="https://soliterata.com/"
                >
                  <img
                    width="190"
                    height="190"
                    style={{
                      borderRadius: "6px",
                      zIndex: 2,
                      position: "absolute",
                      left: "12px",
                      top: "-60px",
                    }}
                    src="https://soliterata.com/wp-content/uploads/2024/01/Untitled-design14.svg"
                    alt=""
                    loading="lazy"
                    // srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
                    sizes="(max-width: 150px) 100vw, 150px"
                  />
                </a>
                <img
                  style={{ objectFit: "contain" }}
                  alt='SoliteraTA'
                  src="https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-5.22.png"
                  width={"100%"}
                  height="100%"
                />
                <Typography
                  color="white"
                  fontSize="12px"
                  sx={{ cursor: "pointer" }}
                  position="absolute"
                  bottom="18px"
                  left="30px"
                  onClick={() =>
                    window.open("https://soliterata.com/", "__blank")
                  }
                >
                  www.soliterata.com
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <ActiveUser getUserModule={getUserModule} />
      )}
    </>
  );
}

export function ActiveUser({ getUserModule }) {
  switch (getUserModule) {
    case 0:
      return <ForgotPassword />;
    case 1:
      return <VerifyForgotPassword />;
    case 2:
      return <ResetPassword />;
    default:
      return <ForgotPassword />;
  }
}
