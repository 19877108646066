import React from 'react';
import SuperAdminDashboard from './SuperAdminDashboard';
import UserCertificationsProcess from './userCertificationsProcess';

const PageSwapper = () => {
  const isSuperAdmin = localStorage.getItem('super-key');

  if (isSuperAdmin) {
    return <SuperAdminDashboard />;
  } else {
    return <UserCertificationsProcess />;
  }
};

export default PageSwapper;