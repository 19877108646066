import axios from "axios";
import { API, API_HOST } from "../../constants/api";

export const superAdminLicense = async() =>{
    try{

        let  response = await axios({
            method: "get",
            url: API_HOST.hostUrl + API.user + API.superadmin,
            headers: {
              Authorization:
                localStorage.getItem("super-key") !== null
                  ? `Bearer ${localStorage.getItem("super-key")}`
                  : `${null}`,
            },
          })
          return response.data
    }
    catch(error){
  console.log(error.response)
  return error.response
    }
}