import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import * as React from "react";
// import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { ConfirmPasswordUser } from "../../services/user-controller";
import { StatusCodes } from "http-status-codes";
import { useContext } from "react";
import { ApplicationContext } from "../../context/applicationCtx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import {
  ButtonMaterial,
  ButtonOutlined,
  text_col,
} from "../../constants/constStyles";

export default function ResetPassword() {
  const [error, setError] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [getLoaderState, setLoaderState] = useState(false);
  const [ResetPSnack, setResetPSnack] = useState(false);
  const [transition, setTransition] = useState(false);
  const [sp, setSp] = useState(false);
  const { setUserModule, verify } = useContext(ApplicationContext);
  console.log(verify);

  const navigate = useNavigate();
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  const verifyInputs = async function () {
    // console.log(password)
    //   console.log(token)
    if (password === confirmPassword) {
      try {
        setLoaderState(true);

        let response = await ConfirmPasswordUser({
          newPassword: password,
          verifyToken: verify,
        });
        if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setLoaderState(false);
          setError("Something went wrong");
        }
        if (!response) {
          // alert('Something went wrong!')
          setError(`**Unable to connect to server!**`);
          setLoaderState(false);
        }
        if (response.status === StatusCodes.OK) {
          // navigate('/login');
          const setSnack = (Transition) => {
            setTransition(() => Transition);
            setResetPSnack(true);
          };
          setSnack();
          setTimeout(() => {
            setResetPSnack(true);
            navigate("/login");
            setUserModule(0);
          }, 3000);
          setLoaderState(false);
        } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
          setError("Fields cant be empty");
          setLoaderState(false);
        } else if (response.status === StatusCodes.BAD_REQUEST) {
          setError(response.data.message);
          setLoaderState(false);
        } else {
          throw response;
        }
      } catch (err) {
        // console.log("ERR =>",err);
        setLoaderState(false);
        if (err.data) {
          // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
        } else {
          if (err.errorMsg) {
            // setStepLicenseKey(err)
            console.log(err.errorMsg);
          } else {
            console.error("UNKNOWN ERROR", err);
          }
        }
      }
    } else {
      setError("Password should be same");
    }
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    confirmPassword: "",
    passwords: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    showPasswords: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          gap: "2%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonOutlined
          onClick={() => setUserModule(1)}
          sx={{ ml: 1, position: "absolute", top: "1%", left: "1%" }}
          variant="outlined"
        >
          <ArrowBackIcon />
          Back
        </ButtonOutlined>
        <Typography
          variant="h3"
          component="h2"
          sx={{
            textAlign: "center",
            fontWeight: "medium",
            fontSize: "40px",
            color: text_col.col_primary,
          }}
        >
          Reset Password
        </Typography>
        <Box
          className="respWidthRP"
          sx={{
            width: "22.9%",
            height: "92px",
            borderRadius: "10px",
            border: "2px solid black",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F8F9FA",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: "300",
              width: "100%",
              fontSize: "17px",
              textAlign: "center",
              color: "#4B4BF6",
            }}
          >
            {`Password min 8-char, 1-upper & lowercase, 1-symbol, 1-number`}.
          </Typography>
        </Box>
        <Box
          className="respWidthRP"
          mt={2}
          sx={{
            width: "22.9%",
            height: "1px",
            backgroundColor: "black",
            opacity: "0.1",
          }}
        ></Box>

        <Box
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            component="h2"
            sx={{
              color: "red",
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: "2px",
              fontSize: "10px",
            }}
          >
            {error}
          </Typography>
        </Box>
        <FormControl
          className="respWidthRP"
          error={
            password === ""
              ? false
              : password !== null && !validator.isStrongPassword(password)
              ? true
              : false
          }
          onBlur={handlePassword}
          onChange={(e) => setValues({ ...values, password: e.target.value })}
          sx={{
            width: "22.9%",
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-passwords">
            New Password*
          </InputLabel>
          <OutlinedInput
            autoComplete="new-password"
            id="outlined-adornment-passwords"
            type={sp ? "text" : "password"}
            value={values?.password}
            onBlur={handlePassword}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                {/* <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setSp(!sp)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                > */}
                {sp ? (
                  <VisibilityOff onClick={() => setSp(false)} />
                ) : (
                  <Visibility onClick={() => setSp(true)} />
                )}
                {/* </IconButton> */}
              </InputAdornment>
            }
            label="New Password*"
          />
          <FormHelperText sx={{ fontSize: "10px", color: "red" }}>
            {password === ""
              ? ""
              : password !== null && !validator.isStrongPassword(password)
              ? "Password min 8-char, 1-upper & lowercase, 1-symbol, 1-number"
              : ""}
          </FormHelperText>
        </FormControl>
        <FormControl
          className="respWidthRP"
          error={
            confirmPassword === ""
              ? false
              : confirmPassword !== password
              ? true
              : false
          }
          onBlur={handleConfirmPassword}
          sx={{ width: "22.9%" }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-passwords">
            Confirm New Password*
          </InputLabel>
          <OutlinedInput
            onPaste={(e) => e.preventDefault()}
            autoComplete="new-password"
            id="outlined-adornment-passwords"
            type={values.showPassword ? "text" : "password"}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            endAdornment={
              <InputAdornment position="end">
                {values.showPassword ? (
                  <VisibilityOff
                    onClick={() =>
                      setValues({
                        ...values,
                        showPassword: !values.showPassword,
                      })
                    }
                  />
                ) : (
                  <Visibility  onClick={() =>
                    setValues({
                      ...values,
                      showPassword: !values.showPassword,
                    })
                  } />
                )}
              </InputAdornment>
            }
            label="Confirm New Password*"
          />
          <FormHelperText sx={{ fontSize: "10px", color: "red" }}>
            {confirmPassword === ""
              ? ""
              : confirmPassword !== password
              ? "Password doesn't match"
              : ""}
          </FormHelperText>
        </FormControl>

        <ButtonMaterial
          className="respWidthRP"
          onClick={verifyInputs}
          variant="contained"
          disabled={
            !validator.isStrongPassword(values.password) ||
            values.password !== values.confirmPassword || getLoaderState
              ? true
              : false
          }
          disableElevation
          sx={{
            pr: 2,
            width: "22.9%",
            fontSize: "11px",
          }}
        >
          Change Password{" "}
          {getLoaderState ? (
            <CircularProgress
              size="1.2em"
              sx={{
                position: "absolute",
                right: "0",
                mr: 1,
                color: "white",
              }}
            />
          ) : (
            ""
          )}
        </ButtonMaterial>
      </Box>

      <Snackbar
        style={{ justifyContent: "center", width: "100%" }}
        open={ResetPSnack}
        TransitionComponent={transition}
        message="Password reset successfully! Redirecting to login"
        key={transition ? transition.name : ""}
        autoHideDuration={2000}
        bodyStyle={{ minWidth: 20 }}
      />
    </>
  );
}
