import { API, API_HOST, API_METHOD } from "../../constants/api";
import { ContentType, HttpRequest } from "../../utility/http-request";


export const  getCertificateQuestions = async function() {
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            url: API_HOST.hostUrl + API.superAdminQuestions,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };

        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const addCertificateQuestions = async function(body) {
    console.log("Request body:", body); // Log the request body for debugging
    try {
        let http = new HttpRequest({
            method: API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.superAdminQuestions,
            isAuthenticated: true,
            contentType: ContentType.formData
        });

        let res = await http.sendRequest();

        // Log response for debugging
        console.log("API response:", res);

        return res; // Return the successful response
    } catch (e) {
        console.error("Error caught in catch block:", e); // Log the entire error object

        // Check if the error has a response object and is not null
        if (e.response) {
            // Log the response data to inspect its structure
            console.log("Error response data:", e.response);

            // Extract error message or provide a generic message
            const errorMessage = e.response.data?.message || 
                                 e.response.data?.error || 
                                 "An error occurred.";
            
            // If response has status 400, display a specific message
            if (e.response.status === 400) {
                console.warn("Bad Request - Specific error handling can be implemented here.");
            }

            return { errors: [errorMessage] };
        } else {
            // Log a generic error message if no response is available
            console.error("Unknown error occurred:", e);
            return { errors: ["An unknown error occurred."] };
        }
    }
};

export const downloadQuestionTemplate = async function() {
    try {
        const response = await fetch(API_HOST.hostUrl + API.superAdminQuestionTemplate, {
          method: 'POST', // Use POST method
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('api-key')}`,
            'Content-Type': 'application/json',
          },
        });

        // Check if the response is successful
        if (response && response.ok) {
            // Convert response to blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob); // Create a URL for the blob

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_Questions_Import.xlsx'); // Set the filename
            document.body.appendChild(link);
            link.click(); // Trigger the download
            link.parentNode.removeChild(link); // Clean up the link
            window.URL.revokeObjectURL(url); // Clean up the object URL
        } else {
            console.error("Failed to download the template:", response.status, response.statusText);
            const errorText = await response.text(); // Extract error text if available
            console.error("Error response body:", errorText);
        }

    } catch (e) {
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
};

export const  editCertificateQuestions = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.put,
            body: body,
            url: API_HOST.hostUrl + API.superAdminQuestions,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();
        console.log(res);

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  deleteCertificateQuestions = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.delete,
            body: body,
            url: API_HOST.hostUrl + API.superAdminQuestions,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  getCertificateList = async function() {
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            url: API_HOST.hostUrl + API.superAdminCertificate,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();
        console.log(res,"res");

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}


export const  getCertificateListUser = async function() {
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            url: API_HOST.hostUrl + API.userCertificate,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();
        console.log(res,"res");

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  addCertificate = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.superAdminCertificate,
            isAuthenticated : true,

        }); 
        
        let res  = await http.sendRequest();
        
        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  editCertificates = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.put,
            body: body,
            url: API_HOST.hostUrl + API.superAdminCertificate,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();
        console.log(res);

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  deleteCertificates = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.delete,
            body: body,
            url: API_HOST.hostUrl + API.superAdminCertificate,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  UserCertificateIntiate = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.userCertificate,
            isAuthenticated : true,
        }); 
        
        let res  = await http.sendRequest();
        
        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  IssueCertificateAPI = async function(body) {
    console.log("body" + body);
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.put,
            body: body,
            url: API_HOST.hostUrl + API.userCertificate,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();
        console.log(res,'res');

        return res;
    }catch(e){
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}

export const  UserCertificateDownload = async function(body) {
    try {
        const response = await fetch(API_HOST.hostUrl + API.userDownloadCertificate, {
          method: 'POST', // Use POST method
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('api-key')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        });

        // Check if the response is successful
        if (response && response.ok) {

            // Convert response to blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob); // Create a URL for the blob

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'UserCerticate.pdf'); // Set the filename
            document.body.appendChild(link);
            link.click(); // Trigger the download

            link.parentNode.removeChild(link); // Clean up the link
            window.URL.revokeObjectURL(url); // Clean up the object URL
        } else {
            console.error("Failed to download the template:", response.status, response.statusText);
            const errorText = await response.text(); // Extract error text if available
            console.error("Error response body:", errorText);
        }

    } catch (e) {
        if (e.response) {
            return { errors: e.response.data.errors || [e.response.data.message] };
        } else {
            return { errors: ["An unknown error occurred."] };
        }
    }
}




