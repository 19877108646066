import { useState, useEffect } from 'react';
import {
  Button, TextField, Typography, Paper, Box, RadioGroup,
  FormControlLabel, Radio, Container,
  Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton, Card, CardContent, Snackbar, Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NoDataAvailable from "../../../lib/components/no-data";
//import { AccessTime, Cancel, Close, Description, Download, EmojiEvents, HourglassEmpty, Quiz, Score, WorkspacePremium } from '@mui/icons-material';
import { getCertificateList, getCertificateListUser, IssueCertificateAPI, UserCertificateDownload, UserCertificateIntiate } from '../../services/certificate-controller';
import { getUserDetails } from '../../services/user-controller';
import TimeIcon from '@mui/icons-material/Timer';
import WorkspacePremium from '@mui/icons-material/WorkspacePremium';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Description from '@mui/icons-material/Description';
import Quiz from '@mui/icons-material/Quiz';
import Download from '@mui/icons-material/Download';
import Close from '@mui/icons-material/Close';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import Score from '@mui/icons-material/Score';
import Cancel from '@mui/icons-material/Cancel';
import AccessTime from '@mui/icons-material/AccessTime';

const CertificateCard = ({ certificate, isDialog, downloadUserCertificate }) => {
  return (
    <Card
      sx={{
        margin: isDialog ? '2px 2px 2px 2px' : '12px',
        padding: isDialog ? '5px' : '20px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        position: 'relative',
        width: isDialog ? '250px' : '300px', // Adjust width based on isDialog value
        height: isDialog ? '95%' : '100%', // Adjust height based on isDialog value
      }}
    >
      {/* Status Label for Regular View */}
      {!isDialog && (

        <Box
          sx={{
            position: 'absolute',
            top: '3px',
            right: '0px',
            backgroundColor: '#FFFFFF',
            padding: '4px 8px',
            borderRadius: '12px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: certificate.status === 'VALID' ? 'green' : 'red',
            zIndex: 1,
          }}
        >
          {certificate.status}
        </Box>


      )}

      <CardContent>
        {!isDialog && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
              borderRadius: '12px',
              backgroundColor: 'white', // Dark background for a modern look
              boxShadow: '4px 4px 2px rgb(140,82,255,0.3)', // Stronger shadow for depth
              marginBottom: '32px', // More space below the title section
              color: '#8C52FF', // White text for contrast
            }}
          >
            {/* Icon Section */}
            <WorkspacePremium
              sx={{
                color: '#8C52FF', // Red color for a bold, standout icon
                fontSize: '40px', // Slightly larger icon for emphasis
                marginRight: '20px', // Space between the icon and text
              }}
            />

            {/* Text Section */}
            <Typography
              variant="h5" // Larger text for prominence
              component="div"
              sx={{
                fontWeight: '400', // Semi-bold for a modern touch
                letterSpacing: '0.5px', // Slight letter spacing for a sleek feel
              }}
            >
              {certificate.certificateName}
            </Typography>
          </Box>
        )}

        {/* Certificate Details in Dialog View */}


        {isDialog && (
          <>
            {/* Certificate Title with Icon */}
            <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: '5px', justifyContent: 'left' }}>
              <EmojiEvents sx={{ color: '#8C52FF', marginRight: '8px', fontSize: '2rem' }} />
              <Typography
                variant="h5"
                component="div"
                mb={2}
                sx={{
                  color: '#8C52FF',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {certificate.name}
              </Typography>
            </Box>

            {/* Card Content */}
            <Box
              sx={{
                padding: '0px', // Add padding for content spacing
              }}
            >
              {/* Certificate Details */}
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <Description sx={{ color: '#8C52FF', marginRight: '8px' }} />
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: '500', fontSize: '10px' }}>
                  Description: <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '10px' }}>{certificate.description || 'N/A'}</Typography>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <Score sx={{ color: '#8C52FF', marginRight: '8px' }} />
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: '500', fontSize: '10px' }}>
                  Pass Percentage: <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '10px' }}>
                    {Number.isFinite(certificate.passScore) && Number.isFinite(certificate.quizSize)
                      ? `${Math.min(Math.max((certificate.passScore / certificate.quizSize) * 100, 0), 100).toFixed(0)}%`
                      : 'N/A'}
                  </Typography>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <Quiz sx={{ color: '#8C52FF', marginRight: '8px' }} />
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: '500', fontSize: '10px' }}>
                  Quiz Size: <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '10px' }}>{certificate.quizSize || 'N/A'}</Typography>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <TimeIcon sx={{ color: '#8C52FF', marginRight: '8px' }} />
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: '500', fontSize: '10px' }}>
                  Test Duration: <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '10px' }}>{certificate.testDurationInMins ? `${certificate.testDurationInMins} mins` : 'N/A'}</Typography>
                </Typography>
              </Box>
            </Box>
          </>
        )}

        {/* Issued and Validity Dates for Regular View */}
        {!isDialog && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
            Issued At: <br />
            {new Date(certificate.issuedAt).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
            Valid Till: <br />
            {new Date(certificate.validTill).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </Typography>
        </Box>
        )}

        {/* Download Button for Regular View */}
        {!isDialog && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#8C52FF',
              color: '#FFF',
              '&:hover': {
                backgroundColor: '#6E3BCC',
              },
              padding: '10px 20px',
            }}
            startIcon={<Download />}
            onClick={() => { downloadUserCertificate(certificate.certificateName) }}
          >
            Download Certificate

          </Button>

        )}
      </CardContent>
    </Card>

  );

};

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  maxWidth: '600px',
  display: 'contents'
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const UserCertificateOperations = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(0); // 1 minute timer
  const [result, setResult] = useState(null);
  const [step, setStep] = useState(1); // Step 1: Initiation, Step 2: Q&A, Step 3: Result
  const [stepModule, setStepModule] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // 'error', 'warning', 'info', 'success'
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [attemptId, setAttemptId] = useState(0);
  const [selectedCertificate, setSelectedCard] = useState(null);
  const [userCertificates, setUserCertificates] = useState([]);
  const [timeUp, setTimeUp] = useState(false);
  const [showApplyScreen, setShowApplyScreen] = useState(true);

  const fetchCertificates = async () => {
    try {
      const response = await getCertificateList(); // Ensure correct API call for certificates
      if (response.data && Array.isArray(response.data)) {
        setCertificates(response.data);
      } else {
        setCertificates([]); // Default to an empty array if the response is invalid
      }
    } catch (error) {
      console.error("Error fetching certificates:", error);
      setCertificates([]); // Default to an empty array if there's an error
    }
  };

  const fetchUserCertificates = async () => {
    try {
      const response = await getCertificateListUser();
      if (response.data && Array.isArray(response.data.list)) {
        setUserCertificates(response.data.list); // Assuming response.data is an array of certificates
      } else {
        setUserCertificates([]); // Default to an empty array if the response is invalid
      }
    } catch (error) {
      console.error("Error fetching certificates:", error);
      setUserCertificates([]); // Default to an empty array if there's an error
    }
  };

  const handleResultDialogOpen = () => setOpenResultDialog(true);
  const handleResultDialogClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setCurrentQuestionIndex(0);
      setAnswers({}); setOpenResultDialog(false);
      setShowApplyScreen(true);
      fetchUserCertificates();
      setTimeUp(false);
    }
  }

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const [initiateDetails, setInitiateDetails] = useState({
    certificateName: '',
    systemInfoCode: '',
  });

  const [openApplyCertificateDialog, setOpenApplyCertificateDialog] = useState(false);

  // Authenticate user session
  const authenticateUserSession = async () => {
    try {
      setIsAuthenticating(true);
      const response = await getUserDetails();
      if (response.data) {
        console.log("User authenticated");
      } else {
        console.error("User not authenticated:", response);
      }
    } catch (error) {
      console.error("Error authenticating user:", error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    authenticateUserSession();
    fetchCertificates();
    fetchUserCertificates();
  }, []);

  useEffect(() => {
    if (step === 2 && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (step === 2 && timeLeft === 0) {
      setTimeUp(true);
      setStep(3); // Move to the result step
      handleResultDialogOpen(); // Open the result dialog
      setCurrentQuestionIndex(0);
      calculateResult();
    }
  }, [step, timeLeft]);

  const handleInitiateDetailsChange = (field, value) => {
    setInitiateDetails(prevDetails => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleClickCertificateCard = (certificateName) => {
    console.log(certificateName);
    setSelectedCard(certificateName);
    handleInitiateDetailsChange('certificateName', certificateName);
  };

  const initiateCertificate = async () => {
    if (initiateDetails.certificateName && initiateDetails.systemInfoCode) {
      try {
        console.log('Initiate Details:', initiateDetails);

        if (!isAuthenticating) {
          const body = {
            certificateName: initiateDetails.certificateName,
            systemInfoCode: initiateDetails.systemInfoCode,
          };

          console.log('Request Body:', body);

          const response = await UserCertificateIntiate(body);

          console.log('API Response:', response);
          console.log('API Response Data:', response.data);

          // Check if the response status is success
          if (response.status === 200 && response.data) {
            const { questions = [], durationInMins, attemptId } = response.data;
            setTimeLeft(durationInMins);
            setAttemptId(attemptId);

            // Validate the response data
            if (questions && durationInMins !== undefined && attemptId !== undefined) {
              console.log('Extracted Duration:', durationInMins);
              console.log('Extracted AttemptId:', attemptId);
              console.log('Extracted Questions:', questions);

              if (Array.isArray(questions) && questions.length > 0) {
                setQuestions(questions); // Set questions from the response
                let timeLeftInMins = durationInMins ? durationInMins * 60 : 0;
                setTimeLeft(timeLeftInMins);
                setShowApplyScreen(false);
                setStepModule(true);
                setStep(2); // Move to Q&A step
                setOpenApplyCertificateDialog(prevState => ({ ...prevState, step1: false, step2: true })); // Close step 1 dialog, open step 2 dialog
                setSnackbarSeverity('success');
                setSnackbarMessage('Certificate initiated successfully!');
                setOpenApplyCertificateDialog(false);
              } else {
                console.error('Questions data is not valid:', questions);
                setSnackbarSeverity('error');
                setSnackbarMessage('Received invalid questions data.');
              }
            } else {
              console.error('Invalid response data:', response.data);
              setSnackbarSeverity('error');
              setSnackbarMessage('Received invalid response data.');
            }
          } else {
            // Handle non-success responses or specific error messages
            const errorMessage = response.data?.message || 'An error occurred while initiating the certificate.';
            console.error('API Error:', errorMessage);
            setSnackbarSeverity('error');
            setSnackbarMessage(errorMessage);
          }
        }
      } catch (error) {
        console.error('Error initiating certificate:', error.response ? error.response.data : error.message);
        const errorMessage = error.response?.data?.message || error.message;
        setSnackbarSeverity('error');
        setSnackbarMessage(errorMessage);
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please fill in all fields.');
      setSnackbarOpen(true);
    }
  };

  const downloadUserCertificate = async (certificateName) => {
    try {

      // Show the initial message when the download starts
    setSnackbarSeverity('info');
    setSnackbarMessage('Certificate download started successfully.');
    setSnackbarOpen(true);

      await UserCertificateDownload(certificateName);
      // If no error is thrown, download is successful
      setSnackbarSeverity('success');
      setSnackbarMessage('Certificate downloaded successfully.');
    } catch (error) {
      // Handle different types of errors or use a generic message
      setSnackbarSeverity('error');
      setSnackbarMessage(error.message || 'Failed to download certificate.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = async () => {
    const formattedAnswers = Object.keys(answers).map(questionId => ({
      ans: answers[questionId],
      questionId: parseInt(questionId)
    }));

    console.log(formattedAnswers);

    // Prepare the data to match the required format
    const payload = {
      responses: formattedAnswers,
      attemptId: attemptId,  // Ensure attemptId is properly included
    };

    console.log(payload);


    try {
      const response = await IssueCertificateAPI(payload);

      console.log(response);

      if (response.status === 200 && response.data) {
        setResult(response.data?.message);
        console.log(response.data?.message, "response");
        setSnackbarSeverity('success');
        setSnackbarMessage('Quiz submitted successfully!');
        setStep(3); // Move to the result step
        handleResultDialogOpen(); // Open the result dialog
      } else {
        setResult(response.data?.message);
        console.log(response.data?.message, "response");
        throw new Error(response.data?.message || 'Failed to submit quiz.');
      }
    } catch (error) {
      console.error('Error submitting quiz:', error);
      //setSnackbarSeverity('error');
      //setSnackbarMessage(error.message || 'Failed to submit quiz.');
    } finally {
      setSnackbarOpen(false);
    }
  };



  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      calculateResult();
    }
  };

  const calculateResult = () => {
    setStep(3); // Move to result step
    setOpenResultDialog(true); // Open the result dialog
  };

  const handleCardClick = () => {
    setOpenApplyCertificateDialog(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpenApplyCertificateDialog(false);
      setSelectedCard(null);
    }
  };

  function StyledButton({ handleCardClick }) {
    return (
      <Button
        onClick={handleCardClick}
        style={{
          position: 'absolute',         // Fixed position
          top: '10px',               // Top-right corner
          right: '20px',
          width: '100px',
          height: '40px',
          backgroundColor: '#8C52FF', // Primary background color
          color: '#fff',              // White text color
          borderRadius: '10px',       // Rounded corners
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow
          display: 'flex',            // Flexbox for centering content
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          transition: 'transform 0.2s', // Smooth hover effect
          zIndex: 1000,              // Ensure it's above other content
        }}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}  // Scale on hover
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}    // Reset scale
      >
        <Typography variant="h6" align="center" style={{ display: 'flex', alignItems: 'center' }}>
          Apply
        </Typography>
      </Button>
    );
  }

  console.log("certificate render")
  return (
    <StyledContainer sx={{ width: "100vh", height: "100%" }}>
      {showApplyScreen && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            backgroundColor="#F4F7FCBF"
            justifyContent="center"
            padding="18px 0px 20px 11px"
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#393939",
              }}
            >
              CERTIFICATIONS
            </Typography>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Box minHeight="50vh" marginTop={0} display="flex" flex={1} justifyContent="left" alignItems="flex-start">
            {userCertificates.length > 0 ? (
              <Box display="flex" flexWrap="wrap">
                {userCertificates.map((certificate, index) => (
                  <CertificateCard key={index} certificate={certificate} downloadUserCertificate={downloadUserCertificate} isDialog={false} />
                ))}
              </Box>
            ) : (
              <Box display="flex" flex={1} justifyContent="center" alignItems="center" minHeight="inherit" height="100%">
                {/* <Typography variant="h4">no certificate found</Typography> */}
                <NoDataAvailable message={"No certificate found"} />
              </Box>
            )}
          </Box>

          <StyledButton handleCardClick={handleCardClick} />

          <Dialog 
  open={openApplyCertificateDialog} 
  onClose={handleDialogClose} 
  maxWidth="md" 
  fullWidth
>
  <DialogTitle
    sx={{
      backgroundColor: "#8C52FF",
      color: "white",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px 24px',
    }}
  >
    Apply Certificate
    <IconButton
      aria-label="close"
      onClick={handleDialogClose}
      sx={{
        color: 'white',
        padding: 0,
      }}
    >
      <Close />
    </IconButton>
  </DialogTitle>
  <DialogContent sx={{ padding: '24px' }}>
    <Typography variant="h6" sx={{ marginBottom: 2, color: "#333" }}>
      Select Certificate:
    </Typography>
    <Box 
      minHeight="14em" 
      sx={{ overflowX: 'auto', border: '1px solid #ddd', borderRadius: '8px', padding: '8px' }}
    >
      {certificates.length > 0 ? (
        <Box display="flex" flexWrap="nowrap" gap={2}>
          {certificates.map((certificate, index) => (
            <Box
              key={index}
              sx={{
                cursor: "pointer",
                border: selectedCertificate === certificate.name ? "2px solid #8C52FF" : "2px solid #e0e0e0",
                borderRadius: "16px",
                transition: "all 0.3s ease",
                width: '260px',
                height: '240px',
                backgroundColor: selectedCertificate === certificate.name ? "#F0E6FF" : "#fff",
                boxShadow: selectedCertificate === certificate.name ? "0px 4px 8px rgba(140, 82, 255, 0.4)" : "0px 2px 4px rgba(0, 0, 0, 0.1)",
                '&:hover': {
                  boxShadow: "0px 4px 12px rgba(140, 82, 255, 0.4)",
                }
              }}
              onClick={() => handleClickCertificateCard(certificate.name)}
            >
              <CertificateCard 
                certificate={certificate} 
                downloadUserCertificate={downloadUserCertificate} 
                isDialog={true} 
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="inherit"
        >
          <NoDataAvailable message={"No certificate found"} />
        </Box>
      )}
    </Box>
    <TextField
      label="System Info Code"
      value={initiateDetails.systemInfoCode}
      onChange={(e) => handleInitiateDetailsChange("systemInfoCode", e.target.value)}
      fullWidth
      margin="normal"
      multiline
      rows={4}
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        marginTop: 3,
        "& .MuiInputBase-input": {
          color: "#333",
          padding: '12px',
        },
        "& .MuiOutlinedInput-root": {
          borderColor: "#e0e0e0",
        },
      }}
    />
    <Typography variant="body2" sx={{ marginTop: '8px', color: 'red' }}>
      * You can copy the System Info Code from the Solitera TA tool under settings, then click on the Activate/Deactivate License button.
    </Typography>
  </DialogContent>
  <DialogActions sx={{ padding: '16px 24px' }}>
    <Button 
      variant="outlined" 
      onClick={handleDialogClose} 
      sx={{ 
        borderColor: "#8C52FF", 
        color: "#8C52FF", 
        textTransform: 'none', 
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: "#f0e6ff",
        },
      }}
    >
      Cancel
    </Button>
    <Button 
      variant="contained" 
      onClick={initiateCertificate} 
      sx={{ 
        backgroundColor: "#8C52FF", 
        color: "white", 
        textTransform: 'none', 
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: "#7a42e6",
        },
      }}
    >
      Next
    </Button>
  </DialogActions>
</Dialog>
        </>
      )}

      {step === 2 && stepModule && (
        <>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#8C52FF",
                color: "white",
                width: "100%",
                padding: 2,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Certification Quiz
              </Typography>
            </Box>

            <Box sx={{ padding: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  Question {currentQuestionIndex + 1} of {questions.length}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "black",
                    borderRadius: "12px",
                    padding: "15px 25px",
                    fontWeight: 'bold',
                    fontSize: '18px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(10px)',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  }}
                >
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds} left
                </Box>
              </Box>

              <StyledPaper sx={{ padding: 3, borderRadius: 4, boxShadow: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', marginBottom: 3 }}>
                  {questions[currentQuestionIndex]?.question || 'No question available'}
                </Typography>

                <RadioGroup
                  value={answers[questions[currentQuestionIndex]?.id] || ''}
                  onChange={(e) => handleAnswerChange(questions[currentQuestionIndex]?.id, e.target.value)}
                >
                  {[1, 2, 3, 4].map((i) => (
                    questions[currentQuestionIndex]?.[`ans${i}`] && (
                      <FormControlLabel
                        key={`ans${i}`}
                        value={questions[currentQuestionIndex][`ans${i}`]}
                        control={<Radio sx={{ color: '#8C52FF', '&.Mui-checked': { color: '#8C52FF' } }} />}
                        label={
                          <Typography
                            variant="body1"
                            sx={{
                              padding: 2,
                              borderRadius: 2,
                              '&:hover': { backgroundColor: '#f5f5f5' },
                              transition: 'background-color 0.3s ease',
                            }}
                          >
                            {questions[currentQuestionIndex][`ans${i}`]}
                          </Typography>
                        }
                      />
                    )
                  ))}
                </RadioGroup>
              </StyledPaper>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
            
                {/* Buttons Container on the Right */}
                <Box sx={{ display: 'flex', gap: 2 }}> {/* Add gap between Back and Next/Submit */}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#8C52FF",
                      borderRadius: '8px',
                      width: '100px',  // Adjust width as needed
                      height: '40px',
                      fontSize: '16px',
                      '&:hover': { backgroundColor: '#7A44E5' },
                    }}
                    onClick={() => {
                      if (currentQuestionIndex > 0) {
                        setCurrentQuestionIndex(currentQuestionIndex - 1);
                      }
                    }}
                    disabled={currentQuestionIndex === 0} // Disable when on the first question
                  >
                    Previous
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#8C52FF",
                      borderRadius: '8px',
                      width: '100px',  // Adjust width as needed
                      height: '40px',
                      fontSize: '16px',
                      '&:hover': { backgroundColor: '#7A44E5' },
                    }}
                    onClick={() => {
                      if (answers[questions[currentQuestionIndex]?.id]) {
                        if (currentQuestionIndex < questions.length - 1) {
                          nextQuestion();
                        } else {
                          handleSubmit();
                          calculateResult();
                          handleResultDialogOpen(true); // Show report dialog when all questions are answered
                        }
                      }
                      else {
                        setSnackbarSeverity('error');
                        setSnackbarMessage('Please select one of the following answer');
                        setSnackbarOpen(true);
                      }
                    }
                    }
                    disabled={currentQuestionIndex >= questions.length}
                  >
                    {currentQuestionIndex < questions.length - 1 ? 'Next' : 'Submit'}
                  </Button>
                </Box>

              </Box>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </>
      )}

      {step === 3 && (
        <Dialog open={openResultDialog} onClose={handleResultDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle
            sx={{
              backgroundColor: "#8C52FF",
              color: "white",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {timeUp ? (
              <>
                <AccessTime sx={{ marginRight: 1 }} /> Oops, time's up!
              </>
            ) : (
              "Result"
            )}
          </DialogTitle>
          <DialogContent>
            {timeUp ? (
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" p={2}>
                <Typography variant="h6" color="error" gutterBottom>
                  Unfortunately, your time has run out.
                </Typography>
                {result ? (
                  <Typography variant="body1">

                    {result}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    <Cancel color="error" sx={{ marginRight: 1 }} />
                    Please try again
                  </Typography>
                )}
              </Box>
            ) : result ? (
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" p={2}>
                <Typography variant="body1">

                  {result}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <HourglassEmpty color="action" sx={{ marginRight: 1 }} />
                <Typography variant="body1">Loading results...</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResultDialogClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledContainer>

  );
};


export default UserCertificateOperations;