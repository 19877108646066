

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringToArray(string) {
    if(string.split(' ').length < 2){
        return (
            string.split(' ')[0][0]
        )
    }else{
        return (
            `${string.split(' ')[0][0]}${string.split(' ')[1][0]}`
        )
    }
    
}
// `${name.split(' ')[0][0]}`

export function stringAvatar(name) {
    try{
        // if (_isNull(name)){
        //     throw("name is empty");
        // }

        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: stringToArray(name),
        };
    }catch(error){
        return error;
    }
    
}

export function stringSlice(string, minValue){
    try{
        // if (_isNull(string)){
        //     throw("name is empty");
        // }

        if(string.length > minValue){
            return `${string.slice(0, minValue)}.....`
        }else{
            return string
        }
    }catch(error){
        return error
    }
    
}