import { API, API_HOST, API_METHOD} from "../../constants/api";
import { HttpRequest } from "../../utility/http-request";


export const addCard = async function(body){
    console.log(body)
    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.post,
                url: API_HOST.hostUrl + API.finance + API.card,
                body: body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}



export const getCardList = async function(body){

    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.get,
                url: API_HOST.hostUrl + API.finance + API.card,
                body: body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}

export const cardDelete = async function(body){

    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.delete,
                url: API_HOST.hostUrl + API.finance + API.card,
                body: body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}

export  const getClientIntent = async function(getId){
   
    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.get,
                url: API_HOST.hostUrl + API.licenses + API.confirm + `/${getId}`,
                // body:body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}


export  const getPaymentDetails = async function(getId){
   
    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.get,
                url: API_HOST.hostUrl + API.licenses + API.payment,
                // body:body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}

export  const approvePayments = async function(body){
   
    try {

        let httpRequest = new HttpRequest(
            {
                method: API_METHOD.post,
                url: API_HOST.hostUrl + API.licenses + API.payment,
                body:body,
                isAuthenticated:true,
                // contentType: ContentType.formData,
            }
        );
 
        let res = null;
        await httpRequest.sendRequest()
            .then(function (data) {
                res = data;
            })
            .catch(function (apiError) {
                if (apiError.response) {
                    res = apiError.response;
                    console.log(res.status);
                } else {
                    new Error(apiError);
                }
            });
        return res;

    } catch (e) {
        console.log(e);
        return e;
    }

}
