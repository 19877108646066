import { useContext } from "react";
import { ApplicationContext } from "../context/applicationCtx";
import { Severity } from "../constants/api";
import { StatusCodes } from "http-status-codes";

export default function useErrorHandler() {
  const { setGLobalSnackBar } = useContext(ApplicationContext);
  const errorHandler = (response, setLoader,setOpen,handleClose) => {
    console.log(response,setLoader,'customHook')
    if (response.status === StatusCodes.OK) {
      handleSnackbar(response, Severity.success,setLoader );
    }
    if (response.status === StatusCodes.NOT_FOUND) {
      handleSnackbar(response, Severity.error,setLoader );
    }
    if (response.status === StatusCodes.BAD_REQUEST) {
      handleSnackbar(response, Severity.error,setLoader );
    }
    if (response.status === StatusCodes.BAD_GATEWAY) {
      handleSnackbar(response, Severity.error,setLoader );
    }
    if (response.status === StatusCodes.NOT_ACCEPTABLE) {
      handleSnackbar(response, Severity.error,setLoader );
    }
    if (response.status === StatusCodes.TOO_MANY_REQUESTS) {
      handleSnackbar(response, Severity.warning,setLoader );
    }
    if (response.status === StatusCodes.CONFLICT) {
      handleSnackbar(response, Severity.error,setLoader );
    }
    if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        handleSnackbar(response, Severity.error,setLoader );
      }
  };

  const handleSnackbar = (response, severity, setLoader,setOpen,handleClose) => {
    setGLobalSnackBar({
      open: true,
      title: response.data.message,
      severity: severity,
    });
    setLoader && setLoader(false);
    // setOpen(false);
    // handleClose()
  };

  return errorHandler;
}


export const globalError = {open:null}