import { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import NavigationRail from "../../components/navigation-rail/NavigationRail";

// Pages
import { ApplicationContext } from "../../context/applicationCtx";
import UserDetails from "../../components/user-details";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { LoaderSpinner } from "../../components/loader/loader";

import DrawerComp from "../../components/drawer";
import LicenseTable from "../../components/license";
import PaymentDetails from "../../components/payment-details";
import PageSwapper from "../../components/certification/pageSwapper";
import { ModuleType } from "../../constants/application";

export default function MainApp(props) {
  const {
    userDetail_CTX,
    getActiveModule,
    setActiveModule,
    getLoaderStateSpinner,
    setLoaderStateSpinner,
  } = useContext(ApplicationContext);

  const navigate = useNavigate();

  const match = useMediaQuery("(max-width:1200px)");
 useEffect(() => {
    if (!localStorage.getItem("api-key")) {
      navigate("/login");
      sessionClear();
    }
    if (localStorage.getItem("super-key") === "") {
      navigate("/login");
      sessionClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const sessionClear = () => {
    window.localStorage.clear();
    localStorage.setItem("state", 0);
    setActiveModule(ModuleType.table);
  };

  return (
    // Box Start
    <>
      {getLoaderStateSpinner ? <LoaderSpinner /> : ""}
      <Box
        display="flex"
        flexDirection={match ? "column" : "row"}
        width="100vw"
        height="100vh"
      >
        {match ? (
          <Box width="100%">
            <DrawerComp
              getActiveModule={getActiveModule}
              setActiveModule={setActiveModule}
              setLoaderState={setLoaderStateSpinner}
            />
          </Box>
        ) : (
          <Box height="100%" width="7%" minWidth="85px">
            <NavigationRail
              getActiveModule={getActiveModule}
              setActiveModule={setActiveModule}
              setLoaderState={setLoaderStateSpinner}
            />
          </Box>
        )}
        <Box
          sx={{
            width: match ? "100%" : "93%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >

          <ActiveModule
            userDetail_CTX={userDetail_CTX}
            value={getActiveModule}
          />
        </Box>
      </Box>
    </>
    // Box End
  );
}

/* Production */
const ActiveModule = function (props) {
  switch (props.value) {
    case 0:
      return <LicenseTable {...props} />;
    case 1:
      return <PaymentDetails {...props} />;
    case 3:
      return <UserDetails {...props} />;
    case 4:
      return <PageSwapper {...props}/>;
    default:
      return <LicenseTable {...props} />;
  }
};

/* Development */
// const ActiveModule = function (props) {
//   switch (props.value) {
//     case 0:
//       return <LicenseTable {...props} />;
//     case 1:
//       return <PaymentMethod {...props} />;
//     case 2:
//       return <PendingQuery {...props} />;
//     case 3:
//       return <UserDetails {...props} />;
//     default:
//       return <LicenseTable {...props} />;
//   }
// };
