import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const LoaderSpinner = () =>{
    return(
        <Box
        sx={{
          position: "absolute",
          zIndex: "13",
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,20%)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column-reverse",
          alignItems: "center",
          backdropFilter: "blur(3px)",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    )
}