import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import { ModuleType } from "../../constants/application";
import {
  LicenseIcon,
  PaymentIcon,
  ProfileIcon,
  CertificationIcon,
  // TicketIcon,
  // TicketIcon,
  // SoliteraTA,
  UserGuideIcon,
} from "../../constants/icons";
import { useContext } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationContext } from "../../context/applicationCtx";
import AvatarComp from "../avatar";
import { useEffect } from "react";
import { getUserDetails } from "../../services/user-controller";
import { text_col } from "../../constants/constStyles";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export default function NavigationRail({
  getActiveModule,
  setActiveModule,
  setLoaderState,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getUserDetail_CTX, setUserDetail_CTX } =
    useContext(ApplicationContext);

  useEffect(() => {
    getUserDetails()
      .then(function (apiData) {
        setUserDetail_CTX(apiData.data);
        setLoaderState(false);
        if (!apiData) {
          window.localStorage.clear();
          navigate("/login");
        }
      })
      .catch(function (apiError) {
        console.log(apiError);
      });
  }, [setUserDetail_CTX, navigate, setLoaderState]);

  /* Production */

  const data = [
    {
      id: 1,
      label: !localStorage.getItem("super-key") ? "Licence" : "User",

      icon: (
        <LicenseIcon
          color={
            getActiveModule === 0 ? text_col.col_primary : theme.status.muted
          }
          size="35"
        />
      ),
    },
    {
      id: 2,
      label: "Payment Request",
      icon: (
        <PaymentIcon
          color={
            getActiveModule === 1 ? text_col.col_primary : theme.status.muted
          }
          size="30"
        />
      ),
      // icon: <PaymentIcon />
    },

    {
      id: 4,
      label: "User Guide",
      icon: <UserGuideIcon color={theme.status.muted} size="38" />,
    },
    {
      id: 5,
      label: "Profile",
      icon: (
        <ProfileIcon
          color={
            getActiveModule === 3 ? text_col.col_primary : theme.status.muted
          }
          size="38"
        />
      ),
    },
    {
      id: 6,
      label: "Certification",
      icon: (
        <CertificationIcon
          color={
            getActiveModule === 4 ? text_col.col_primary : theme.status.muted
          }
          size="48"
        />
      ),
    }, 
  ];

  const handleModule = (index) => {
    index === 2
      ? window.open("https://soliterata.com/docs/")
      : setActiveModule(index);
    // setLoaderState(true);
    localStorage.setItem("state", index);
  };

  console.log(getActiveModule, "module");

  return (
    //* NavigationRail Start
    <>
      <Box
        display={"flex"}
        flexDirection="column"
        height="100vh"
        bgcolor="#ffffff"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        zIndex="12"
      >
        {/* 
            //!    NavigationRail Header Start 
            */}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="15px"
          height="13%"
          // paddingLeft={"50px"}
        >
          {/* Main LOGO - Start */}
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href="https://soliterata.com/"
          >
            <img
              width="42"
              height="42"
              style={{ borderRadius: "4px" }}
              src="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png"
              alt=""
              loading="lazy"
              srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
              sizes="(max-width: 150px) 100vw, 150px"
            />
          </a>
          {/* <SoliteraTA /> */}
        </Box>

        {/* Avatar for NAvigationRail */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <AvatarComp />

          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "17px",
              color: "#676767",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Hi, {getUserDetail_CTX.firstName}
          </Typography>
        </Box>
        {/* 
            //!     NavigationRail Header End 
            */}

        {/* 
            //! NavigationRail Main Start 
            */}
        <Box
          className="navResp"
          height="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" gap={"15px"}>
            {data.map((arr, index) => (
              <Box
                display={"flex"}

                style={{ cursor: "pointer" }}
                flexDirection="column"
                alignItems={"center"}
                key={arr.id}
                onClick={() => handleModule(index)}
              >
                {arr.icon}
                <Typography
                  color={
                    getActiveModule === index
                      ? text_col.col_primary
                      : index === 2
                      ? theme.status.muted
                      : theme.status.muted
                  }
                  fontSize={"12px"}
                  textAlign="center"
                >
                  {arr.label}
                </Typography>
              </Box>
              // </a>
            ))}
          </Box>
        </Box>

        {/* 
                //! NavigationRail Main End 
            */}

        {/* NavigationRail Footer Start */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flex="1"
          gap="37px"
        >
          <Tooltip
            placement="right"
            title={
              <Typography sx={{ fontSize: "12px" }}>
                Download SoliteraTA
              </Typography>
            }
          >
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              href="https://soliterata.com/downloads/"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadForOfflineIcon
                sx={{ fontSize: "49px", color: text_col.col_primary }}
              />
              <Typography sx={{ color: text_col.col_primary }}>
                Download
              </Typography>
            </a>
          </Tooltip>

          <Link to="/login" style={{ textDecoration: "none", color: "black" }}>
            <Button
              onClick={() => {
                window.localStorage.clear();
                localStorage.setItem("state", 0);
                setActiveModule(ModuleType.table);
              }}
              variant="contained"
              sx={{
                backgroundColor: text_col.col_primary,
                borderRadius: "22px",
                color: "ffffff",
              }}
            >
              Logout
            </Button>
          </Link>
        </Box>
      </Box>
      {/* // NavigationRail End */}
    </>
  );
}
