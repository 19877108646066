import { useState, createContext } from "react";

export const ApplicationContext = createContext();

export const ApplicationContextProvider = (props) => {
  //* States
  const [getActiveModule, setActiveModule] = useState(
    +localStorage.getItem("state")
  ); //default environment
  const [getUserModule, setUserModule] = useState(0);
  const [getUserModuleLogin, setUserModuleLogin] = useState(0);
  const [getReloadModuleData, setReloadModuleData] = useState(false);
  const [getLoaderStateSpinner, setLoaderStateSpinner] = useState(true);
  const [loginToken, setLoginToken] = useState(null);
  const [colorChange, setColorChange] = useState(localStorage.getItem("color"));
  const [username, setUsername] = useState(null);
  const [usernameLogin, setUsernameLogin] = useState(null);

  const [getSneakBar, setSneakBar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    message: "",
  });
  const [globalSnackbar, setGLobalSnackBar] = useState({
    open: false,
    title: "",
    severity: "",
  });
  // Application Filter
  const [filter, setFilter] = useState("Submitted");

  // Payment CArds
  const [paymentUrl_CTX, setPaymentUrl_CTX] = useState("");
  const [buySnack, setBuySnack] = useState(false);
  const [renewSnack, setRenewSnack] = useState(false);
  const [paymentDetials, setPaymentDetails] = useState({});

  const [getCardsList_CTX, setCardList_CTX] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  // License List
  const [getLicenseList_CTX, setLicenseList_CTX] = useState([]);
  const [paymentScroller, setPaymentScroller] = useState("");

  // Allocated and Deallocate
  const [getLicenseAl_CTX, setLicenseAl_CTX] = useState([]);
  const [getLicenseListLeads_CTX, setLicenseListLeads_CTX] = useState([]);
  const [limitationsData, setLimitationsData] = useState({});

  //user details
  const [getUserDetail_CTX, setUserDetail_CTX] = useState([]);

  const [getClientCode_CTX, setClientCode_CTX] = useState(null);

  const [getProtected_CTX, setProtected_CTX] = useState();
  const [state, setState] = useState(0);

  /* Pricing data */
  const [getPricing_CTX, setPricing_CTX] = useState({});

  // Admin Panel
  const [getLicenseSup_CTX, setLicenseSup_CTX] = useState([]);
  const [getSupUserDetail_CTX, setSupUserDetail_CTX] = useState([]);

  // Forgot Password
  const [getPassword_CTX, setPassword_CTX] = useState();
  const [verify, setVerify] = useState();

  /* User Guide */
  const [userGuide, setUserGuide] = useState(false);
  //ticket
  const tableTemplate = {
    id: "",
    title: "",
    category: "",
    status: "",
    created: "",
    responded: "",
    action: "",
  };
  const [user, setUser] = useState([tableTemplate]);
  const [getTicket, setTicket] = useState([]);

  const [getChat, setChat] = useState({});

  const [query, setQuery] = useState(0);
  const [getId, setId] = useState(0);
  const [getMessage, setMessage] = useState({});
  const [table, setTable] = useState(true);
  //* Values
  const values = {
    username,
    setUsername,

    usernameLogin,
    setUsernameLogin,

    loginToken,
    setLoginToken,

    getLoaderStateSpinner,
    setLoaderStateSpinner,

    getActiveModule,
    setActiveModule,

    globalSnackbar,
    setGLobalSnackBar,

    getReloadModuleData,
    setReloadModuleData,

    colorChange,
    setColorChange,

    getSneakBar,
    setSneakBar,

    renewSnack,
    setRenewSnack,

    getCardsList_CTX,
    setCardList_CTX,

    getLicenseList_CTX,
    setLicenseList_CTX,

    paymentDetials,
    setPaymentDetails,

    paymentScroller,
    setPaymentScroller,

    getLicenseAl_CTX,
    setLicenseAl_CTX,

    getLicenseListLeads_CTX,
    setLicenseListLeads_CTX,

    limitationsData,
    setLimitationsData,

    getUserDetail_CTX,
    setUserDetail_CTX,

    getPricing_CTX,
    setPricing_CTX,

    getClientCode_CTX,
    setClientCode_CTX,

    getProtected_CTX,
    setProtected_CTX,

    state,
    setState,

    getLicenseSup_CTX,
    setLicenseSup_CTX,

    getSupUserDetail_CTX,
    setSupUserDetail_CTX,

    getPassword_CTX,
    setPassword_CTX,

    getUserModule,
    setUserModule,

    getUserModuleLogin,
    setUserModuleLogin,

    paymentUrl_CTX,
    setPaymentUrl_CTX,

    buySnack,
    setBuySnack,

    openCard,
    setOpenCard,

    verify,
    setVerify,

    userGuide,
    setUserGuide,

    user,
    setUser,

    getTicket,
    setTicket,

    getChat,
    setChat,

    query,
    setQuery,

    getMessage,
    setMessage,

    getId,
    setId,

    filter,
    setFilter,

    table,
    setTable,
  };

  return (
    <ApplicationContext.Provider value={values}>
      {" "}
      {props.children}{" "}
    </ApplicationContext.Provider>
  );
};
