import { Box, Typography, TextField, CircularProgress, useMediaQuery } from "@mui/material";
import gmail from "../verification/images/icons8-gmail.svg";
import outlook from "../verification/images/icons8-microsoft-outlook.svg";
import { useContext, useState } from "react";
import { ForgotPasswordUser, VerifyUser } from "../../services/user-controller";
import { StatusCodes } from "http-status-codes";
import { ApplicationContext } from "../../context/applicationCtx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReCAPTCHA from "react-google-recaptcha";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  ButtonMaterial,
  ButtonOutlined,
  text_col,
} from "../../constants/constStyles";

export default function VerifyForgotPassword() {
  const screenRes = useMediaQuery('(min-resolution:2dppx)')
  console.log(screenRes,'screenval')
  const [error, setError] = useState();
  const [recaptcha, setRecaptcha] = useState();
  const [resendCode, setResendCode] = useState(false);
  const [getLoaderState, setLoaderState] = useState(false);
  const [getLoaderStateResend, setLoaderStateResend] = useState(false);
  const { setUserModule, getPassword_CTX, verify, setVerify } =
    useContext(ApplicationContext);
  const handleChange = (e) => {
    setVerify(e.target.value);
  };
  console.log(verify);


 
  async function resendPassword() {
    setLoaderStateResend(true);
    try {
      let response = await ForgotPasswordUser({
        username: getPassword_CTX,
      });
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.OK) {
        setLoaderStateResend(false);
        setResendCode(true);
        setTimeout(() => {
          setResendCode(false);
        }, 30000);
        setError(`Verification code is sent to ${getPassword_CTX}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const verifyInputs = async function () {
    try {
      setLoaderState(true);

      //let response = await updateLicense(new FormData().append("licenseKey", getStepLicenseKey.value));
      let response = await VerifyUser({
        verifyToken: verify,
      });
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setRecaptcha(false);
        window.grecaptcha.reset();
        setError(response.data.message);
        setLoaderState(false);
      } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError("**Fields Cant Be empty**");
        setLoaderState(false);
      } else if (response.status === StatusCodes.OK) {
        // navigate('/reset-password');
        setUserModule(2);
      } else {
        throw response;
      }
    } catch (err) {
      // console.log("ERR =>",err);
      setLoaderState(false);
      if (err.data) {
        // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
      } else {
        if (err.errorMsg) {
          // setStepLicenseKey(err)
          console.log(err.errorMsg);
        } else {
          console.error("UNKNOWN ERROR", err);
        }
      }
    }
  };

  return (
    <>
      {getLoaderStateResend ? (
        <Box
          sx={{
            position: "absolute",
            zIndex: "3",
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,20%)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column-reverse",
            alignItems: "center",
            backdropFilter: "blur(3px)",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        ""
      )}
      <Box
        className="verifyFP"
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          textAlign: "center",
          paddingTop: "8px",
        }}
      >
           <Box
        sx={{
          width: "100%",
          display: "flex",
          position:'sticky',top:'0px'
        }}
      >

        <ButtonOutlined
          onClick={() => setUserModule(0)}
          sx={{ alignSelf: "flex-start", marginLeft: "2%", }}
          variant="outlined"
        >
          <ArrowBackIcon />
          Back
        </ButtonOutlined>
      </Box>

        <Typography
          variant="h3"
          component="h2"
          sx={{
            marginTop:screenRes ? '11%' : "4%",
            fontWeight: "medium",
            fontSize: "40px",
            color: text_col.col_primary,
          }}
        >
          SoliteraTA
        </Typography>

        <Typography
          mt={5}
          variant="h3"
          component="h2"
          sx={{
            marginTop: "1%",
            fontWeight: "medium",
            fontSize: "38px",
            color: "black",
          }}
        >
          Check Your Email For Code
        </Typography>
        <Typography
          mt={5}
          variant="h3"
          component="h2"
          sx={{
            marginTop: "1%",
            fontWeight: "300",
            fontSize: "18px",
            color: "black",
          }}
        >
          We’ve sent a 8-character code to your email. The code expires
          shortly,so please enter it soon!
        </Typography>
        <Box sx={{ width: "100%", textAlign: "center", marginTop: "1%" }}>
          <Typography
            variant="subtitle1"
            component="h2"
            sx={{
              color: "red",
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: "2px",
              fontSize: "10px",
            }}
          >
            {error}
          </Typography>
        </Box>

        <Box mt={1} sx={{ width: "100%" }}>
          <TextField
            inputProps={{ maxLength: 8 }}
            onChange={handleChange}
            // autoComplete={'new-password'}
            name='password'
            autoComplete='off'
            id="outlined-basic"
            type="Text"
            label="Enter Verification Code"
            variant="outlined"
            sx={{
              border: "px solid #4B4BF6",
              borderRadius: "5px",
              width: "34%",
              borderColor: "red",
            }}
          />
        </Box>
        <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <ReCAPTCHA
            sitekey="6Le73YAjAAAAAPrNfkoNDPViVhB6XSnh9yyzhjyx"
            onChange={(value) => {
              setRecaptcha(value);
            }}
            theme="light"
          />
        </Box>
        <ButtonMaterial
          className="continueFP"
          disabled={
            getLoaderState ? true : !recaptcha ? true : !verify ? true : false
          }
          variant="contained"
          disableElevation
          sx={{ marginTop: "1%", width: "10%", pr: 2, alignSelf: "center" }}
          onClick={() => {
            verifyInputs();
          }}
        >
          Continue{" "}
          {getLoaderState ? (
            <CircularProgress
              sx={{ color: "white", position: "absolute", left: "79%" }}
              size="1.2em"
            />
          ) : (
            ""
          )}
        </ButtonMaterial>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            gap: "100px",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
            }}
            mt={3}
            variant="subtitle1"
            component="h2"
          >
            <Box
              sx={{
                position: "relative",
                top: "12px",
              }}
            >
              {" "}
              <a
                href="https://mail.google.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={gmail} alt="" />
              </a>{" "}
            </Box>
            Open Gmail
          </Typography>
          <Typography
            sx={{ whiteSpace: "nowrap" }}
            mt={3}
            variant="subtitle1"
            component="h2"
          >
            <Box
              sx={{
                position: "relative",
                top: "13px",
              }}
            >
              <a
                href="https://outlook.office365.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={outlook} alt="" />
              </a>
            </Box>
            Open Outlook
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Typography
            sx={{ marginRight: "8px", marginTop: "24px" }}
            mt={3}
            variant="subtitle1"
            component="h2"
          >
            Can’t find your code? Check your spam folder!
          </Typography>
          {resendCode ? (
            <Typography
              className="resendTxt"
              sx={{ marginRight: "24px", color: "#4B4BF6", display: "flex" }}
              mt={3}
              variant="subtitle1"
              component="h2"
            >
              wait for{" "}
              {resendCode ? (
                <Box ml={0.5} mr={0.5} sx={{ alignSelf: "center" }}>
                  <CountdownCircleTimer
                    size={16}
                    strokeWidth={0}
                    isPlaying
                    duration={30}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[7, 5, 2, 0]}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </Box>
              ) : (
                ""
              )}{" "}
              seconds to resend code...
            </Typography>
          ) : (
            <Typography
              onClick={() => resendPassword()}
              sx={{ marginRight: "24px", color: "#4B4BF6", cursor: "pointer" }}
              mt={3}
              variant="subtitle1"
              component="h2"
            >
              Resend Code?
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
