

export class ModuleType{
    static licence = 0;
    static paymentMethod = 1;
    static userDetails = 2;
    static ticket=3;
    static userGuide=4;
    static notification=5;
}



export class DirectoryType {
    static project = "DT-1";
    static release = "DT-2";
    static application = "DT-3"
    static testData = "DT-4";
    static element = "DT-5";
    static script = "DT-5";
}

export class FileType {
    static element = "FT-1";
    static application = "FT-2";
    static testData = "FT-3";
    static script = "FT-3";
    static playList = "FT-4";
}


export class EnvironmentDataType {
    static application = "applications";
    static element = "elements";
}

export class EditorType {
    static application = "application";
    static feature = "feature";
    static element = "element";
}


export class StepDefinitionsOperation {
    static api = "API Operation";
    static custom = "Custom";
    static database = "Database Operation";
    static mobileApplication = "Mobile Application Operation";
    static Other = "Other Operation";
    static webApplication = "Web Application Operation";
}


export class DefaultEditorContent {
    static element = `
<environment>
    <config>
        <name>SITE-NAME</name>
        <type>web_application</type>
        <url>https://test.website.com/</url>
        <cookie>true</cookie>
    </config>
</environment>
                
`}