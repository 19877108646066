import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LicenseSuperAdmin from "../../components/license-superadmin";
import NavigationRailSuperadmin from "../../components/navigation-rail/navigation-superadmin";
import { API, API_HOST } from "../../constants/api";
import { ApplicationContext } from "../../context/applicationCtx";
// import { getLicenseListSuperadmin } from "../../services/license";
// import { getUserDetails } from "../../services/user-controller";

export default function MainSuper() {
  const { state } = useContext(ApplicationContext);
  const { setLicenseSup_CTX, setSupUserDetail_CTX } =
    useContext(ApplicationContext);
  const [getLoaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoaderState(true);
    // let myHeaders = new Headers();
    // myHeaders.append('Authorization', `Bearer ${localStorage.getItem('api-key')} `)
    // setLoaderState(true)
    // getLicenseListSuperadmin().then(function (apiData) {
    //     console.log(apiData, 'checkSUp')
    //     if (apiData.status === StatusCodes.FORBIDDEN) {

    //     }
    //     setLicenseSup_CTX(apiData.data.userList)
    //     setLoaderState(false);

    // }).catch(function (apiError) {
    //     console.log(apiError);
    // });
    // getUserDetails().then(function (apiData) {

    //     if (apiData.status === StatusCodes.FORBIDDEN) {

    //     }
    //     setSupUserDetail_CTX(apiData.data)
    // }).catch(function (apiError) {
    //     console.log(apiError);
    // });

    axios({
      method: "get",
      url: API_HOST.hostUrl + API.user + API.superadmin,
      headers: {
        Authorization:
          localStorage.getItem("super-key") !== null
            ? `Bearer ${localStorage.getItem("super-key")}`
            : `${null}`,
      },
    })
      .then(function (response) {
        console.log(response.status, "axios");
        setLicenseSup_CTX(response.data.userList);
        setLoaderState(false);
      })
      .catch(function (error) {
        console.log(error.response, "axioserror");
        if (error.response.status === StatusCodes.FORBIDDEN) {
          navigate("/login");
        }
      });

    // GetUserDetails
    axios({
      method: "get",
      url: API_HOST.hostUrl + API.user,
      headers: {
        Authorization:
          localStorage.getItem("super-key") !== null
            ? `Bearer ${localStorage.getItem("super-key")}`
            : `${null}`,
      },
    }).then(function (response) {
      setSupUserDetail_CTX(response.data);
      setLoaderState(false);
      console.log(response, "axioss");
    });
  }, [setLicenseSup_CTX, setSupUserDetail_CTX, navigate]);

  // const { state } = useContext(ApplicationContext);
  // const { setLicenseSup_CTX, setSupUserDetail_CTX } = useContext(ApplicationContext);
  // const [getLoaderState, setLoaderState] = useState(true);

  // if (getLoaderState) {
  //     if (state === 0) {
  //         getLicenseListSuperadmin().then(function (apiData) {
  //             console.log(apiData, 'checkSUp')
  //             if (apiData.status === 403) {
  //                 window.location.reload()
  //             }
  //             setLicenseSup_CTX(apiData.data.userList)
  //             setLoaderState(false);

  //         }).catch(function (apiError) {
  //             console.log(apiError);
  //         });
  //         getUserDetails().then(function (apiData) {
  //             // console.log(apiData,'checkSUp')
  //             if (apiData.status === 403) {
  //                 window.location.reload()
  //             }
  //             setSupUserDetail_CTX(apiData.data)
  //             // setLicenseSup_CTX(apiData.data.userList)
  //             // setLoaderState(false);

  //         }).catch(function (apiError) {
  //             console.log(apiError);
  //         });

  //     }

  //     else {
  //         setLoaderState(false);
  //     }
  // }

  return (
    <>
      {getLoaderState ? (
        <Box
          sx={{
            position: "absolute",
            zIndex: "3",
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,20%)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column-reverse",
            alignItems: "center",
            backdropFilter: "blur(3px)",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        ""
      )}

      <NavigationRailSuperadmin />
      <ActiveModuleSup value={state} />
    </>
  );
}
const ActiveModuleSup = function (props) {
  switch (props.value) {
    case 0:
      return <LicenseSuperAdmin {...props} />;
    default:
      return <LicenseSuperAdmin {...props} />;
  }
};
