import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { StatusCodes } from "http-status-codes";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../context/applicationCtx";
import {
  changePassword,
  getUserDetails,
  updateUserDetails,
} from "../../services/user-controller";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { ModuleType } from "../../constants/application";
import { ButtonMaterial, text_col } from "../../constants/constStyles";

export default function UserDetails() {
  const navigate = useNavigate();
  const {
    getUserDetail_CTX,
    setUserDetail_CTX,
    setActiveModule,
    setLoaderStateSpinner,
  } = useContext(ApplicationContext);
  const [getLoaderState, setLoaderState] = useState(false);
  // const [getLoaderStateSpinner, setLoaderStateSpinner] = useState(true);
  const [getCurrentP, setCurrentP] = useState("");
  const [getNewP, setNewP] = useState("");
  const [getError, setError] = useState();
  const [setErrorPhone] = useState();
  const [openDetailSnack, setOpenDetailSnack] = useState(false);
  const [openChangePasswordSnack, setOpenChangePasswordSnack] = useState(false);
  const [transition, setTransition] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const [userDetailChange, setUserDetailChange] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    organisationName: "",
  });

  const [phone, setPhone] = useState(
    getUserDetail_CTX.phoneNum == null ? "" : getUserDetail_CTX.phoneNum
  );
  const [firstName, setFirstName] = useState(
    getUserDetail_CTX.firstName == null ? "" : getUserDetail_CTX.firstName
  );
  const [lastName, setLastName] = useState(
    getUserDetail_CTX.lastName == null ? "" : getUserDetail_CTX.lastName
  );
  const [values, setValues] = useState({
    amount: "",
    password: "",
    passwordNew: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    showPasswordNew: false,
  });

  console.log(values, "val");

 

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setLoaderStateSpinner(true);
    getUserDetails()
      .then(function (apiData) {
        if (!apiData || apiData.status === StatusCodes.FORBIDDEN) {
          navigate("/login");
          window.localStorage.clear();
          localStorage.setItem('state',0)
          setActiveModule(ModuleType.table);
        }
        setFirstName(apiData.data.firstName ?? "");
        setLastName(apiData.data.lastName ?? "");
        setPhone(apiData.data.phoneNum ?? "");
        setUserDetailChange({
          firstName: apiData.data.firstName ?? "",
          lastName: apiData.data.lastName ?? "",
          phone: apiData.data.phoneNum ?? "",
        });
        document.getElementById("organisation").value =
          apiData.data.organisationName;
        document.getElementById("email").value = apiData.data.email;
        setLoaderStateSpinner(false);
      })
      .catch(function (apiError) {
        console.log(apiError);
      });
// eslint-disable-next-line react-hooks/exhaustive-deps

  }, [navigate, setLoaderStateSpinner,setActiveModule]);

  useEffect(() => {
    if (getCurrentP === getNewP) {
      setError("");
    }
  }, [getCurrentP, getNewP]);



  const handleCurrentP = (e) => {
    setCurrentP(e.target.value);
  };

  const handleNewP = (e) => {
    setNewP(e.target.value);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordNew = () => {
    setValues({
      ...values,
      showPasswordNew: !values.showPasswordNew,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const updateUser = async function () {
    try {
      setLoaderState(true);
      let response = await updateUserDetails({
        firstName: document.getElementById("firstName").value,
        lastName: lastName,
        organisationName: document.getElementById("organisation").value,
        phoneNum: document.getElementById("phoneNum").value,
      });
      console.log(response, "userUpdate");
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.OK) {
        let reloadDetails = await getUserDetails();
        setUserDetail_CTX(reloadDetails.data);
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setOpenDetailSnack(true);
        };
        setSnack();
        setTimeout(() => {
          // setOpenDetailSnack(false);
        }, 2000);
        setShowEdit(true);
        setLoaderState(false);
        // setErrorPhone("");
      }
    } catch (error) {
      console.log(error, "usererror");
    }
  };

  const verifyInpu = async function () {
    try {
      setLoaderState(true);

      let response = await changePassword({
        currentPassword: getCurrentP,
        newPassword: getNewP,
      });
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setLoaderState(false);
        setError(response.data.message);
      }
      if (response.status === StatusCodes.OK) {
        // setError(response.data.message)
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setOpenChangePasswordSnack(true);
        };
        setSnack();
        setTimeout(() => {
          setOpenChangePasswordSnack(false);
          window.localStorage.clear();
          navigate("/login");
          setActiveModule(ModuleType.table);
        }, 3000);
        setLoaderState(false);
      }
      if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError("Fields cant be empty");
        setLoaderState(false);
      }
      console.log(response);
    } catch (err) {
      // console.log("ERR =>",err);
      setLoaderState(false);
      if (err.data) {
        // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
      } else {
        if (err.errorMsg) {
          // setStepLicenseKey(err)
          console.log(err.errorMsg);
        } else {
          console.error("UNKNOWN ERROR", err);
        }
      }
    }
  };

  function handleEdit() {
    setShowEdit(false);
  }
  async function handleCancelState() {
    document.getElementById("phoneNum").value = getUserDetail_CTX.phoneNum;
    document.getElementById("organisation").value =
      getUserDetail_CTX.organisationName;
    setFirstName("");
    setLastName("");
    setPhone("");
    setUserDetailChange({
      firstName: getUserDetail_CTX.firstName ?? "",
      lastName: getUserDetail_CTX.lastName ?? "",
      phone: getUserDetail_CTX.phoneNum ?? "",
    });
    setShowEdit(true);
  }

  function handlePhone(e) {
    setUserDetailChange({
      ...userDetailChange,
      phone: e.target.value.replace(/[^0-9|^+]/gi, ""),
    });
  }

  function handleBlurPhone(e) {
    setPhone(e.target.value.replace(/[^0-9|^+]/gi, ""));

    if (validator.isMobilePhone(e.target.value.replace(/[^0-9|^+]/gi, ""))) {
      setErrorPhone("");
    } else {
      setErrorPhone("Phone Number not valid");
    }
  }
  return (
    <>
      {/* {getLoaderStateSpinner ? <LoaderSpinner/> : ''} */}
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor="#F4F7FCBF"
          justifyContent="center"
          padding="18px 0px 20px 11px"
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#393939",
            }}
          >
            {"PROFILE"}
          </Typography>
        </Box>

        <Box
          height={"89vh"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Box
            className="userScroll"
            sx={{
              display: "flex",
              height: "79%",
              width: "78.5%",
              borderRadius: "8px",
              // border: "1px solid #dde0e0",
              justifyContent: "center",
              alignItems: "center",
              gap: "4.3%",
              minWidth: "295px",
              flexWrap: "wrap",
              overflow: "auto",
              overflowX: "hidden",
              // maxHeight:'528px'
            }}
          >
            <Box
              className="smallBox-1"
              sx={{
                width: "43%",
                // height: "78%",
                padding: "0px 0px 73px 0px",

                borderRadius: "19px",
                border: "1px solid #cbc4c4",
                display: "flex",
                flexDirection: "column",
                minWidth: "288px",
                maxHeight: "438px",
              }}
            >
              <Typography
                mt={2}
                ml={3}
                variant="h3"
                component="h2"
                sx={{ fontSize: "19px", color: "black" }}
              >
                Your Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "89%",
                  gap: "5%",
                  alignSelf: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography
                    mt={3}
                    variant="h3"
                    component="h2"
                    sx={{ fontSize: "12px", color: "black" }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    disabled={showEdit}
                    value={userDetailChange.firstName?.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    error={
                      firstName === ""
                        ? false
                        : firstName.length < 3
                        ? true
                        : false
                    }
                    helperText={
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        {firstName === ""
                          ? ""
                          : firstName.length < 3
                          ? "Miniumum 3-characters"
                          : ""}
                      </Typography>
                    }
                    defaultValue={`${getUserDetail_CTX.firstName}`}
                    sx={{
                      width: "100%",
                      pointerEvents: `${showEdit ? "none" : "all"}`,
                    }}
                    onChange={(e) =>
                      setUserDetailChange({
                        ...userDetailChange,
                        firstName: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                    onBlur={(e) =>
                      setFirstName(e.target.value.replace(/[^A-Za-z]/gi, ""))
                    }
                    id="firstName"
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography
                    mt={3}
                    variant="h3"
                    component="h2"
                    sx={{ fontSize: "12px", color: "black" }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    disabled={showEdit}
                    value={userDetailChange?.lastName?.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )}
                    defaultValue={`${getUserDetail_CTX?.lastName}`}
                    sx={{
                      width: "100%",
                      pointerEvents: `${showEdit ? "none" : "all"}`,
                    }}
                    onChange={(e) =>
                      setUserDetailChange({
                        ...userDetailChange,
                        lastName: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                    onBlur={(e) =>
                      setLastName(e.target.value.replace(/[^A-Za-z]/gi, ""))
                    }
                    id="lastName"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "89%",
                  gap: "5%",
                  alignSelf: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography
                    mt={2}
                    variant="h3"
                    component="h2"
                    sx={{ fontSize: "12px", color: "black" }}
                  >
                    Phone
                  </Typography>
                  <TextField
                    disabled={showEdit}
                    error={
                      phone === ""
                        ? false
                        : !validator.isMobilePhone(phone)
                        ? true
                        : false
                    }
                    helperText={
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        {phone === ""
                          ? ""
                          : !validator.isMobilePhone(phone)
                          ? "Phone number not valid"
                          : ""}
                      </Typography>
                    }
                    onChange={handlePhone}
                    onBlur={handleBlurPhone}
                    defaultValue={`${
                      getUserDetail_CTX.phoneNum == null
                        ? ""
                        : getUserDetail_CTX.phoneNum
                    }`}
                    sx={{
                      width: "100%",
                      pointerEvents: `${showEdit ? "none" : "all"}`,
                    }}
                    id="phoneNum"
                    variant="outlined"
                    value={
                      userDetailChange?.phone?.replace(/[^0-9|^+]/gi, "") ?? ""
                    }
                  />
                </Box>

                <Box sx={{ width: "50%" }}>
                  <Typography
                    mt={2}
                    variant="h3"
                    component="h2"
                    sx={{ fontSize: "12px", color: "black" }}
                  >
                    Organisation Name
                  </Typography>
                  <TextField
                    disabled={showEdit}
                    defaultValue={`${getUserDetail_CTX.organisationName}`}
                    sx={{
                      width: "100%",
                      pointerEvents: `${showEdit ? "none" : "all"}`,
                    }}
                    id="organisation"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box sx={{ alignSelf: "center", width: "89%" }}>
                <Typography
                  mt={2}
                  variant="h3"
                  component="h2"
                  sx={{ fontSize: "12px", color: "black" }}
                >
                  Email
                </Typography>
                <TextField
                  disabled
                  defaultValue={`${getUserDetail_CTX.email}`}
                  sx={{
                    width: "100%",
                    input: { cursor: showEdit ? "default" : "not-allowed" },
                  }}
                  id="email"
                  variant="outlined"
                />
              </Box>

              {showEdit ? (
                <Box
                  onClick={handleEdit}
                  sx={{ textAlign: "center", marginTop: "3%" }}
                >
                  {" "}
                  <ButtonMaterial
                    variant={"contained"}
                    sx={{ width: "22%", height: "30px", marginTop: "1.5%" }}
                  >
                    Edit
                  </ButtonMaterial>
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "3%" }}>
                  <ButtonMaterial
                    onClick={updateUser}
                    disabled={
                      !validator.isMobilePhone(userDetailChange?.phone) &&
                      userDetailChange?.phone !== ""
                        ? true
                        : userDetailChange?.firstName?.length < 3
                        ? true
                        :  getLoaderState ? true : false
                    }
                    variant={"contained"}
                    sx={{
                      width: "22%",
                      height: "30px",
                      marginTop: "1.5%",
                      marginRight: "3%",
                    }}
                  >
                    Save{" "}
                    {getLoaderState ? (
                      <CircularProgress
                        size="1.2em"
                        sx={{
                          color: text_col.col_primary,
                          position: "absolute",
                          right: "0",
                          mr: 1,
                        }}
                      />
                    ) : (
                      ""
                    )}{" "}
                  </ButtonMaterial>
                  <Button
                    onClick={() => handleCancelState()}
                    variant={"outlined"}
                    color={"error"}
                    sx={{ width: "22%", height: "30px", marginTop: "1.5%" }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              className="smallBox-2"
              sx={{
                width: "43%",
                // height: "78%",
                padding: "0px 0px 155px 0px",

                borderRadius: "19px",
                border: "1px solid #cbc4c4",
                display: "flex",
                flexDirection: "column",
                minWidth: "288px",
                maxHeight: "438px",
                // alignItems:'center'
              }}
            >
              <Typography
                mt={2}
                ml={3}
                variant="h3"
                component="h2"
                sx={{ fontSize: "19px", color: "black" }}
              >
                Change Password
              </Typography>
              <Box
                sx={{ alignSelf: "center", width: "89%", marginTop: "16px" }}
              >
                <Typography
                  mt={1}
                  variant="h3"
                  component="h2"
                  sx={{
                    fontSize: "10px",
                    color: "red",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    textAlign: "center",
                  }}
                >
                  {getError}
                </Typography>
                <Typography
                  mt={1}
                  variant="h3"
                  component="h2"
                  sx={{ fontSize: "12px", color: "black" }}
                >
                  Current Password*
                </Typography>

                <FormControl
                  onBlur={handleCurrentP}
                  sx={{ width: "100%" }}
                  variant="outlined"
                  error={
                    getCurrentP === ""
                      ? false
                      : !validator.isStrongPassword(getCurrentP)
                      ? true
                      : false
                  }
                >
                  <OutlinedInput
                    autoComplete="new-password"
                    placeholder="Current Password*"
                    id="outline"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle  visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText sx={{ color: "red !important", fontSize: "12px" }}>
                    {getCurrentP === ""
                      ? ""
                      : !validator.isStrongPassword(getCurrentP)
                      ? "Password min 8-char, 1-upper & lowercase, 1-symbol, 1-number"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ alignSelf: "center", width: "89%" }}>
                <Typography
                  mt={2}
                  variant="h3"
                  component="h2"
                  sx={{ fontSize: "12px", color: "black" }}
                >
                  New Password*
                </Typography>

                <FormControl
                  onBlur={handleNewP}
                  error={
                    getNewP === ""
                      ? false
                      : !validator.isStrongPassword(getNewP)
                      ? true
                      : getCurrentP === getNewP
                      ? true
                      : false
                  }
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    onPaste={(e) => e.preventDefault()}
                    placeholder="New Password*"
                    id="outlined-adornment-password"
                    type={values.showPasswordNew ? "text" : "password"}
                    value={values.passwordNew}
                    onChange={handleChange("passwordNew")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordNew}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPasswordNew ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText sx={{ color: "red !important", fontSize: "12px" }}>
                    {getNewP === ""
                      ? ""
                      : !validator.isStrongPassword(getNewP)
                      ? "Password min 8-char, 1-upper & lowercase, 1-symbol, 1-number"
                      : getCurrentP === getNewP
                      ? "Current password & new password cant be same"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <ButtonMaterial
                disabled={
                  !validator.isStrongPassword(values?.passwordNew)
                    ? true
                    : !validator.isStrongPassword(values?.password)
                    ? true
                    : values?.password === values?.passwordNew
                    ? true
                    :  getLoaderState ? true : false
                }
                onClick={() => {
                  verifyInpu();
                }}
                sx={{ alignSelf: "center", marginTop: "17px", width: "49%" }}
                variant="contained"
              >
                Change Password{" "}
                {getLoaderState ? (
                  <CircularProgress
                    size="1.2em"
                    sx={{
                      color: text_col.col_primary,
                      position: "absolute",
                      right: "0",
                      mr: 0.4,
                    }}
                  />
                ) : (
                  ""
                )}
              </ButtonMaterial>
            </Box>
          </Box>
        </Box>

        <Box>
          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={openDetailSnack}
            TransitionComponent={transition}
            onClose={() => setOpenDetailSnack(false)}
            message="Details updated successfully"
            autoHideDuration={2000}
            ContentProps={{ style: { maxWidth: "58%" } }}
          />
        </Box>

        <Box>
          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={openChangePasswordSnack}
            TransitionComponent={transition}
            onClose={() => setOpenChangePasswordSnack(false)}
            message="Password updated successfully! Please login with new password"
            autoHideDuration={2000}
            bodystyle={{ minWidth: 20 }}
            ContentProps={{ style: { maxWidth: "58%", textAlign: "center" } }}
          />
        </Box>
      </Box>
    </>
  );
}
