import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Typography,
  Button,
  TextField,
  Tooltip,
  CircularProgress,
  TextareaAutosize,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
  Skeleton,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import styles from "./mystyle.module.css";
import {
  IconDesktop,
  IconMobile,
  IconWeb,
  SearchIcon,
} from "../../constants/icons";
import FormControl from "@mui/material/FormControl";
import { ApplicationContext } from "../../context/applicationCtx";
import {
  buyDemoLicense,
  getLeads,
  getLicenseBulk,
  getLicenseList,
  getPricing,
  licenceLimitations,
} from "../../services/license";
import { useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { getClientIntent } from "../../services/card-controller";
import { StatusCodes } from "http-status-codes";
// import { ModuleType } from "../../constants/application";
import { useEffect } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {
  BootstrapDialog,
  ButtonMaterial,
  ButtonOutlined,
  CssTextField,
  text_col,
} from "../../constants/constStyles";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { superAdminList } from "../../services/user-controller";
import NoDataAvailable from "../no-data";
import { TableRowWithData } from "./tableComponents/licenceTableRow";
import {
  TableRowWithDataLeads,
  TableRowWithDataSuper,
} from "./tableComponents/superAdminTableRow";
import { ModuleType } from "../../constants/application";
import useErrorHandler from "../../utility/errorHandler";
// import { TableRowWithDataLeads, TableRowWithDataSuper } from "./tableComponents/superAdminTable";
export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

// Table Body

const TableUser = ({
  setOpenPaymentConfirm,
  // renewSnack={renewSnack}
  // setRenewSnack={setRenewSnack}
  setLicenseAl_CTX,
  setLicenseList_CTX,
  search,
  tableQuery,
  getLicenseList_CTX,
  result,
  getPricingData,
}) => {
  const [zoomLevel, setZoomLevel] = useState(null);
  const tableResolution1 = useMediaQuery("(min-resolution:1.5dppx)");
  const tableResolution2 = useMediaQuery("(min-resolution:1.9dppx)");
  const tableResolution3 = useMediaQuery("(min-resolution:2dppx)");

  useEffect(() => {
    setZoomLevel(Math.round(window?.devicePixelRatio * 100 - 25));

    window.addEventListener("resize", (e) => {
      console.log(e);
      setZoomLevel(Math.round(window?.devicePixelRatio * 100 - 25));
    });
  }, [zoomLevel]);

  console.log(zoomLevel, "level");
  console.log(
    tableResolution1,
    tableResolution2,
    tableResolution3,
    "resolutionCheck"
  );
  return (
    <>
      <TableContainer
        id="tableScroll"
        className="tableContainer"
        sx={{
          top: "88px",
          left: "5%",
          width: "100%",
          overflow: "auto",
          height:
            tableResolution3 && zoomLevel !== 175
              ? "62vh"
              : zoomLevel > 125 && zoomLevel <= 131
              ? "80vh"
              : zoomLevel > 131 && zoomLevel < 166
              ? "66vh !important"
              : tableQuery
              ? "77vh !important"
              : "86vh",
          display: "flex",
          flexDirection: "column",
          borderCollapse: "collapse",
        }}
        component={Paper}
      >
        <Table
          sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
              >
                LICENCE NO
              </TableCell>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                LICENCE TYPE&nbsp;
              </TableCell>

              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                LICENCE KEY&nbsp;
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                EMAIL&nbsp;
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                STATUS&nbsp;
              </TableCell>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                MODULE
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                colSpan="3"
                align="center"
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {getLicenseList_CTX
              .filter((filterSearch, i, length) => {
                // let date = new Date(filterSearch.validTill);
                // let dateString = date.toDateString()?.toLowerCase();

                // if (filterSearch.status.includes("Active")) {
                //   return (filterSearch.status = "Allocated");
                // }
                if (filterSearch.licenseNo?.toLowerCase().includes(search)) {
                  return filterSearch;
                }
                if (search === "") {
                  return filterSearch;
                }

                // if (dateString.includes(search)) {
                //   return filterSearch;
                // }
                if (filterSearch.email !== null) {
                  if (filterSearch.email?.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                }
                if (search.startsWith("un")) {
                  if (filterSearch.status?.toLowerCase() === "unallocated") {
                    return filterSearch;
                  }
                }
                if (search.startsWith("al")) {
                  if (filterSearch.status?.toLowerCase() === "active") {
                    return filterSearch;
                  }
                }

                if (
                  filterSearch.status?.toLowerCase().includes(search) &&
                  filterSearch.status?.toLowerCase() !== "active" &&
                  filterSearch.status?.toLowerCase() !== "unallocated"
                ) {
                  return filterSearch;
                }
                // if(search === 'unallocated'){
                //   if(filterSearch.status?.toLowerCase() === 'unallocated'){
                //     return filterSearch
                //   }
                // }
                // if(filterSearch.status?.toLowerCase() === 'unallocated'){
                //   return filterSearch
                // }
                if (filterSearch.licenseType?.toLowerCase().includes(search)) {
                  return filterSearch;
                }
                if (
                  search.includes("web") &&
                  !search.includes("mobile") &&
                  !search.includes("desktop")
                ) {
                  if (
                    filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("mobile") &&
                  !search.includes("web") &&
                  !search.includes("desktop")
                ) {
                  if (
                    !filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("desktop") &&
                  !search.includes("mobile") &&
                  !search.includes("web")
                ) {
                  if (
                    !filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }

                if (
                  search.includes("web") &&
                  search.includes("mobile") &&
                  !search.includes("desktop")
                ) {
                  if (
                    filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("web") &&
                  search.includes("desktop") &&
                  !search.includes("mobile")
                ) {
                  if (
                    filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("mobile") &&
                  search.includes("desktop") &&
                  !search.includes("web")
                ) {
                  if (
                    !filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("web") &&
                  search.includes("desktop") &&
                  search.includes("mobile")
                ) {
                  if (
                    filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (filterSearch.firstName !== null) {
                  if (filterSearch.firstName.includes(search)) {
                    return filterSearch;
                  }
                }

                return false;
              })
              .map((license, index, results) => {
                result = results.length;
                return (
                  <TableRowWithData
                    setOpenPaymentConfirm={setOpenPaymentConfirm}
                    // renewSnack={renewSnack}
                    // setRenewSnack={setRenewSnack}
                    setLicenseAl_CTX={setLicenseAl_CTX}
                    setLicenseList_CTX={setLicenseList_CTX}
                    getLicenseList_CTX={getLicenseList_CTX}
                    key={index}
                    index={index}
                    license={license}
                    getPricingData={getPricingData}
                  />
                );
              })}
          </TableBody>
        </Table>
        {/* {tableQuery && getLicenseList_CTX.length !== 0 ? (
            <Box sx={{ position: "sticky", bottom: "7px", left: "0px" }}>
              <TableBottomHeader
                result={result}
                getLicenseAl_CTX={getLicenseAl_CTX}
                getLicenseList_CTX={getLicenseList_CTX}
                tableQuery={tableQuery}
                search={search}
              />
            </Box>
          ) : (
            ""
          )} */}
        {getLicenseList_CTX.length === 0 ? (
          <NoDataAvailable message={"Please buy licence"} />
        ) : (
          ""
        )}
        {!result && getLicenseList_CTX.length !== 0 ? (
          <NoDataAvailable message={"No data found"} />
        ) : (
          ""
        )}
      </TableContainer>
    </>
  );
};

const TableSuperAdmin = ({
  setOpenPaymentConfirm,
  // renewSnack={renewSnack}
  // setRenewSnack={setRenewSnack}
  setLicenseAl_CTX,
  setLicenseList_CTX,
  search,
  tableQuery,
  getLicenseList_CTX,
  result,
  getPricingData,
}) => {
  return (
    <>
      <TableContainer
        id="tableScroll"
        className="tableContainer"
        sx={{
          top: "88px",
          left: "5%",
          width: "100%",
          overflow: "auto",
          height: tableQuery ? "77vh !important" : "91vh",
          display: "flex",
          flexDirection: "column",
          borderCollapse: "collapse",
        }}
        component={Paper}
      >
        <Table
          sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
              >
                S.NO
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                EMAIL&nbsp;
              </TableCell>
              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                ORGANISATION NAME&nbsp;
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                STATUS&nbsp;
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                // colSpan="3"
                align="center"
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {getLicenseList_CTX
              .filter((filterSearch, i, length) => {
                // let date = new Date(filterSearch.validTill);
                // let dateString = date.toDateString()?.toLowerCase();

                // if (filterSearch.status.includes("Active")) {
                //   return (filterSearch.status = "Allocated");
                // }
                if (search === "") {
                  return filterSearch;
                }

                // if (dateString.includes(search)) {
                //   return filterSearch;
                // }
                if (filterSearch.email !== null) {
                  if (filterSearch.email?.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                }
                if (filterSearch.status?.toLowerCase().includes(search)) {
                  return filterSearch;
                }

                if (filterSearch.firstName !== null) {
                  if (filterSearch.firstName.includes(search)) {
                    return filterSearch;
                  }
                }
                if (
                  filterSearch.organisationName?.toLowerCase().includes(search)
                ) {
                  return filterSearch;
                }

                return false;
              })
              .map((license, index, results) => {
                result = results.length;
                return (
                  <TableRowWithDataSuper
                    // renewSnack={renewSnack}
                    // setRenewSnack={setRenewSnack}
                    setLicenseAl_CTX={setLicenseAl_CTX}
                    setLicenseList_CTX={setLicenseList_CTX}
                    key={index}
                    index={index}
                    license={license}
                    getPricingData={getPricingData}
                  />
                );
              })}
          </TableBody>
        </Table>

        {getLicenseList_CTX.length === 0 ? (
          <NoDataAvailable message={"No user available"} />
        ) : (
          ""
        )}
        {!result && getLicenseList_CTX.length !== 0 ? (
          <NoDataAvailable message={"No data found"} />
        ) : (
          ""
        )}
      </TableContainer>
    </>
  );
};

const TableSuperAdminLeads = ({
  setOpenPaymentConfirm,
  // renewSnack={renewSnack}
  // setRenewSnack={setRenewSnack}
  setLicenseAl_CTX,
  setLicenseListLeads_CTX,
  search,
  tableQuery,
  getLicenseListLeads_CTX,
  result,
  getPricingData,
}) => {
  return (
    <>
      <TableContainer
        id="tableScroll"
        className="tableContainer"
        sx={{
          top: "88px",
          left: "5%",
          width: "100%",
          overflow: "auto",
          height: tableQuery ? "77vh !important" : "86vh",
          display: "flex",
          flexDirection: "column",
          borderCollapse: "collapse",
        }}
        component={Paper}
      >
        <Table
          sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
              >
                LICENCE NO
              </TableCell>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                LICENCE TYPE&nbsp;
              </TableCell>

              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                LICENCE KEY&nbsp;
              </TableCell>

              {/* <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                EMAIL&nbsp;
              </TableCell> */}

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                align="left"
              >
                STATUS&nbsp;
              </TableCell>
              <TableCell
                sx={{
                  color: " #171C26",
                  fontWeight: "600",
                }}
                align="left"
              >
                MODULE
              </TableCell>

              <TableCell
                sx={{ color: " #171C26", fontWeight: "600" }}
                // colSpan="3"
                align="center"
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {getLicenseListLeads_CTX
              .filter((filterSearch, i, length) => {
                // let date = new Date(filterSearch.validTill);
                // let dateString = date.toDateString()?.toLowerCase();

                // if (filterSearch.status.includes("Active")) {
                //   return (filterSearch.status = "Allocated");
                // }
                if (filterSearch.licenseNo?.toLowerCase().includes(search)) {
                  return filterSearch;
                }
                if (search === "") {
                  return filterSearch;
                }

                // if (dateString.includes(search)) {
                //   return filterSearch;
                // }
                if (filterSearch.email !== null) {
                  if (filterSearch.email?.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                }
                if (search.startsWith("un")) {
                  if (filterSearch.status?.toLowerCase() === "unallocated") {
                    return filterSearch;
                  }
                }
                if (search.startsWith("al")) {
                  if (filterSearch.status?.toLowerCase() === "active") {
                    return filterSearch;
                  }
                }

                if (
                  filterSearch.status?.toLowerCase().includes(search) &&
                  filterSearch.status?.toLowerCase() !== "active" &&
                  filterSearch.status?.toLowerCase() !== "unallocated"
                ) {
                  return filterSearch;
                }
                // if(search === 'unallocated'){
                //   if(filterSearch.status?.toLowerCase() === 'unallocated'){
                //     return filterSearch
                //   }
                // }
                // if(filterSearch.status?.toLowerCase() === 'unallocated'){
                //   return filterSearch
                // }
                if (filterSearch.licenseType?.toLowerCase().includes(search)) {
                  return filterSearch;
                }
                if (
                  search.includes("web") &&
                  !search.includes("mobile") &&
                  !search.includes("desktop")
                ) {
                  if (
                    filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("mobile") &&
                  !search.includes("web") &&
                  !search.includes("desktop")
                ) {
                  if (
                    !filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("desktop") &&
                  !search.includes("mobile") &&
                  !search.includes("web")
                ) {
                  if (
                    !filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }

                if (
                  search.includes("web") &&
                  search.includes("mobile") &&
                  !search.includes("desktop")
                ) {
                  if (
                    filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    !filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("web") &&
                  search.includes("desktop") &&
                  !search.includes("mobile")
                ) {
                  if (
                    filterSearch.webModule &&
                    !filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("mobile") &&
                  search.includes("desktop") &&
                  !search.includes("web")
                ) {
                  if (
                    !filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (
                  search.includes("web") &&
                  search.includes("desktop") &&
                  search.includes("mobile")
                ) {
                  if (
                    filterSearch.webModule &&
                    filterSearch.mobileModule &&
                    filterSearch.desktopModule
                  )
                    return filterSearch;
                }
                if (filterSearch.firstName !== null) {
                  if (filterSearch.firstName.includes(search)) {
                    return filterSearch;
                  }
                }

                return false;
              })
              .map((license, index, results) => {
                result = results.length;
                return (
                  <TableRowWithDataLeads
                    setOpenPaymentConfirm={setOpenPaymentConfirm}
                    // renewSnack={renewSnack}
                    // setRenewSnack={setRenewSnack}
                    setLicenseAl_CTX={setLicenseAl_CTX}
                    setLicenseList_CTX={setLicenseListLeads_CTX}
                    key={index}
                    index={index}
                    license={license}
                    getPricingData={getPricingData}
                  />
                );
              })}
          </TableBody>
        </Table>
        {/* {tableQuery && getLicenseList_CTX.length !== 0 ? (
<Box sx={{ position: "sticky", bottom: "7px", left: "0px" }}>
  <TableBottomHeader
    result={result}
    getLicenseAl_CTX={getLicenseAl_CTX}
    getLicenseList_CTX={getLicenseList_CTX}
    tableQuery={tableQuery}
    search={search}
  />
</Box>
) : (
""
)} */}
        {getLicenseListLeads_CTX.length === 0 ? (
          <NoDataAvailable message={"No request found"} />
        ) : (
          ""
        )}
        {!result && getLicenseListLeads_CTX.length !== 0 ? (
          <NoDataAvailable message={"No data found"} />
        ) : (
          ""
        )}
      </TableContainer>
    </>
  );
};

const TabAdmin = ({
  setOpenPaymentConfirm,
  // renewSnack={renewSnack}
  // setRenewSnack={setRenewSnack}
  setLicenseAl_CTX,
  setLicenseListLeads_CTX,
  getLicenseListLeads_CTX,
  getLicenseList_CTX,
  setLicenseList_CTX,
  search,
  tableQuery,
  result,
  tabAdmin,
  getPricingData,
}) => {
  switch (tabAdmin) {
    case 0:
      return (
        <TableSuperAdmin
          setOpenPaymentConfirm={setOpenPaymentConfirm}
          search={search}
          tableQuery={tableQuery}
          getLicenseList_CTX={getLicenseList_CTX}
          setLicenseList_CTX={setLicenseList_CTX}
          result={result}
          setLicenseAl_CTX={setLicenseAl_CTX}
          getPricingData={getPricingData}
        />
      );
    case 1:
      return (
        <TableSuperAdminLeads
          setOpenPaymentConfirm={setOpenPaymentConfirm}
          search={search}
          tableQuery={tableQuery}
          getLicenseListLeads_CTX={getLicenseListLeads_CTX}
          setLicenseListLeads_CTX={setLicenseListLeads_CTX}
          result={result}
          setLicenseAl_CTX={setLicenseAl_CTX}
          getPricingData={getPricingData}
        />
      );
    default:
      <TableUser />;
  }
};

// Main function containing Table
export default function LicenseTable() {
  // States section
  const tableQuery = useMediaQuery("(max-width:824px)");
const errorHandler  = useErrorHandler()
  const {
    // setOpenCard,
    getClientCode_CTX,
    setClientCode_CTX,
    setLoaderStateSpinner,
    buySnack,
    setBuySnack,
    renewSnack,
    setRenewSnack,
    setActiveModule,
  } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [getLoaderState, setLoaderState] = useState(false);
  const [getPricingData, setPricingData] = useState({});
  const [error, setError] = useState(null);
  const [noLicense, setNoLicense] = useState(1);
  const [selectF, setSelectF] = useState("Monthly");
  const [selectLicenceType, setSelectLicenceType] = useState("Individual");
  const [priceList, setPriceList] = useState([]);
  const [selectPrice, setSelectPrice] = useState("");
  // License List
  const [getLicenseList_CTX, setLicenseList_CTX] = useState([]);
  const [getLicenseListLeads_CTX, setLicenseListLeads_CTX] = useState([]);
  // Allocated and Deallocate
  const [getLicenseAl_CTX, setLicenseAl_CTX] = useState([]);
  const [systemInfoCodeDemo, setSystemCodeDemo] = useState("");
  const [open, setOpen] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);
  const [buyDemoSnack, setBuyDemoSnack] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [checkBox, setCheckbox] = useState(["WEB_MOBILE_DESKTOP"]);
  // eslint-disable-next-line
  const [platformType, setPlatformType] = useState(false);
  const [freeLicenseType, setFreeLicenseType] = useState("Trial");
  const [nextStepLicence, setNextStepLicence] = useState(false);
  const [requestSnack, setRequestSnack] = useState(false);
  const [checkOut] = useState(false);
  const [textSkeleton, setTextSkeleton] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [limitationsData, setLimitationsData] = useState({});
  const [customerEmail, setCustomerEmail] = useState("");
  const licenceQuery = useMediaQuery("(max-width:800px)");
  const [workSpaceCount, setWorkSpaceCount] = useState("3");
  // const licenceQuery = useMediaQuery("(max-width:1098px)");
  const [openPaymentConfirm, setOpenPaymentConfirm] = useState(false);
  const [tabAdmin, setTabAdmin] = useState(0);
  /* Global vars */
  let combo = [];
  let result;
  priceList.map((comboPrice, indexCombo) => {
    if (checkBox.includes(comboPrice.module) && indexCombo < 3) {
      console.log(comboPrice.perUserCost, "comb");
      combo.push(...Array.of(comboPrice.perUserCost));
      console.log(combo, "comb");
    }
    return false;
  });
  let discount =
    combo.reduce((pv, cv) => pv + cv, 0) * noLicense - noLicense * selectPrice;

  let subtotal = noLicense * combo.reduce((pv, cv) => pv + cv, 0);
  console.log(subtotal - discount, selectPrice, "subt");
  let VAT =
    (((workSpaceCount <= getPricingData?.freeWorkspaceCount
      ? 0
      : workSpaceCount - getPricingData?.freeWorkspaceCount) *
      (selectF === "Annually"
        ? getPricingData?.workspaceCostAnnually
        : getPricingData?.workspaceCostMonthly) +
      selectPrice * noLicense) *
      getPricingData.vat) /
    100;

  useEffect(() => {
    setLoaderStateSpinner(true);
    if (!localStorage.getItem("super-key")) {
      getLicenseList()
        .then(function (apiData) {
          if (!apiData) {
            navigate("/login");
            // sessionClear()
          }
          if (apiData.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
            // sessionClear()
          }
          setLoaderStateSpinner(false);
          setLicenseList_CTX(apiData.data.licenseList);
          setLicenseAl_CTX(apiData.data);
        })
        .catch(function (apiError) {
          console.log(apiError);
        });

      licenceLimitations()
        .then((apiData) => {
          console.log(apiData);
          if (apiData.status === StatusCodes.OK) {
            setLimitationsData(apiData.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      superAdminList()
        .then(function (response) {
          console.log(response.status, "axios");
          if (response.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
            sessionClear();
          }
          if (response.status === StatusCodes.OK) {
            setLicenseList_CTX(response.data.userList);
            setLicenseAl_CTX(response.data);
            setLoaderState(false);
            setLoaderStateSpinner(false);
          }
        })
        .catch(function (error) {
          console.log(error.response, "axioserror");
          if (error.response.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
            sessionClear();
          }
        });

      getLeads()
        .then(function (response) {
          console.log(response.status, "leads");
          if (response.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
            sessionClear();
          }
          if (response.status === StatusCodes.OK) {
            setLicenseListLeads_CTX(response.data.licenseList);
            // setLicenseAl_CTX(response.data);
            setLoaderState(false);
            setLoaderStateSpinner(false);
          }
        })
        .catch(function (error) {
          console.log(error.response, "axioserror");
          if (error.response.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
            sessionClear();
          }
        });

      // GetUserDetails
      // axios({
      //   method: "get",
      //   url: API_HOST.hostUrl + API.user,
      //   headers: {
      //     Authorization:
      //       localStorage.getItem("super-key") !== null
      //         ? `Bearer ${localStorage.getItem("super-key")}`
      //         : `${null}`,
      //   },
      // }).then(function (response) {
      //   setSupUserDetail_CTX(response.data);
      //   setLoaderState(false);
      //   console.log(response, "axioss");
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const sessionClear = () => {
    window.localStorage.clear();
    localStorage.setItem("state", 0);
    setActiveModule(ModuleType.table);
  };

  useEffect(() => {
    setSkeleton(true);
    let isMounted = true;
    const pricingApi = async () => {
      try {
        let response = await getPricing();
        if (response.status === StatusCodes.OK) {
          if (isMounted) {
            setPricingData(response.data);
            setSkeleton(false);
          }
          // if (selectLicenceType === "Business") {
          //   setNoLicense(10);
          // }
          //else if (
          //   selectLicenceType === "Business" &&
          //   selectF === "Monthly"
          // ) {
          //   setNoLicense(response.data.business.minLicenseReq);
          //   setPriceList(response.data.business?.monthly);
          // }
          // if (selectLicenceType === "Individual" && selectF === "Annually") {
          //   // setNoLicense(response.data.individual.minLicenseReq);
          //   setPriceList(response.data.individual?.annually);
          //   // setSkeletion(false)
          // } else if (
          //   selectLicenceType === "Individual" &&
          //   selectF === "Monthly"
          // ) {
          //   // setNoLicense(response.data.individual.minLicenseReq);
          //   setPriceList(response.data.individual?.monthly);
          //   // setSkeletion(false)
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    pricingApi();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    console.log(getPricingData, "pricingg");
    // setTextSkeleton(true)

    if ((selectLicenceType === "Individual" || selectLicenceType === "Floating") && selectF === "Annually") {
      setPriceList(getPricingData?.individual?.annually ?? []);
      // setTextSkeleton(false)
    } else if ((selectLicenceType === "Individual" || selectLicenceType === "Floating") && selectF === "Monthly") {
      setPriceList(getPricingData?.individual?.monthly ?? []);
      // setTextSkeleton(false)
    }
  }, [selectLicenceType, selectF, getPricingData]);

  useEffect(() => {
    if (
      checkBox.includes("WEB") &&
      checkBox.includes("MOBILE") &&
      checkBox.includes("DESKTOP")
    ) {
      priceList.map((data, i) => {
        if (data.module === "WEB_MOBILE_DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (checkBox.includes("WEB_MOBILE_DESKTOP")) {
      priceList.map((data, i) => {
        if (data.module === "WEB_MOBILE_DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (checkBox.includes("WEB") && checkBox.includes("MOBILE")) {
      priceList.map((data, i) => {
        if (data.module === "WEB_MOBILE") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (checkBox.includes("WEB") && checkBox.includes("DESKTOP")) {
      priceList.map((data, i) => {
        if (data.module === "WEB_DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (checkBox.includes("MOBILE") && checkBox.includes("DESKTOP")) {
      priceList.map((data, i) => {
        if (data.module === "MOBILE_DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (
      checkBox.includes("WEB") &&
      !checkBox.includes("MOBILE") &&
      !checkBox.includes("DESKTOP")
    ) {
      priceList.map((data, i) => {
        if (data.module === "WEB") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (
      checkBox.includes("MOBILE") &&
      !checkBox.includes("WEB") &&
      !checkBox.includes("DESKTOP")
    ) {
      priceList.map((data, i) => {
        if (data.module === "MOBILE") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else if (
      checkBox.includes("DESKTOP") &&
      !checkBox.includes("WEB") &&
      !checkBox.includes("MOBILE")
    ) {
      priceList.map((data, i) => {
        if (data.module === "DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    }
  }, [checkBox, priceList, selectPrice]);

  // JSON for selection of Frequency

  // selection of frequency values during Buy License
  const handleSelectF = (selecters) => {
    console.log(selecters, "checkDefaultFrequency");
    setTextSkeleton(true);
    setTimeout(() => {
      setTextSkeleton(false);
    }, 1000);
    if (selectLicenceType === "Individual") {
      // setSkeletion(true)
    }
    setSelectF(selecters);
  };

  const handleSelectLT = (selecters) => {
    setWorkSpaceCount("3");
    setSelectLicenceType(selecters);
    setError("");
    if (selecters === "Individual" || selecters === 'Floating') {
      // setNoLicense(response.data.individual.minLicenseReq);
      setNoLicense(getPricingData.individual.minLicenseReq);
      setSelectF("Monthly");
      setCustomerEmail("");
      checkBox.length = 1;
    } else {
      setNoLicense(5);
      setSelectF("Annually");
      setCustomerEmail("");
      checkBox.length = 0;
      priceList.map((data, i) => {
        if (i < 3) {
          checkBox.push(...Array.of(data.module));
        }
        // if (data.name === "WEB_MOBILE_DESKTOP") {
        //   setSelectPrice(data.perUserCost);
        // }
        return false;
      });
    }
    console.log(selecters, "checkDefaultLT");
  };

  const handleSelectFreeL = (selecters) => {
    setFreeLicenseType(selecters);
  };
  // eslint-disable-next-line
  const handleSelectPT = (e) => {
    checkBox.length = 0;
    setPlatformType(e.target.checked);
    if (e.target.checked === true) {
      priceList.map((data, i) => {
        if (i < 3) {
          checkBox.push(...Array.of(data.module));
          setCheckbox([...checkBox]);
        }
        if (data.name === "WEB_MOBILE_DESKTOP") {
          setSelectPrice(data.perUserCost);
        }
        return false;
      });
    } else {
      setSelectPrice("");
    }
  };
  // console.log(selectPrice,'price')

  // eslint-disable-next-line
  const handleCheckBox = (data) => {
    setTextSkeleton(true);
    setTimeout(() => {
      setTextSkeleton(false);
    }, 1000);
    if (selectLicenceType !== "Business")
      !checkBox.includes(data.module)
        ? checkBox.push(data.module)
        : checkBox.splice(checkBox.indexOf(data.module), 1);
    setCheckbox([...checkBox]);
    console.log(checkBox);
  };
  // let couponTimeout;
  // const handleCoupon = (e) => {
  //   clearTimeout(couponTimeout);
  //   couponTimeout = setTimeout(() => {
  //     setCouponCode(e.target.value);
  //   }, 600);
  // };

  // Selection Of cards during Buy License
  // const handleSelect = (select) => {
  //   setSelect(select.cardId);
  // };

  // Stripe payment confimation for 3D cards during Buy License
  if (getClientCode_CTX) {
    async function clientCode() {
      let response = await getClientIntent(getClientCode_CTX);
      // console.log(response,)
      if (response.status === StatusCodes.OK) {
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setBuySnack(true);
        };
        setSnack();
        setTimeout(() => {
          setBuySnack(false);
        }, 2000);
      }
    }
    clientCode();
  }

  useEffect(() => {
    document.body.classList.add("bodyHide");
    document.body.style.overflow = "hidden";
  }, []);

  // Function to handle no of license
  const handleLicense = useCallback(
    (e) => {
      selectLicenceType === "Business"
        ? e.target.value < 0
          ? setNoLicense("")
          : e.target.value < 10
          ? setNoLicense(e.target.value > 50 ? 50 : e.target.value)
          : setNoLicense(
              Math.ceil((e.target.value > 50 ? 50 : e.target.value) / 5) * 5
            )
        : e.target.value < 0
        ? setNoLicense("")
        : e.target.value < getPricingData.individual.minLicenseReq
        ? setNoLicense(e.target.value > 50 ? 50 : e.target.value)
        : setNoLicense(
            Math.ceil(
              (e.target.value > 50 ? 50 : e.target.value) /
                getPricingData.individual.inMultipleOf
            ) * getPricingData.individual.inMultipleOf
          );
    },
    [getPricingData, selectLicenceType]
  );

  console.log(noLicense, "no");

  // Buy license function to trigger api request
  const verifyInputs = async function () {
    setLoaderState(true);
    sessionStorage.setItem("paymentType", "buy");
    if (noLicense < 1 && (selectLicenceType === "Individual" || selectLicenceType === "Floating")) {
      setError(
        `Individual: Min users should be ${getPricingData.individual.minLicenseReq}`
      );
      setLoaderState(false);
    } else if (workSpaceCount < 3) {
      setError(`Min workspace should be 3`);
      setLoaderState(false);
    } else if (noLicense < 5 && selectLicenceType === "Business") {
      setError(`Business: Min users should be ${5}`);
      setLoaderState(false);
    } else {
      try {
        let response = await getLicenseBulk({
          customerEmail: customerEmail === "" ? null : customerEmail,
          noOfLicenses: noLicense,
          frequency: selectF,
          licenseCost: `${
            checkBox.length === 0
              ? 0
              : (workSpaceCount <= getPricingData?.freeWorkspaceCount
                  ? 0
                  : workSpaceCount - getPricingData?.freeWorkspaceCount) *
                  (selectF === "Annually"
                    ? getPricingData?.workspaceCostAnnually
                    : getPricingData?.workspaceCostMonthly) +
                noLicense * selectPrice +
                VAT
          }`,
          licenseType: selectLicenceType,
          // mobileModule: checkBox.includes("MOBILE"),
          // webModule: checkBox.includes("WEB"),
          // desktopModule: checkBox.includes("DESKTOP"),
          mobileModule: checkBox.includes("WEB_MOBILE_DESKTOP"),
          webModule: checkBox.includes("WEB_MOBILE_DESKTOP"),
          desktopModule: checkBox.includes("WEB_MOBILE_DESKTOP"),
          noOfWorkspace: workSpaceCount,
        });
        errorHandler(response,setLoaderState)
        if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setLoaderState(false);
          setError("Something went wrong");
        }
        if (response.status === StatusCodes.ACCEPTED) {
          setLoaderState(false);
          if (!response.data.redirectUrl) {
            // setError(response.data.message);
            if (!localStorage.getItem("super-key")) {
              handleClose();
              setError(response.data.message);
              let reloadList = await getLicenseList();
              setLicenseList_CTX(reloadList.data.licenseList);
              setLicenseAl_CTX(reloadList.data);
              setRequestSnack(true);
              // setOpenPaymentConfirm(true);
            } else {
              handleClose();
              setError(response.data.message);
              let reloadList = await superAdminList();
              setLicenseList_CTX(reloadList.data.userList);

              setRequestSnack(true);

              // setLicenseAl_CTX(reloadList.data);
            }
          } else {
            // setPaymentUrl_CTX(response.data.redirectUrl);
            sessionStorage.setItem("redirect-url", response.data.redirectUrl);
            sessionStorage.setItem("id", response.data.paymentIntent);
            // window.open(response.data.redirectUrl, "_self");
            navigate("/licence/purchaseredirect");
            handleClose();
          }
        }
        // console.log(response)
        if (response.status === StatusCodes.OK) {
          setError("");
          let reloadList = await getLicenseList();
          setLicenseList_CTX(reloadList.data.licenseList);
          setLicenseAl_CTX(reloadList.data);
          setLoaderState(false);
          setOpen(false);
          handleClose();
          // setAge("");
          const setSnack = (Transition) => {
            setTransition(() => Transition);
            setBuySnack(true);
          };
          setSnack();

          console.log(response);

          setTimeout(() => {
            setBuySnack(false);
          }, 2000);
        } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
          setError("**Fields Cant Be empty**");
          setLoaderState(false);
        } else if (response.status === StatusCodes.FAILED_DEPENDENCY) {
          setOpen(false);
          // setClientCode(response.data.clientCode)
          let code = response.data.clientCode;
          var stripe = window.Stripe(
            "pk_test_51Kg0AFFgTsFRsmEw2JUsD7CpPsU1Lhrsi70B2on0QqBQdcBQRHHrwTSdBWnZzInKjw1anOC354WNV6CezdKEs6e700NM9rdzQk"
          );
          let clientSecret = code;

          stripe.handleCardAction(clientSecret).then(async function (result) {
            // console.log(result.paymentIntent.id);
            console.log(result);
            if (result.error) {
              let reloadList = await getLicenseList();
              setLicenseList_CTX(reloadList.data.licenseList);
              setLicenseAl_CTX(reloadList.data);
            }
            setClientCode_CTX(result.paymentIntent.id);
          });
          setLoaderState(false);
        } else if (response.status === StatusCodes.EXPECTATION_FAILED) {
          setError(response.data.message);
          setLoaderState(false);
        }
      } catch (err) {
        setLoaderState(false);
        if (err.data) {
        } else {
          if (err.errorMsg) {
            // setStepLicenseKey(err)
            console.log(err.errorMsg);
          } else {
            console.error("UNKNOWN ERROR", err);
          }
        }
      }
    }
  };
  // console.log(paymentUrl_CTX, "intent");
  // Popups and snack Opening/closing
  const handleClickOpen = () => {
    setError("");
    setOpen(true);
  };

  const handleClose = () => {
    setError("");
    setOpen(false);
    setSelectF("Monthly");
    setNoLicense(1);
    setPlatformType(false);
    setCheckbox(["WEB_MOBILE_DESKTOP"]);
    // setCheckbox(["WEB"]);
    setSelectLicenceType("Individual");
    setCustomerEmail("");
    setLoaderState(false);
    setWorkSpaceCount("3");
  };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  // const handleChangeF = (event) => {
  //   setAgeF(event.target.value);
  // };
  // const handleModule = () => {
  //   setActiveModule(ModuleType.paymentMethod);
  //   setOpenCard(true);
  // };

  const handleDemo = async () => {
    setLoaderState(true);
    try {
      let response = await buyDemoLicense({
        type: freeLicenseType === "Trial" ? "Individual" : freeLicenseType,
        systemInfoCode: systemInfoCodeDemo,
      });
      if (response.status === StatusCodes.OK) {
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        setLoaderState(false);
        setOpenDemo(false);
        setBuyDemoSnack(true);
        handleCloseFreeLicence();
        setTimeout(() => {
          setBuyDemoSnack(false);
        }, 2000);
      }
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError(response.data.fields.systemInfoCode);
      }
      if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setLoaderState(false);
        setError(response.data.fields.systemInfoCode);
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setLoaderState(false);
        setError(response.data.message);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  let clearFreeTrail;
  const handleFreeTrail = (e) => {
    clearTimeout(clearFreeTrail);
    clearFreeTrail = setTimeout(() => {
      setSystemCodeDemo(e.target.value);
    }, 400);
  };

  let clearSearch;
  const handleSearch = (e) => {
    clearTimeout(clearSearch);
    clearSearch = setTimeout(() => {
      setSearch(e.target.value?.toLowerCase());
    }, 400);
  };

  const handleCloseFreeLicence = () => {
    setOpenDemo(false);
    setError("");
    setSystemCodeDemo("");
    setFreeLicenseType("Trial");
    setNextStepLicence(false);
  };

  console.log(customerEmail, "emaill");
  // const handleAddCard = () => {
  //   setActiveModule(1);
  //   localStorage.setItem("state", 1);
  //   setOpenCard(true);
  // };

  const handleWorkspace = useCallback(
    (e) => {
      setWorkSpaceCount(
        e.target.value < 0
          ? "0"
          : (selectLicenceType === "Individual" || selectLicenceType === "Floating") && e.target.value >= 10
          ? "10"
          : selectLicenceType === "Business" && e.target.value >= 20
          ? "20"
          : e.target.value
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectLicenceType]
  );

  console.log(
    checkBox,
    priceList,
    getPricingData?.workspaceCostAnnually,
    workSpaceCount,
    "check"
  );
  return (
    <>
      {/* {!getLoaderStateSpinner ? <LoaderSpinner /> : ""} */}
      <Box width="100%" display={"flex"} flexDirection="column" flex={1}>
        <Box
          display={"flex"}
          height="65px"
          backgroundColor="#F4F7FCBF"
          alignItems="center"
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              paddingLeft: "1%",
              fontWeight: "bold",
              color: "#393939",
              flex: 1,
              fontSize: tableQuery ? "13px" : "24px",
              whiteSpace: "nowrap",
            }}
          >
            {!localStorage.getItem("super-key") ? "LICENCE LIST" : "USER LIST"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              // width: "262px",
              height: "37px",
              gap: "6px",
              marginRight: "12px",
              marginLeft: "12px",
              alignItems: "center",
            }}
          >
            {/* {localStorage.getItem("super-key") ? ( */}
            <TextField
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="">
                    <SearchIcon sx={{ color: "green" }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              label="Search"
            />
            {/* ) : (
              <></>
            )} */}
            <ButtonMaterial
              className="buttonTxt"
              onClick={() => setOpenDemo(true)}
              variant="contained"
              disableElevation
              sx={{
                // mt: 1,
                pr: 2,
                fontSize: tableQuery ? "8px" : "13px",
                fontWeight: "600",
                height: tableQuery ? "33px" : "auto",
                whiteSpace: "nowrap",
                // fontSize:'8px'
              }}
            >
              FREE LICENCE
            </ButtonMaterial>
            <ButtonMaterial
              className="buttonTxt"
              onClick={handleClickOpen}
              variant="contained"
              disableElevation
              sx={{
                // mt: 1,
                pr: 2,
                fontSize: tableQuery ? "8px" : "13px",
                fontWeight: "600",
                height: tableQuery ? "33px" : "auto",
                whiteSpace: "nowrap",
              }}
            >
              NEW LICENCE
            </ButtonMaterial>
          </Box>
        </Box>
        {localStorage.getItem("super-key") ? (
          <Box
            display={"flex"}
            height="45px"
            backgroundColor="#F4F7FCBF"
            alignItems="center"
            paddingLeft={"14px"}
            gap="8px"
          >
            {Array.of("ALL USERS LIST", "REQUEST LIST").map((data, i) => {
              return (
                <Typography
                  onClick={() => {
                    setSearch("");
                    setTabAdmin(i);
                  }}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    whiteSpace: "nowrap",
                    padding: "9px",
                    border:
                      tabAdmin === i
                        ? `2px solid ${text_col.col_primary}`
                        : "2px solid grey",
                    borderRadius: "5px",
                    lineHeight: 1,
                    color: tabAdmin === i ? text_col.col_primary : "#393939",
                    borderBottom:
                      tabAdmin === i
                        ? `4px solid ${text_col.col_primary}`
                        : "2px solid grey",
                    textDecorationColor: text_col.col_primary,
                    cursor: "pointer",
                    opacity: tabAdmin === i ? 1 : 0.4,
                  }}
                >
                  {data}
                </Typography>
              );
            })}
          </Box>
        ) : (
          <></>
        )}
        {!localStorage.getItem("super-key") ? (
          <>
            <TableUser
              setOpenPaymentConfirm={setOpenPaymentConfirm}
              search={search}
              tableQuery={tableQuery}
              getLicenseList_CTX={getLicenseList_CTX}
              result={result}
              setLicenseAl_CTX={setLicenseAl_CTX}
              setLicenseList_CTX={setLicenseList_CTX}
              getPricingData={getPricingData}
            />
            <TableBottomHeader
              result={result}
              getLicenseAl_CTX={getLicenseAl_CTX}
              getLicenseList_CTX={getLicenseList_CTX}
              tableQuery={tableQuery}
              search={search}
            />{" "}
          </>
        ) : (
          <>
            <TabAdmin
              setOpenPaymentConfirm={setOpenPaymentConfirm}
              search={search}
              tabAdmin={tabAdmin}
              tableQuery={tableQuery}
              getLicenseListLeads_CTX={getLicenseListLeads_CTX}
              setLicenseListLeads_CTX={setLicenseListLeads_CTX}
              getLicenseList_CTX={getLicenseList_CTX}
              setLicenseList_CTX={setLicenseList_CTX}
              result={result}
              setLicenseAl_CTX={setLicenseAl_CTX}
              getPricingData={getPricingData}
            />
          </>
        )}
        <Box>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth={"100%"}
            maxheight={"100%"}
            PaperProps={{
              style: {
                minWidth: "358px",
                // minWidth: "340px",
                maxHeight: "100%",
                // height: "73%",
                paddingBottom: "22px",
                // width: "58%",
                width: "769px",
              },
            }}
          >
            <BootstrapDialogTitle
              sx={{
                // paddingLeft: "32px",
                paddingLeft: "24px",
                height: "45px",
              }}
              onClose={handleClose}
            >
              <Typography sx={{ fontSize: "19px" }}>
                {!checkOut ? " New Licence" : "Payment Section"}
              </Typography>
            </BootstrapDialogTitle>

            {!checkOut ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  // flexWrap: "wrap",
                  flexDirection: licenceQuery ? "column" : "row",
                }}
              >
                <Box
                  className="purchaseLeftBox"
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: licenceQuery ? "0" : "1px solid #E8E9EB",
                    paddingTop: "13px",
                  }}
                >
                  <Box
                    sx={{
                      width: "88%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "13px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        Licence Type{" "}
                        <Tooltip
                          arrow
                          placement="bottom-start"
                          title={
                            <Box
                              sx={{
                                // width: "400px",
                                gap: "10px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  borderBottom: "1px solid white",
                                  paddingBottom: "8px",
                                }}
                              >
                                Business : Deployment on server, Multiple Users
                                Access, Team Collaborations
                              </Typography>
                              <Typography sx={{ fontSize: "14px" }}>
                                Individual : Deployment on self pc, Single User
                                Access, No Collaboration.
                              </Typography>
                            </Box>
                          }
                        >
                          <InfoIcon
                            sx={{
                              fontSize: "16px",
                              color: text_col.col_primary,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                      <Box display='flex' alignItems='center'>
                        <FormControl
                          sx={{
                            pointerEvents:
                              priceList.length === 0 ? "none" : "all",
                          }}
                        >
                          <RadioGroup
                            sx={{ alignItems: "center" }}
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={selectLicenceType}
                          >
                            {Array.of("Business", "Individual",'Floating').map(
                              (type, i) => {
                                return (
                                  
                                  <Tooltip componentsProps={{tooltip:{style:{background:'#F5F7FB',borderRadius: "10px",boxShadow:'0px 0px 5px -1px grey'}}}} placement="left-start" arrow open={selectLicenceType === "Business" && i === 0}  title={selectLicenceType === "Business" ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // width: "100%",
                                        height: "100%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "6px",
                                        background: "#F5F7FB",
                                        padding: "13px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Typography sx={{ color: "black" }}>
                                        Min users should be {5}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    ""
                                  )}>
                                    <FormControlLabel
                                      disabled={
                                        priceList.length === 0 ? true : false
                                      }
                                      key={i}
                                      value={type}
                                      onClick={() => handleSelectLT(type)}
                                      control={
                                        <Radio
                                          sx={{
                                            color: text_col.col_primary,
                                            "&.Mui-checked": {
                                              color: text_col.col_primary,
                                            },
                                          }}
                                        />
                                      }
                                      label={type}
                                    />
                                  </Tooltip>
                                );
                              }
                            )}
                            
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                    {/* <Typography>njknkn</Typography> */}

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Duration:</Typography>
                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={selectF}
                          >
                            {Array.of("Annually", "Monthly").map((type, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  value={type}
                                  control={
                                    <Radio
                                      onClick={() => handleSelectF(type)}
                                      sx={{
                                        color: text_col.col_primary,
                                        "&.Mui-checked": {
                                          color: text_col.col_primary,
                                        },
                                      }}
                                    />
                                  }
                                  label={type}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    <Box
                      // sx={{ display: "flex", flexDirection: "row", gap: "9px" }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "9px",
                        minWidth: "332px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                          width: "100%",
                        }}
                      >
                        {/* <Typography display={"flex"} alignItems="center">
                          Modules:
                        </Typography> */}
                        {/* <Typography display={"flex"} alignItems="center">
                          <Checkbox
                            disableRipple
                            disabled={
                              selectLicenceType === "Business" ? true : false
                            }
                            indeterminate={
                              checkBox.length !== 0 && checkBox.length !== 3
                                ? true
                                : false
                            }
                            checked={
                              platformType || checkBox.length === 3
                                ? true
                                : false
                            }
                            // onChange={(e) => handleSelectPT(e)}
                            sx={{
                              width: "22px",
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                                color: text_col.col_primary,
                              },
                              borderRadius: "12px",
                              color: text_col.col_primary,
                              "&.Mui-checked": {
                                color: text_col.col_primary,
                              },
                              opacity:
                                selectLicenceType === "Business" ? "0.6" : "1",
                            }}
                          />
                          Select All{" "}
                        </Typography> */}

                        <Box
                          className="purchaseModuleMainBox"
                          sx={{
                            display: "flex",
                            // flexDirection: licenceQuery ? "column" : "row",
                            flexDirection: "column",
                            gap: "16px",
                            // minWidth: "299px",
                          }}
                        >
                          <Box
                            className="purchaseModuleLB"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              // minWidth: "200px",
                              // width: "362px",
                              // opacity:
                              //   selectLicenceType === "Business" ? 0.6 : 1,
                              opacity: 0.6,
                            }}
                          >
                            {skeleton ? (
                              <>
                                {priceList.slice(0, 3).map((data, i) => {
                                  return (
                                    <Box
                                      sx={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "48px",
                                        minWidth: "233px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "9px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        sx={{ backgroundColor: "#F5F7FB" }}
                                        animation="wave"
                                        width={"100%"}
                                        height={"100%"}
                                      />
                                    </Box>
                                  );
                                })}
                              </>
                            ) : (
                              priceList.slice(0, 3).map((data, i) => {
                                return (
                                  <>
                                    <Box
                                      key={i}
                                      // onClick={() => handleCheckBox(data)}
                                      sx={{
                                        // cursor: "pointer",
                                        width: "100%",
                                        height: "48px",
                                        // minWidth: "233px",
                                        borderRadius: "10px",
                                        border: checkBox.includes(data.module)
                                          ? `1px solid ${text_col.col_primary}`
                                          : "1px solid #212121",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "3px",
                                        paddingLeft: "3px",
                                        paddingRight: "7px",
                                      }}
                                    >
                                      <Checkbox
                                        disableRipple
                                        checked={
                                          checkBox.includes(data.module)
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          cursor: "default",
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 20,
                                          },
                                          borderRadius: "12px",
                                          color: text_col.col_primary,
                                          "&.Mui-checked": {
                                            color: text_col.col_primary,
                                          },
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap:
                                            i === 0
                                              ? "9px"
                                              : i === 2
                                              ? "15px"
                                              : "6px",
                                          flex: 1,
                                        }}
                                      >
                                        {i === 0 ? (
                                          <IconWeb />
                                        ) : i === 1 ? (
                                          <IconDesktop />
                                        ) : i === 2 ? (
                                          <IconMobile />
                                        ) : (
                                          ""
                                        )}
                                        {selectLicenceType !== "Business" ? (
                                          <Typography
                                            textTransform="capitalize"
                                            whiteSpace="nowrap"
                                            display="flex"
                                            alignItems="flex-end"
                                            gap="2px"
                                          >
                                            {/* {data.module.split('_')[0]?.toLowerCase()}+{data.module.split('_')[1]?.toLowerCase()}+{data.module.split('_')[2]?.toLowerCase()} - */}
                                            {data.module
                                              ?.toLowerCase()
                                              .replace(/_/gi, "+")
                                              .replace(/[m]|[d]/gi, (m) =>
                                                m.toUpperCase()
                                              )}{" "}
                                            - &pound;
                                            {data?.perUserCost}
                                            <Typography
                                              fontSize="9px"
                                              textTransform="lowercase"
                                            >
                                              per licence
                                            </Typography>
                                          </Typography>
                                        ) : (
                                          <Typography
                                            textTransform="capitalize"
                                            whiteSpace="nowrap"
                                            display="flex"
                                            alignItems="flex-end"
                                            gap="2px"
                                          >
                                            {data.module
                                              .split("_")[0]
                                              ?.toLowerCase()}
                                            +
                                            {data.module
                                              .split("_")[1]
                                              ?.toLowerCase()}
                                            +
                                            {data.module
                                              .split("_")[2]
                                              ?.toLowerCase()}
                                          </Typography>
                                        )}
                                      </Box>
                                      <Tooltip
                                        arrow
                                        placement="bottom-start"
                                        title={
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              gap: "10px",
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {Array.of(
                                              "Web: This module is used to create and execute reusable tests for all types of web apps, including those built with popular modern frameworks.",
                                              "Mobile : This module is used to create and automate functional UI tests on physical or virtual iOS and Android devices with ease, saving you time and effort.",
                                              "Desktop : This module is used to test windows based applications and is already equipped with all predefined desktop operations."
                                            ).map((info, infoIndex) => {
                                              // if (
                                              //   info
                                              //     ?.toLowerCase()
                                              //     .includes(
                                              //       data.module?.toLowerCase()
                                              //     )
                                              // ) {
                                              // return info;
                                              // }
                                              // return false;
                                              return (
                                                <Typography>{info}</Typography>
                                              );
                                            })}
                                          </Typography>
                                        }
                                      >
                                        <InfoIcon
                                          sx={{
                                            fontSize: "16px",
                                            color: text_col.col_primary,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </>
                                );
                              })
                            )}
                          </Box>
                          <TextField
                            fullWidth
                            type={"number"}
                            size="small"
                            sx={{ marginTop: "1%" }}
                            helperText={
                              <Typography fontSize="13px" color={"grey"}>
                                {workSpaceCount <=
                                getPricingData?.freeWorkspaceCount
                                  ? ""
                                  : `£${
                                      selectF === "Annually"
                                        ? getPricingData?.workspaceCostAnnually
                                        : getPricingData?.workspaceCostMonthly
                                    } per workspace  `}
                              </Typography>
                            }
                            value={
                              workSpaceCount < 0
                                ? "0"
                                : (selectLicenceType === "Individual" || selectLicenceType === "Floating") &&
                                  workSpaceCount >= 10
                                ? "10"
                                : selectLicenceType === "Business" &&
                                  workSpaceCount >= 20
                                ? "20"
                                : workSpaceCount
                            }
                            onChange={handleWorkspace}
                            label="No of Workspace"
                          />
                          <Box
                            className="purchaseModuleRB"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // width: "80%",
                              flex: 1,
                            }}
                          >
                            {/* <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "6px",
                                background: "#F5F7FB",
                                padding: "13px",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography fontSize="15px">
                                Pro Tip: If you choose a longer duration and
                                select multiple modules, you can take advantage
                                of additional discounts. It's a great way to
                                maximize your savings.
                              </Typography>
                            </Box> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="purchaseRightBox"
                  sx={{
                    // width: licenceQuery ? "100%" : "38%",
                    // height: "100%",
                    flex: 1,
                    // paddingTop: "26px",
                    paddingLeft: "19px",
                    paddingRight: "19px",
                    gap: "9px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      // width: "265px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      sx={{
                        color: "red",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: "2px",
                        fontSize: "10px",
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography flex={1}>Cart Overview</Typography>
                    {/* <Typography>1 Item</Typography> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "26px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <Typography flex={1}>
                          No of{" "}
                          {selectLicenceType === "Business"
                            ? "Users"
                            : "Licence"}
                        </Typography>
                        <Typography
                          flex={1}
                          sx={{
                            color: "#3D4758",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {selectLicenceType === "Business"
                            ? "Users"
                            : "Licence"}{" "}
                          should be multiple of{" "}
                          {selectLicenceType === "Business"
                            ? // ? getPricingData.business?.inMultipleOf
                              "5"
                            : getPricingData.individual?.inMultipleOf}
                        </Typography>
                      </Box>
                      <Box>
                        <CssTextField
                          sx={{ width: "72px", flex: 1 }}
                          size="small"
                          onChange={handleLicense}
                          type="number"
                          inputProps={{
                            step:
                              selectLicenceType === "Business"
                                ? `5` ?? "5"
                                : `${getPricingData.individual?.inMultipleOf}` ??
                                  "1",
                          }}
                          inMultipleOf
                          value={noLicense > 50 ? 50 : noLicense}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  </Box>
                  {selectLicenceType !== "Business" ? (
                    <>
                      {checkBox.map((data, i) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "26px",
                            }}
                          >
                            <Box sx={{ display: "flex", flex: 1 }}>
                              <Typography
                                sx={{
                                  flex: 1,
                                  textTransform: "capitalize",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data?.split("_")[0]?.toLowerCase()}+
                                {data
                                  ?.split("_")[1]
                                  ?.toLowerCase()
                                  .replace(/[m]/gi, (m) => m.toUpperCase())}
                                +
                                {data
                                  ?.split("_")[2]
                                  ?.toLowerCase()
                                  .replace(/[d]/gi, (m) =>
                                    m.toUpperCase()
                                  )}{" "}
                                Module
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                display: "flex",
                                gap: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography whiteSpace={"nowrap"}>
                                &pound;
                                {priceList.map((dataPrice, inPrice) => {
                                  if (data === dataPrice.module)
                                    return dataPrice.perUserCost;
                                  return false;
                                })}{" "}
                                x {noLicense}
                              </Typography>
                              = &pound;
                              {priceList.map((dataPrice, inPrice) => {
                                if (data === dataPrice.module)
                                  return noLicense * dataPrice.perUserCost;
                                return false;
                              })}
                            </Typography>
                          </Box>
                        );
                      })}

                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "26px",
                        }}
                      >
                        {textSkeleton ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              backgroundColor: "#F5F7FB",
                            }}
                          />
                        ) : (
                          <>
                            <Typography
                              sx={{ flex: 1, textTransform: "capitalize" }}
                            >
                              Discount
                            </Typography>

                            <Typography sx={{ color: "red" }}>
                              - &pound;
                              {checkBox.length === 0 || noLicense === "0"
                                ? "0"
                                : discount}
                            </Typography>
                          </>
                        )}
                      </Box> */}
                      <Box
                        sx={{ display: "flex", borderTop: "1px solid #E8E9EB" }}
                      ></Box>
                      <Box sx={{ display: "flex" }}>
                        {textSkeleton ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              backgroundColor: "#F5F7FB",
                            }}
                          />
                        ) : (
                          <>
                            <Typography flex={1}>Subtotal</Typography>
                            <Typography>
                              {" "}
                              &pound;
                              {checkBox.length === 0 ||
                              noLicense === "0" ||
                              !noLicense
                                ? 0
                                : selectPrice * noLicense +
                                  (workSpaceCount <=
                                  getPricingData?.freeWorkspaceCount
                                    ? 0
                                    : workSpaceCount -
                                      getPricingData?.freeWorkspaceCount) *
                                    (selectF === "Annually"
                                      ? getPricingData?.workspaceCostAnnually
                                      : getPricingData?.workspaceCostMonthly)}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        {textSkeleton ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              backgroundColor: "#F5F7FB",
                            }}
                          />
                        ) : (
                          <>
                            <Typography flex={1}>
                              VAT({getPricingData.vat}% applicable as address)
                            </Typography>
                            <Typography>
                              &pound;
                              {checkBox.length === 0 ||
                              noLicense === "0" ||
                              !noLicense
                                ? 0
                                : VAT}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box
                        sx={{ display: "flex", borderTop: "1px solid #E8E9EB" }}
                      ></Box>
                      {/* <Box sx={{ display: "flex" }}>
                        {textSkeleton ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              backgroundColor: "#F5F7FB",
                            }}
                          />
                        ) : (
                          <>
                            <Typography flex={1}>Grand total</Typography>
                            <Typography>
                              {" "}
                              &pound;
                              {checkBox.length === 0 || noLicense === "0" || !noLicense
                                ? 0
                                : (workSpaceCount <=
                                  getPricingData?.freeWorkspaceCount
                                    ? 0
                                    : workSpaceCount -
                                      getPricingData?.freeWorkspaceCount) *
                                    (selectF === "Annually"
                                      ? getPricingData?.workspaceCostAnnually
                                      : getPricingData?.workspaceCostMonthly) +
                                  noLicense * selectPrice + VAT}
                            </Typography>
                          </>
                        )}
                      </Box> */}

                      {/* <Box sx={{ display: "flex", gap: "11px" }}>
                  <CssTextField
                    sx={{ width: "100%" }}
                    size="small"
                    onChange={handleCoupon}
                    placeholder="Discount Code"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="">
                          {couponCode ? (
                            <CheckIcon sx={{ color: "green" }} />
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box> */}
                    </>
                  ) : (
                    <></>
                  )}

                  {localStorage.getItem("super-key") ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      fullWidth
                      // componentsProps={{ popper: { style: { height: '10px' } } }}
                      // inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setCustomerEmail(newInputValue);
                      }}
                      id="combo-box-demo"
                      options={getLicenseList_CTX ?? []}
                      getOptionLabel={(option) => option.email}
                      // sx={{ width: 300 }}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder="Select Email"
                        />
                      )}
                    />
                  ) : (
                    // <FormControl sx={{ marginTop: "8px" }} size="small">
                    //   <InputLabel id="card">Select Email</InputLabel>
                    //   <Select
                    //   MenuProps={{ autoFocus: false }}
                    //   autoFocus={false}
                    //     labelId="demo-simple-select-label"
                    //     id="demo-simple-select"
                    //     value={customerEmail}
                    //     label="Select Email"
                    //     // onChange={handleChange}
                    //     sx={{
                    //       borderRadius: "5px",
                    //     }}
                    //   >
                    //     {" "}
                    //     {/* <MenuItem> */}
                    //     <ListSubheader>
                    //     <Box sx={{padding: "8px"}}>
                    //       <CssTextField autoFocus onChange={(e)=>setEmailSearch(e.target.value)} size="small" fullWidth placeholder="Search"/>
                    //       </Box>

                    //     </ListSubheader>
                    //     {/* </MenuItem> */}
                    //     {getLicenseList_CTX.filter((emailFilter,i)=>{
                    //          if(emailSearch === ''){
                    //           return emailFilter
                    //         }
                    //         if(emailFilter.email?.toLowerCase().includes(emailSearch)){
                    //           return emailFilter
                    //         }
                    //         return false
                    //     }).map((select, index) => {

                    //       return (
                    //         <MenuItem
                    //           key={index}
                    //           onClick={() => {
                    //             handleCustomerEmail(select);
                    //           }}
                    //           value={`${select.email}`}
                    //         >{`${select.email}`}</MenuItem>
                    //       );
                    //     })}
                    //   </Select>
                    // </FormControl>
                    <></>
                  )}
                  {/* <ButtonMaterial
                  onClick={handleAddCard}
                  variant="contained"
                  disableElevation
                  sx={{
                    background: text_col.col_primary,
                    borderRadius: "22px",
                  }}
                >
                  ADD CARD
                </ButtonMaterial> */}

                  <ButtonMaterial
                    onClick={verifyInputs}
                    // onClick={()=>{
                    //   setCheckOut(true)
                    // }}

                    variant="contained"
                    disableElevation
                    disabled={
                      !localStorage.getItem("super-key")
                        ? !selectF
                          ? true
                          : !noLicense ||
                            noLicense === "0" ||
                            (selectLicenceType === "Business" &&
                              noLicense !== "5" &&
                              noLicense !== Math.ceil(noLicense / 5) * 5)
                          ? true
                          : !selectLicenceType
                          ? true
                          : !selectPrice
                          ? true
                          : checkBox.length === 0
                          ? true
                          : getLoaderState
                          ? true
                          : skeleton
                          ? true
                          : workSpaceCount === "0"
                          ? true
                          : false
                        : // else condtion
                        !selectF
                        ? true
                        : !noLicense ||
                          noLicense === "0" ||
                          (selectLicenceType === "Business" &&
                            noLicense !== "5" &&
                            noLicense !== Math.ceil(noLicense / 5) * 5)
                        ? true
                        : !selectLicenceType
                        ? true
                        : !selectPrice
                        ? true
                        : checkBox.length === 0
                        ? true
                        : getLoaderState
                        ? true
                        : !customerEmail
                        ? true
                        : skeleton
                        ? true
                        : workSpaceCount === "0"
                        ? true
                        : false
                    }
                    sx={{
                      background: text_col.col_primary,
                      borderRadius: "22px",
                    }}
                  >
                    {" "}
                    {getLoaderState ? (
                      <CircularProgress
                        size="1.2em"
                        sx={{
                          position: "absolute",
                          right: "0",
                          mr: 1,
                          color: text_col.col_primary,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {selectLicenceType === "Business"
                      ? "Request Licence"
                      : "Buy Now"}
                  </ButtonMaterial>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </BootstrapDialog>
        </Box>

        <Box>
          <BootstrapDialog
            open={openDemo}
            onClose={handleCloseFreeLicence}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              // className="demoResp"
              sx={{
                background: "white",
                gap: "7px",
                paddingTop: "2%",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  //  width:'92%'
                  gap: "8px",
                  minWidth: tableQuery ? "297px" : "440px",
                }}
              >
                <Box
                  sx={{
                    height: "20%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "19px" }}>
                    FREE LICENCE
                  </Typography>
                  <CloseIcon
                    onClick={handleCloseFreeLicence}
                    style={{
                      cursor: "pointer",
                      color: "grey",
                      position: "absolute",
                      right: "5%",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: "red",
                    textTransform: "uppercase",
                    textAlign: "center ",
                  }}
                >
                  {error}
                </Typography>
                {!nextStepLicence ? (
                  <Box display="flex" flexDirection="column">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></Box>
                    <Box width="100%">
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                FEATURES
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                TRIAL LICENCE
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                COMMUNITY LICENCE
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Allowed projects</TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxAllowedProjectsCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Allowed scripts in projects</TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxScriptInProjectCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Allowed scripts in playlist</TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxScriptsInPlaylistCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Total scripts in single execution
                              </TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxScriptInExecutionCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Total execution in a day</TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxDailyExecutionCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Total steps in a script </TableCell>
                              <TableCell>No Limit</TableCell>
                              <TableCell>
                                {limitationsData?.maxTestStepInScriptCount}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Validity</TableCell>
                              <TableCell>30 Days</TableCell>
                              <TableCell>
                                <Typography>Lifetime</Typography>
                                <Typography sx={{ fontSize: "10px" }}>
                                  (Renews annually)
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Select licence type</TableCell>
                              {Array.of("Trial", "Community").map((type, i) => {
                                return (
                                  <TableCell>
                                    {" "}
                                    <Checkbox
                                      disableRipple
                                      checked={
                                        freeLicenseType === type ? true : false
                                      }
                                      onClick={() => handleSelectFreeL(type)}
                                      sx={{
                                        width: "22px",
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 20,
                                        },
                                        borderRadius: "12px",
                                        color: text_col.col_primary,
                                        "&.Mui-checked": {
                                          color: text_col.col_primary,
                                        },
                                      }}
                                    />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: text_col.col_primary,
                        display: "flex",
                        alignItems: "center",
                        background: "#F4F7FCBF",
                        width: "100%",
                        height: "50px",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        gap: "6px",
                      }}
                    >
                      Licence Type:{" "}
                      <Typography sx={{ color: "black" }}>
                        {freeLicenseType}
                      </Typography>
                    </Typography>
                    <Box>
                      <Typography sx={{ fontSize: "10px" }}>
                        Please Enter Your System Code*
                      </Typography>

                      <TextareaAutosize
                        className="textAreaLicence"
                        required
                        onChange={(e) => handleFreeTrail(e)}
                        aria-label="minimum height"
                        defaultValue={systemInfoCodeDemo}
                        minRows={3}
                        placeholder="Please Enter Your System Code*"
                        style={{
                          width: "100%",
                          // maxWidth: "463px",
                          minWidth: "130px",
                          height: "101px",
                          maxHeight: "101px",
                          resize: "none",
                        }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Tooltip
                          title={
                            "You will get this code from SoliteraTA tool after installation on the desired system."
                          }
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              background: text_col.col_primary,
                              width: "18px",
                              height: "18px",
                              borderRadius: "20px",
                              color: "white",
                              cursor: "pointer",
                            }}
                          >
                            i
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  height="62px"
                  borderTop="1px solid #cfd5e2"
                >
                  <ButtonOutlined
                    disabled={!nextStepLicence}
                    // onClick={handleDemo}
                    onClick={() => setNextStepLicence(false)}
                    sx={{ width: "22%" }}
                    variant="outlined"
                  >
                    <ArrowBack />
                    PREV{" "}
                  </ButtonOutlined>
                  <Box flex="1"></Box>
                  {nextStepLicence ? (
                    <ButtonMaterial
                      disabled={
                        !systemInfoCodeDemo || getLoaderState ? true : false
                      }
                      onClick={handleDemo}
                      sx={{ width: "108px" }}
                      variant="contained"
                    >
                      CONFIRM
                      {getLoaderState ? (
                        <CircularProgress
                          size="1.2em"
                          sx={{
                            position: "absolute",
                            right: "0",
                            mr: 1,
                            color: "white",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </ButtonMaterial>
                  ) : (
                    <ButtonOutlined
                      disabled={nextStepLicence}
                      onClick={() => setNextStepLicence(true)}
                      sx={{ width: "99px" }}
                      variant="outlined"
                    >
                      CONFIRM <ArrowForward />
                    </ButtonOutlined>
                  )}
                </Box>
                <Box sx={{ height: "34%" }}></Box>
              </Box>
            </Box>
          </BootstrapDialog>
        </Box>

        <Box>
          <BootstrapDialog
            open={openPaymentConfirm}
            onClose={() => setOpenPaymentConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              // className="demoResp"
              sx={{
                background: "white",
                gap: "7px",
                paddingTop: "2%",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "14px",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "19px" }}>
                  Payment confirmation
                </Typography>
                <CloseIcon
                  onClick={() => setOpenPaymentConfirm(false)}
                  style={{
                    cursor: "pointer",
                    color: "grey",
                    position: "absolute",
                    right: "5%",
                  }}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography>
                  Please check payment details section to confirm your payment
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <ButtonOutlined
                    onClick={() => {
                      setActiveModule(1);
                      localStorage.setItem("scroller", "true");
                      localStorage.setItem("state", "1");
                    }}
                    variant="outlined"
                  >
                    CHECK
                  </ButtonOutlined>
                  <Button
                    onClick={() => setOpenPaymentConfirm(false)}
                    variant="outlined"
                    color="error"
                  >
                    CANCEL
                  </Button>
                </Box>
              </Box>
            </Box>
          </BootstrapDialog>
        </Box>
        <Box>
          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={renewSnack}
            onClose={() => setRenewSnack(false)}
            TransitionComponent={transition}
            message="Licence renewed successfully"
            // key={transition ? transition.name : ""}
            autoHideDuration={2000}
            bodyStyle={{ minWidth: 20 }}
          />
        </Box>

        <Box>
          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={requestSnack}
            onClose={() => setRequestSnack(false)}
            TransitionComponent={transition}
            message={error}
            // key={transition ? transition.name : ""}
            autoHideDuration={2000}
            bodyStyle={{ minWidth: 20 }}
          />
        </Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={buySnack}
          onClose={() => setBuySnack(false)}
          TransitionComponent={transition}
          message="Licence purchased successfully"
          // key={transition ? transition.name : ""}
          autoHideDuration={2000}
          bodyStyle={{ minWidth: 20 }}
        />
        <Box>
          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={buyDemoSnack}
            TransitionComponent={transition}
            message="Free trial started sucessfully"
            key={transition ? transition.name : ""}
            autoHideDuration={2000}
            bodyStyle={{ minWidth: 20 }}
          />
        </Box>
      </Box>
    </>
  );
}

const TableBottomHeader = ({
  getLicenseAl_CTX,
  getLicenseList_CTX,
  tableQuery,
  result,
  search,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "37px",
        backgroundColor: "#F4F7FC",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        padding: "7px",
        flex: 1,
        position: "sticky",
        bottom: "0px",
      }}
    >
      <Box display="flex" alignItems="center" gap="20px">
        {search === "" ? (
          ""
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className="headerTxt"
              variant="h3"
              component="h2"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: text_col.col_primary,
              }}
            >
              Total Search Results: {!result ? "0" : result}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            className="headerTxt"
            variant="h3"
            component="h2"
            sx={{ fontSize: "12px", fontWeight: "600", color: "#687182" }}
          >
            Allocated Licences: {getLicenseAl_CTX.allocated}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            className="headerTxt"
            variant="h3"
            component="h2"
            sx={{ fontSize: "12px", fontWeight: "600", color: "#687182" }}
          >
            Unallocated Licences: {getLicenseAl_CTX.unallocated}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            className="headerTxt"
            variant="h3"
            component="h2"
            sx={{ fontSize: "12px", fontWeight: "600", color: "#687182" }}
          >
            Expired Licences: {getLicenseAl_CTX.expired}
          </Typography>
        </Box>
        <Typography
          className="headerTxt"
          variant="h3"
          component="h2"
          sx={{ fontSize: "12px", fontWeight: "600", color: "#687182" }}
        >
          Total Licences: {getLicenseList_CTX.length}
        </Typography>
      </Box>
    </Box>
  );
};
