import "./App.css";
import { ApplicationContextProvider } from "./lib/context/applicationCtx";
import { BrowserRouter } from "react-router-dom";
import RoutesList from "./lib/router";
import ApplicationWrappers from "./lib/components/applicationWrappers";

function App() {
  return (
    <>
      {/* <AuthMain /> */}
      <ApplicationContextProvider>
        <BrowserRouter basename="/app">
          <ApplicationWrappers>
          <RoutesList />

          </ApplicationWrappers>
        </BrowserRouter>
      </ApplicationContextProvider>
    </>
  );
}

export default App;
