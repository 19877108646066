import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import { stringAvatar } from '../../utility/stringManipulation';
import { LicenseIcon} from '../../constants/icons';
import { Logout } from '@mui/icons-material';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ApplicationContext } from '../../context/applicationCtx';



function NavItems({getActiveModule, setActiveModule, children, theme, stateValue, value, setLoaderState, setActiveEditorFiles, setActiveFile, setElementActiveEditorFiles, setElementActiveFile, setExpandedTreeView}){
    return <Grid item mb={1} display="flex" flexDirection="column" alignItems="center" sx={{ cursor: 'pointer' }}
        onClick={() => {
            setActiveModule(stateValue);
            setLoaderState(true)
            if(stateValue === 1 || stateValue === 5){
                if(stateValue !== getActiveModule){
                    setActiveEditorFiles([])
                    setActiveFile(null)
                    setElementActiveEditorFiles([])
                    setElementActiveFile(null)
                    setExpandedTreeView([])
                }
            }
        }}>
        <Avatar  sx={{ bgcolor: 'transparent' }}>
            {children}
        </Avatar>
        <Typography fontSize="11px" textAlign="center"
                    color={(getActiveModule === stateValue ? `${theme.palette.primary.main}` : '#555555')} 
                    sx={{ color: (getActiveModule === stateValue ? `${theme.palette.primary.main}` : '#555555') }}
                    >
                    {value}
        </Typography>
    </Grid>;

}


export default function NavigationRailSuperadmin({getActiveModule}) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {setState, getSupUserDetail_CTX} = useContext(ApplicationContext);
    // console.log(getSupUserDetail_CTX,'superr')
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };




  const currentValue = function (value) {
    switch (value) {
      case 0:
        return 'Licence';
      case 1:
        return 'Payment Method';
      default:
        console.log(value);
        break;
    }
  }
   
 
            
    return (
    //* NavigationRail Start
    <>
   
        <Grid
        item
        md={0.6}
        sm={1}
        container
        direction='column'
        height="100vh"
        bgcolor="#ffffff" 
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            zIndex="1"
            position="fixed"
            
        >
           
            {/* 
            //!    NavigationRail Header Start 
            */}
            <Box
            component="div"
            height="10vh"
            display="flex"
            justifyContent="center"
            alignItems="center"   
            >
                {/* Main LOGO - Start */}
                <Button>
                    <Avatar  sx={{ bgcolor: `${theme.palette.primary.main}` }} variant="rounded">
                    TA
                    </Avatar>
                </Button>
                {/* Main LOGO - End */}
            
            </Box>
            {/* 
            //!     NavigationRail Header End 
            */}

            {/* 
            //! NavigationRail Main Start 
            */}
            <Box
                 
                component="div"
                height="70vh"
                display="flex"
                justifyContent="center"
                alignItems="center"   
            >
                <Grid item container alignItems="center" direction="column"> 

                    <NavItems 
                         value={currentValue(0)} 
                        theme={theme} 
                       
                     >
                            <LicenseIcon
                            onClick={()=>{setState(0)}}
                            color={`${theme.palette.primary.main}`}
                            size="30"
                            
                            
                            
                            />
                    </NavItems>
                  
                </Grid>

            </Box>
            {/* 
                //! NavigationRail Main End 
            */}

          {/* NavigationRail Footer Start */}
            <Box
            component="div"
            height="20vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"   
            >
                <Tooltip  placement="top" title={'Account Settings'} >
                <IconButton 
                onClick={handleClick}
                 aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                    {/** TODO: User to be passed from props */}
                   <Avatar {...stringAvatar(`${getSupUserDetail_CTX.firstName} ${getSupUserDetail_CTX.lastName}`)} /> 
                </IconButton></Tooltip>
            </Box>
            {/* NavigationRail Footer End */}
            <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,marginLeft:'60px',marginTop:'-29px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 23,
              right: 113,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       

        <Link to="/login" style={{textDecoration:'none',color:'black'}}>
        <MenuItem onClick={()=>{
              window.localStorage.clear();
          }}>
          <ListItemIcon >
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        </Link>
      </Menu>
      {/* <ActiveModuleSup value={state} /> */}
        </Grid>
       
        </>
        // NavigationRail End
    );
}


