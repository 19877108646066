import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
  Slide,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  InputAdornment,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import styles from "./mystyle.module.css";
import {
  IconDesktop,
  IconDetails,
  IconMobile,
  IconWeb,
} from "../../../constants/icons";
import FormControl from "@mui/material/FormControl";
import { deleteLic, getLeads, getLicenseDetails, postLeads } from "../../../services/license";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { StatusCodes } from "http-status-codes";
// import { ModuleType } from "../../../constants/application";
// import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {
  BootstrapDialog,
  ButtonMaterial,
  CssTextField,
  text_col,
} from "../../../constants/constStyles";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableRowWithData } from "./licenceTableRow";
import { LicenceInfo } from "../../payment-details";

export function TableRowWithDataLeads({
  license,
  index,
  // getCardsList_CTX,
  setLicenseAl_CTX,
  setOpenPaymentConfirm,
  setLicenseList_CTX,
}) {
  let validDate = new Date(license.validTill);
  // States
  // const { getCardsList_CTX, setLicenseAl_CTX, setLicenseList_CTX } =
  //   useContext(ApplicationContext);
  const requestQuery = useMediaQuery("(max-width:800px)");

  const [getLoaderState, setLoaderState] = useState(false);
  const [error, setError] = useState(null);
  const [transition] = useState(undefined);
  const [openRevokeSnack, setOpenRevokeSnack] = useState(false);
  const [openRequestSnack, setOpenRequestSnack] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openLicInfo, setOpenLicInfo] = useState(false);
  const [filterUserDetail, setFilterUserDetail] = useState({});
  const [selectF] = useState(license?.frequency);
  const [selectLicenseType] = useState(license?.licenseType);
  const [openApproveSnack, setOpenApproveSnack] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [responseSnack, setResponseSnack] = useState("");

  const [checkBox, setCheckbox] = useState(["WEB", "MOBILE", "DESKTOP"]);
  // const [checkBox, setCheckbox] = useState([]);
  const [amount, setAmount] = useState("");
  const [platformType, setPlatformType] = useState(true);
let validDateInfo = new Date(filterUserDetail.licenseDTO?.validTill);
const [noOfUsers,setNoOfUsers] = useState('')

  // const [select setSelect] = useState();
  // const [setSelect] = useState();

  // Licence renew function for api request
  const handleLead = async function () {
    sessionStorage.setItem("paymentType", "renew");
    try {
      // console.log(select)
      setLoaderState(true);

      let response = await postLeads({
        noOfLicenses: noOfUsers,
        frequency: selectF,
        licenseType: selectLicenseType,
        licenseNo: license.licenseNo,
        licenseCost: amount,
        mobileModule: checkBox.includes("MOBILE"),
        webModule: checkBox.includes("WEB"),
        desktopModule: checkBox.includes("DESKTOP"),
      });
      console.log(response, "postLeads");
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }

      if (response.status === StatusCodes.OK) {
        setLoaderState(false);
        setOpenRequestDialog(false);
        setResponseSnack(response.data.message);
        setOpenRequestSnack(true);
        setTimeout(async () => {
          let reloadList = await getLeads();
          setLicenseList_CTX(reloadList.data.licenseList);
        }, 1000);
        // const setSnack = (Transition) => {
        //   setTransition(() => Transition);
        // };
        // setSnack();
        // setTimeout(() => {
        //   setRenewSnack(false);
        //   setError("");
        // }, 2000);
        // setAge("");
      } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError("Fields cant be empty");
        setLoaderState(false);
      }
    } catch (err) {
      // console.log("ERR =>",err);
      setLoaderState(false);
      // setOpenRenew(false);
      if (err.data) {
        // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
      } else {
        if (err.errorMsg) {
          // setStepLicenseKey(err)
          console.log(err.errorMsg);
        } else {
          console.error("UNKNOWN ERROR", err);
        }
      }
    }
  };

const handleLeadDelete = async() =>{
  setLoaderState(true);
  try {
    let response = await deleteLic({
      licenseNo: license.licenseNo,
    });
    console.log(response);
    if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      setLoaderState(false);
      setError("Something went wrong");
    }
    if (response.status === StatusCodes.NOT_FOUND) {
      setLoaderState(false);
      setError(response.data.message);
    }
    if (response.status === StatusCodes.OK) {
      setResponseSnack(response.data.message)
      setOpenDeleteDialog(false);;
      setLoaderState(false);
      // setError(response.data.message);
      setOpenRequestSnack(true);
      setTimeout(async() => {
        let reloadList = await getLeads();
        setLicenseList_CTX(reloadList.data.licenseList);
        // setLicenseAl_CTX(reloadList.data);
      setOpenRequestDialog(false);
        setOpenRequestSnack(false);
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
}

  // const handleSelect = (selectCard) => {
  //   // console.log(selectCard.cardId)
  //   setSelect(selectCard.cardId);
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const handleSelectF = (selecters) => {
  //   console.log(selecters, "checkDefaultFrequency");
  //   setSelectF(selecters);
  // };
  const handleCheckBox = (data) => {
    console.log(platformType, "fromCheck");
    !checkBox.includes(data)
      ? checkBox.push(data)
      : checkBox.splice(checkBox.indexOf(data), 1);
    setCheckbox([...checkBox]);
    console.log(checkBox);
    console.log(checkBox, "checklength");
    if (checkBox.length === 0) {
      setPlatformType(false);
    }
    if (checkBox.length === 3) {
      setPlatformType(true);
    }
  };

  const handleSelectPT = (e) => {
    console.log(e.target.checked, "fromPT");
    setPlatformType(e.target.checked);
    checkBox.length = 0;
    if (e.target.checked) {
      checkBox.push("WEB", "MOBILE", "DESKTOP");
      setCheckbox([...checkBox]);
    }

    // if(e.target.checked){
    //    checkBox.length = 0
    //    setPlatformType(false)
    //   console.log('yes')

    // }

    // else{

    //    setPlatformType(true)
    //    checkBox.push("WEB","MOBILE","DESKTOP")
    // }
  };

  // const handleSelectLT = (type) => {
  //   setSelectLicenceType(type);
  // };

  const handleClose = () => {
    setOpenRequestDialog(false);
    setLoaderState(false);
    setAmount("");
    setNoOfUsers("");
    setError("");
  };

  const getPerLicenseUser = async (licenseNo, cellStatus) => {
    try {
      let response = await getLicenseDetails(licenseNo);
      console.log(response, "licencePerUser");
      if (response.status === StatusCodes.OK) {
        setNoOfUsers(response.data.licenseDTO.allowedUserCount)
        setFilterUserDetail(response.data);
        if(cellStatus){

          setOpenLicInfo(true);
        }
      }
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setResponseSnack("Something went wrong");
        setOpenApproveSnack(true);
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setFilterUserDetail({});
        setResponseSnack(response.data.message);
          setOpenApproveSnack(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
console.log(filterUserDetail,'filter')


  return (
    <>
      {/* Table Body UI Start */}
      <Slide in>
        <TableRow>
          <TableCell  sx={{ color: " #171C26", }} align="left">
          <Tooltip
                    arrow
                    placement="right-start"
                    title={<Typography>Click to see licence details</Typography>}
                  >


                     <Typography onClick={()=>getPerLicenseUser(license.licenseNo,true)} sx={{fontSize:'14px', width:'fit-content',fontWeight: "bold",color:text_col.col_primary,'&:hover':{textDecoration:'underline',cursor:"pointer"}}} >
              {license.licenseNo}
              </Typography>
                  </Tooltip>
          </TableCell>
          {license.email === null ? (
            <>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#171C26",
                  }}
                >
                  {license.licenseType}{" "}
                  {license.licenseType === "Business"
                    ? `- ${license.allowedUserCount}`
                    : ""}
                </Typography>
                {license.status === "Pending" ||
                license.status === "Requested" ? (
                  ""
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#687182",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Valid Upto: {validDate.getDate()}{" "}
                    {validDate.toDateString().split(" ")[1]}{" "}
                    {validDate.getFullYear()}
                  </Typography>
                )}
              </TableCell>
              <TableCell colSpan={1} sx={{ textAlign: "left" }}>
                <Typography
                  // className={styles.unallo}
                  onClick={() => {
                    license.status === "Pending" &&
                    license.licenseType === "Business" ? (
                      <></>
                    ) : license.licenseType === "Business" &&
                      license.status === "Requested" ? (
                      <></>
                    ) : (
                      // handleClickOpen()
                      <></>
                    );
                  }}
                  sx={{
                    color: "black",
                    // cursor: "pointer",
                    fontSize: "14px",
                    "&:hover": {
                      textDecoration:
                        license.status === "Pending" &&
                        license.licenseType === "Business"
                          ? "none"
                          : license.licenseType === "Business" &&
                            license.status === "Requested"
                          ? "none"
                          : "underline",
                    },
                  }}
                >
                  {license.status === 'Requested' ? 'Processing' : '----'}
                </Typography>
              </TableCell>
              {/* <TableCell align="left">{license.email}</TableCell> */}
              <TableCell align="left">
                <Box
                  sx={{
                    width: "max-content",
                    backgroundColor:
                      license.status === "Active"
                        ? "#E1FCEF"
                        : license.status === "Unallocated"
                        ? "#F0F1FA"
                        : license.status === "Pending"
                        ? "#FFF3C5"
                        : license.status === "Expired"
                        ? "#FAF0F3"
                        : "#eeeeee",
                    borderRadius: "10px",
                    padding: "2px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        license.status === "Active"
                          ? "#14804A"
                          : license.status === "Unallocated"
                          ? "#4F5AED"
                          : license.status === "Pending"
                          ? "#F7B700"
                          : license.status === "Expired"
                          ? "#D12953"
                          : "grey",
                    }}
                  >
                    {license.status}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography sx={{ display: "flex", gap: "6px" }}>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.webModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Web
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.mobileModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Mobile
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.desktopModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Desktop
                    </Typography>
                  </Box>
                </Typography>
              </TableCell>

              {license.status === "Pending" ||
              license.status === "Requested" ||
              license.status === "Expired" ? (
                <>
                  <TableCell align="center">
                    <Typography display={'flex'} justifyContent='center' gap={'12px'}>

                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "12px" }}>
                          Request Payment
                        </Typography>
                      }
                      arrow
                    >
                      <Typography
                        onClick={() => {
                          getPerLicenseUser(license.licenseNo,false)
                          setOpenRequestDialog(true);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {/* <DeleteIcon color="error" /> */}
                        <Button variant="outlined">REQUEST PAYMENT</Button>
                      </Typography>
                    </Tooltip>
                      <Tooltip
                      title={
                        <Typography sx={{ fontSize: "12px" }}>
                          Cancel Request
                        </Typography>
                      }
                      arrow
                    >
                      <Typography
                        onClick={() => {
                          getPerLicenseUser(license.licenseNo,false)
                          // setOpenRequestDialog(true);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {/* <DeleteIcon color="error" /> */}
                        <Button
                      // onClick={handleLeadDelete}
                      onClick={()=>setOpenDeleteDialog(true)}
                      variant={'outlined'}
                      color='error'
                      // disabled={
                      //   getLoaderState 
                      //     ? true
                      //     : false
                      // }
                    >
                     
                      CANCEL REQUEST
                    </Button>
                      </Typography>
                    </Tooltip>
                    </Typography>
                  </TableCell>
                </>
              ) : (
                <></>
              )}
              <></>
            </>
          ) : (
            <></>
          )}
        </TableRow>
      </Slide>

      <Box>
        <BootstrapDialog
          open={openDeleteDialog}
          onClose={() => {
            setOpenDeleteDialog(false);
            // setLoaderState(false);
            setError("");
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "340px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              paddingTop: "4%",
              gap: "15px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>Cancel Request</Typography>
              <CloseIcon
                onClick={() => {
                  setOpenDeleteDialog(false);
                  setLoaderState(false);
                  setError("");
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ color: "red" }}>{error}</Typography>
              <Box width={"18px"}></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "86%",
                  gap: "2px",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Please confirm to cancel the request :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}>
                    Assigned To:
                  </Typography>{" "}
                  {!license.email ? "Not yet assigned" : license.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      Licence Type:
                    </Typography>{" "}
                    {license.licenseType}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}> Modules:</Typography>{" "}
                  {license.webModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Web ,
                  {license.mobileModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Mobile ,
                  {license.desktopModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Desktop
                </Typography>
                {/* <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      // gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}> Valid Upto</Typography>{" "}
                    : {validDate.getDate()}{" "}
                    {validDate.toDateString().split(" ")[1]}{" "}
                    {validDate.getFullYear()}
                  </Typography> */}
              </Box>
              <Box width={"18px"}></Box>
            <Box
              sx={{
                width: "100%",
                height: "68px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3%",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                disabled={getLoaderState}
                sx={{ width: "130px" }}
                onClick={handleLeadDelete}
              >
                Cancel{" "}
                {getLoaderState ? (
                  <CircularProgress
                    size="1.2em"
                    sx={{
                      position: "absolute",
                      right: "0",
                      mr: 1,
                      color: "red",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
            </Box>
          </Box>
        </BootstrapDialog>
      </Box>
      <Box>
        <BootstrapDialog
          open={openLicInfo}
          onClose={() => {
            setOpenLicInfo(false);
            setLoaderState(false);
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "360px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              padding: "4% 0% 4%",
              gap: "7px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
                gap: "4px",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>
                {"Licence Details"}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpenLicInfo(false);
                  setLoaderState(false);
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ textAlign: "center", color: "red" }}>
                {error}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "86%",
                gap: "2px",
              }}
            >
              <LicenceInfo
                details={license}
                filterUserDetail={filterUserDetail}
                validDate={validDateInfo}
                tableType={'licence'}
              />
            </Box>

            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>

      <Box>
        <BootstrapDialog
          keepMounted={false}
          open={openRequestDialog}
          onClose={handleClose}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "325px",
              maxHeight: "100%",
              // height: "73%",
              width: "39%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              padding: "4% 0% 2% 0%",
              gap: "15px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>Request Payment</Typography>
              <CloseIcon
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box display={'flex'} width='100%' flexDirection={requestQuery ? 'column' : 'row'}>

            <Box width={"90%"} padding="0px 30px" borderRight={requestQuery ? 'none' :'1px solid #b9bdc5'}>
              <Typography
                sx={{ width: "100%", textAlign: "center", color: "red" }}
              >
                {error}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  Licence Type{" "}
                  <Tooltip
                    arrow
                    placement="right-start"
                    title={
                      <Box
                        sx={{
                          // width: "400px",
                          gap: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            borderBottom: "1px solid white",
                            paddingBottom: "8px",
                          }}
                        >
                          Business : Deployment on server, Multiple Users
                          Access, Team Collaborations
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          Individual : Deployment on self pc, Single User
                          Access, No Collaboration.
                        </Typography>
                      </Box>
                    }
                  >
                    <InfoIcon
                      sx={{
                        fontSize: "16px",
                        color: text_col.col_primary,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Box>
                  <FormControl
                  // sx={{
                  //   pointerEvents:
                  //     priceList.length === 0 ? "none" : "all",
                  // }}
                  >
                    <RadioGroup
                      sx={{ alignItems: "center" }}
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={selectLicenseType}
                    >
                      {Array.of("Business", "Individual").map((type, i) => {
                        return (
                          <FormControlLabel
                            // disabled={
                            //   priceList.length === 0 ? true : false
                            // }
                            key={i}
                            value={type}
                            // onClick={() => handleSelectLT(type)}
                            control={
                              <Radio
                                sx={{
                                  color: text_col.col_primary,
                                  "&.Mui-checked": {
                                    color: text_col.col_primary,
                                  },
                                }}
                              />
                            }
                            label={type}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              {/* <Typography>njknkn</Typography> */}

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>Duration:</Typography>
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={selectF}
                    >
                      {Array.of("Annually", "Monthly").map((type, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            value={type}
                            control={
                              <Radio
                                // onClick={() => handleSelectF(type)}
                                sx={{
                                  color: text_col.col_primary,
                                  "&.Mui-checked": {
                                    color: text_col.col_primary,
                                  },
                                }}
                              />
                            }
                            label={type}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", gap: "9px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    width: "100%",
                  }}
                >
                  <Typography display={"flex"} alignItems="center">
                    Modules:
                  </Typography>
                  <Typography display={"flex"} alignItems="center">
                    <Checkbox
                      disableRipple
                      indeterminate={
                        checkBox.length !== 0 && checkBox.length !== 3
                          ? true
                          : false
                      }
                      checked={
                        platformType || checkBox.length === 3 ? true : false
                      }
                      onChange={(e) => handleSelectPT(e)}
                      sx={{
                        width: "22px",
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          color: text_col.col_primary,
                        },
                        borderRadius: "12px",
                        color: text_col.col_primary,
                        "&.Mui-checked": {
                          color: text_col.col_primary,
                        },
                      }}
                    />
                    Select All{" "}
                  </Typography>

                  <Box
                    className="purchaseModuleMainBox"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box
                      className="purchaseModuleLB"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        // minWidth: "200px",
                        // width: "362px",
                      }}
                    >
                      {Array.of("WEB", "MOBILE", "DESKTOP").map((data, i) => {
                        return (
                          <Box
                            key={i}
                            onClick={() => handleCheckBox(data)}
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              height: "37px",
                              borderRadius: "10px",
                              border: checkBox.includes(data)
                                ? `1px solid ${text_col.col_primary}`
                                : "1px solid #212121",
                              display: "flex",
                              alignItems: "center",
                              gap: "9px",
                              paddingLeft: "3px",
                              paddingRight: "7px",
                            }}
                          >
                            <Checkbox
                              disableRipple
                              checked={checkBox.includes(data) ? true : false}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 20 },
                                borderRadius: "12px",
                                color: text_col.col_primary,
                                "&.Mui-checked": {
                                  color: text_col.col_primary,
                                },
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: i === 0 ? "9px" : i === 2 ? "15px" : "6px",
                                flex: 1,
                              }}
                            >
                              {i === 0 ? (
                                <IconWeb />
                              ) : i === 1 ? (
                                <IconDesktop />
                              ) : i === 2 ? (
                                <IconMobile />
                              ) : (
                                ""
                              )}
                              <Typography textTransform={'capitalize'}>{data?.toLowerCase()}</Typography>
                            </Box>
                            <Tooltip
                              arrow
                              placement="right"
                              title={
                                <Typography sx={{ fontSize: "14px" }}>
                                  {Array.of(
                                    "Web: This module is used to create and execute reusable tests for all types of web apps, including those built with popular modern frameworks.",
                                    "Mobile : This module is used to create and automate functional UI tests on physical or virtual iOS and Android devices with ease, saving you time and effort.",
                                    "Desktop : This module is used to test windows based applications and is already equipped with all predefined desktop operations."
                                  ).map((info, infoIndex) => {
                                    if (
                                      info
                                        ?.toLowerCase()
                                        .includes(data?.toLowerCase())
                                    ) {
                                      return info;
                                    }
                                    return false;
                                  })}
                                </Typography>
                              }
                            >
                              <InfoIcon
                                sx={{
                                  fontSize: "16px",
                                  color: text_col.col_primary,
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </Box>
                          <CssTextField
                          size="small"
                            placeholder="No of Users"
                            required
                            type={"number"}
                            label="No of Users"
                           
                            value={noOfUsers < 0 ? 0 : noOfUsers}
                            onChange={(e) => {
                              setNoOfUsers(e.target.value > 50 ? 50 : e.target.value);
                              console.log(e.target.value);
                            }}
                            fullWidth
                          />
                     
                    <CssTextField
                    size="small"
                      placeholder="Amount"
                      required
                      type={"number"}
                      label="Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &pound;
                          </InputAdornment>
                        ),
                      }}
                      value={amount < 0 ? 0 : amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        console.log(e.target.value);
                      }}
                      fullWidth
                    />
                    <ButtonMaterial
                      onClick={handleLead}
                      disabled={
                        getLoaderState || !amount || amount === "0"
                          ? true
                          : false
                      }
                      sx={{ borderRadius: "22px" }}
                      variant="contained"
                    >
                      {getLoaderState ? (
                        <CircularProgress
                          size="1.2em"
                          sx={{
                            position: "absolute",
                            right: "0",
                            mr: 1,
                            color: text_col.col_primary,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      REQUEST PAYMENT
                    </ButtonMaterial>
                    {/* <Button
                      onClick={handleLeadDelete}
                      variant={'outlined'}
                      color='error'
                      disabled={
                        getLoaderState 
                          ? true
                          : false
                      }
                      sx={{ borderRadius: "22px" }}
                    >
                      {getLoaderState ? (
                        <CircularProgress
                          size="1.2em"
                          sx={{
                            position: "absolute",
                            right: "0",
                            mr: 1,
                            color: text_col.col_primary,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      CANCEL PAYMENT
                    </Button> */}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection='column' width='100%' padding="0px 30px" paddingTop={requestQuery ? '17px' :'0px'}>
            <LicenceInfo
                details={license}
                filterUserDetail={filterUserDetail}
                validDate={validDateInfo}
                tableType={'licence'}
              />
            </Box>
            </Box>
            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openApproveSnack}
          onClose={() => setOpenApproveSnack(false)}
          message={responseSnack}
          autoHideDuration={2000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openRevokeSnack}
          onClose={() => setOpenRevokeSnack(false)}
          TransitionComponent={transition}
          message={"Licence revoked successfully"}
          autoHideDuration={2000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openRequestSnack}
          onClose={() => setOpenRequestSnack(false)}
          TransitionComponent={transition}
          message={responseSnack}
          autoHideDuration={1000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
    </>
    // Table body ui end
  );
}

export function TableRowWithDataSuper({
  license,
  index,
  // getCardsList_CTX,
  setLicenseAl_CTX,
  setLicenseList_CTX,
}) {
  let validDate = new Date(license.createdAt);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDetail = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetail = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Table Body UI Start */}
      <Slide in>
        <TableRow
          sx={{
            cursor: "pointer",
            boxShadow: openCollapse ? "0px 0px 9px 0px grey" : "0",
            "&:hover": { boxShadow: "0px 2px 7px -2px grey" },
          }}
        >
          <TableCell sx={{ width: "0%" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenCollapse(!openCollapse)}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell sx={{ color: " #171C26", fontWeight: "600" }} align="left">
            {index + 1}.
          </TableCell>
          {license.email === null ? (
            // <>
            //   <TableCell>
            //     <Typography
            //       sx={{
            //         fontSize: "14px",
            //         fontWeight: "bold",
            //         color: "#171C26",
            //       }}
            //     >
            //       {license.licenseType}{" "}
            //       {license.licenseType === "Business"
            //         ? `- ${license.allowedUserCount}`
            //         : ""}
            //     </Typography>
            //     {license.status === "Pending" ? (
            //       ""
            //     ) : (
            //       <Typography
            //         sx={{
            //           fontSize: "12px",
            //           color: "#687182",
            //           whiteSpace: "nowrap",
            //         }}
            //       >
            //         Valid Upto: {validDate.getDate()}{" "}
            //         {validDate.toDateString().split(" ")[1]}{" "}
            //         {validDate.getFullYear()}
            //       </Typography>
            //     )}
            //   </TableCell>
            //   <TableCell colSpan={1} sx={{ textAlign: "left" }}>
            //     <Typography
            //       className={styles.unallo}
            //       onClick={handleClickOpen}
            //       style={{
            //         color: "black",
            //         cursor: "pointer",
            //         fontSize: "14px",
            //       }}
            //     >
            //       {currentDate <= validDate
            //         ? "Click Here To Allocate"
            //         : "Payment For This Licence Has Not Been Done"}
            //     </Typography>
            //   </TableCell>
            //   <TableCell align="left">{license.email}</TableCell>
            //   <TableCell align="left">
            //     <Box
            //       sx={{
            //         width: "max-content",
            //         backgroundColor:
            //           license.status === "Allocated"
            //             ? "#E1FCEF"
            //             : license.status === "Unallocated"
            //             ? "#F0F1FA"
            //             : license.status === "Pending"
            //             ? "#FFF3C5"
            //             : license.status === "Expired"
            //             ? "#FAF0F3"
            //             : "white",
            //         borderRadius: "10px",
            //         padding: "2px 8px",
            //       }}
            //     >
            //       <Typography
            //         sx={{
            //           fontSize: "12px",
            //           color:
            //             license.status === "Allocated"
            //               ? "#14804A"
            //               : license.status === "Unallocated"
            //               ? "#4F5AED"
            //               : license.status === "Pending"
            //               ? "#F7B700"
            //               : license.status === "Expired"
            //               ? "#D12953"
            //               : "white",
            //         }}
            //       >
            //         {license.status}
            //       </Typography>
            //     </Box>
            //   </TableCell>
            //   <TableCell>
            //     <Typography sx={{ display: "flex", gap: "6px" }}>
            //       <Box
            //         sx={{
            //           width: "max-content",
            //           backgroundColor: "#E9EDF5",
            //           borderRadius: "10px",
            //           padding: "2px 8px",
            //         }}
            //       >
            //         <Typography
            //           sx={{
            //             fontSize: "12px",
            //             color: "#5A6376",
            //             display: "flex",
            //             alignItems: "center",
            //             gap: "4px",
            //           }}
            //         >
            //           {license.webModule ? (
            //             <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            //           ) : (
            //             <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            //           )}{" "}
            //           Web
            //         </Typography>
            //       </Box>

            //       <Box
            //         sx={{
            //           width: "max-content",
            //           backgroundColor: "#E9EDF5",
            //           borderRadius: "10px",
            //           padding: "2px 8px",
            //         }}
            //       >
            //         <Typography
            //           sx={{
            //             fontSize: "12px",
            //             color: "#5A6376",
            //             display: "flex",
            //             alignItems: "center",
            //             gap: "4px",
            //           }}
            //         >
            //           {license.mobileModule ? (
            //             <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            //           ) : (
            //             <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            //           )}{" "}
            //           Mobile
            //         </Typography>
            //       </Box>
            //       <Box
            //         sx={{
            //           width: "max-content",
            //           backgroundColor: "#E9EDF5",
            //           borderRadius: "10px",
            //           padding: "2px 8px",
            //         }}
            //       >
            //         <Typography
            //           sx={{
            //             fontSize: "12px",
            //             color: "#5A6376",
            //             display: "flex",
            //             alignItems: "center",
            //             gap: "4px",
            //           }}
            //         >
            //           {license.desktopModule ? (
            //             <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            //           ) : (
            //             <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            //           )}{" "}
            //           Desktop
            //         </Typography>
            //       </Box>
            //     </Typography>
            //   </TableCell>

            //   <TableCell align="left">
            //     <Tooltip
            //       title={
            //         <Typography sx={{ fontSize: "12px" }}>
            //            { license.status === 'Pending' || license.status === "Expired" ? "Retry Payment" : "Renew licence"}
            //         </Typography>
            //       }
            //       arrow
            //     >
            //       <Typography
            //         sx={{ cursor: "pointer" }}
            //         onClick={handleOpenRenew}
            //       >
            //         <IconRenew />
            //       </Typography>
            //     </Tooltip>
            //   </TableCell>
            //   {license.status === "Pending" || license.status === "Expired" ? (
            //     <>
            //       <TableCell>
            //         <Tooltip
            //           title={
            //             <Typography sx={{ fontSize: "12px" }}>
            //               Delete licence
            //             </Typography>
            //           }
            //           arrow
            //         >
            //           <Typography
            //             onClick={() => {
            //               setOpenDeleteDialog(true);
            //               setLicenseId(license.licenseNo);
            //               console.log(license.status);
            //             }}
            //             sx={{ cursor: "pointer" }}
            //           >
            //             <IconRevoke />
            //           </Typography>
            //         </Tooltip>
            //       </TableCell>
            //     </>
            //   ) : (
            //     <>
            //       <TableCell>
            //         <Tooltip
            //           title={
            //             <Typography sx={{ fontSize: "12px" }}>
            //               Allocate licence
            //             </Typography>
            //           }
            //           arrow
            //         >
            //           <Typography
            //             sx={{ cursor: "pointer" }}
            //             onClick={() => {
            //               handleClickOpen();
            //               setreassignSnack(true);
            //             }}
            //           >
            //             <IconReassign />
            //           </Typography>
            //         </Tooltip>
            //       </TableCell>
            //     </>
            //   )}
            //   <TableCell>
            //     <Tooltip
            //       title={
            //         <Typography sx={{ fontSize: "12px" }}>Details</Typography>
            //       }
            //       arrow
            //     >
            //       <Typography sx={{ cursor: "pointer", opacity: 0.1 }}>
            //         <IconDetails />
            //       </Typography>
            //     </Tooltip>
            //     <Popover
            //       anchorEl={anchorEl}
            //       open={openDetail}
            //       onClose={handleCloseDetail}
            //       PaperProps={{
            //         style: {
            //           width: "auto",
            //           display: "flex",
            //           flexDirection: "column",
            //           padding: "8px 11px",
            //           gap: "4px",
            //           borderRadius: 6,
            //         },
            //       }}
            //       anchorOrigin={{
            //         vertical: "top",
            //         horizontal: "left",
            //       }}
            //       transformOrigin={{
            //         vertical: "top",
            //         horizontal: "center",
            //       }}
            //     >
            //       <Typography
            //         sx={{
            //           color: text_col.col_primary,
            //           display: "flex",
            //           fontSize: "14px",
            //           gap: "6px",
            //           textTransform: "capitalize",
            //         }}
            //       >
            //         Name:{" "}
            //         <Typography sx={{ color: "black", fontSize: "14px" }}>
            //           {license.firstName}
            //         </Typography>
            //       </Typography>
            //       <Typography
            //         sx={{
            //           color: text_col.col_primary,
            //           display: "flex",
            //           fontSize: "14px",
            //           gap: "6px",
            //         }}
            //       >
            //         Phone:{" "}
            //         <Typography sx={{ color: "black", fontSize: "14px" }}>
            //           {license.phoneNum}
            //         </Typography>
            //       </Typography>
            //       <Typography
            //         sx={{
            //           color: text_col.col_primary,
            //           display: "flex",
            //           fontSize: "14px",
            //           gap: "6px",
            //         }}
            //       >
            //         Valid Upto:{" "}
            //         <Typography sx={{ color: "black", fontSize: "14px" }}>
            //           {validDate.getDate()}{" "}
            //           {validDate.toDateString().split(" ")[1]}{" "}
            //           {validDate.getFullYear()}
            //         </Typography>
            //       </Typography>
            //     </Popover>
            //   </TableCell>
            // </>
            <></>
          ) : (
            <>
              <TableCell align="left">{license.email}</TableCell>
              <TableCell align="left">{license.organisationName}</TableCell>

              <TableCell align="left">
                <Box
                  sx={{
                    width: "max-content",
                    backgroundColor:
                      license.status === "Active"
                        ? "#E1FCEF"
                        : license.status === "Unallocated"
                        ? "#F0F1FA"
                        : license.status === "Pending"
                        ? "#FFF3C5"
                        : license.status === "Expired"
                        ? "#FAF0F3"
                        : "#eeeeee",
                    borderRadius: "10px",
                    padding: "2px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        license.status === "Active"
                          ? "#14804A"
                          : license.status === "Unallocated"
                          ? "#4F5AED"
                          : license.status === "Pending"
                          ? "#F7B700"
                          : license.status === "Expired"
                          ? "#D12953"
                          : "grey",
                    }}
                  >
                    {/* {license.status === "Active" ? "Allocated" : license.status} */}
                    {license.status}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell align="center">
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "12px" }}>
                      Additional info
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                  >
                    <IconDetails />
                  </Typography>
                </Tooltip>
                <Popover
                  anchorEl={anchorEl}
                  open={openDetail}
                  onClose={handleCloseDetail}
                  PaperProps={{
                    style: {
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px 13px",
                      gap: "4px",
                      borderRadius: 6,
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Additional Info:
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "6px",
                      textTransform: "capitalize",
                    }}
                  >
                    Name:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {license.firstName}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "6px",
                    }}
                  >
                    Phone:{" "}
                    {license.phoneNum !== "" ? (
                      <Typography sx={{ color: "black", fontSize: "14px" }}>
                        {license.phoneNum}
                      </Typography>
                    ) : (
                      <Typography sx={{ textAlign: "center", width: "28%" }}>
                        -
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      // gap: "3px",
                    }}
                  >
                    Created At:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {validDate.getDate()}{" "}
                      {validDate.toDateString().split(" ")[1]}{" "}
                      {validDate.getFullYear()}
                    </Typography>
                  </Typography>
                </Popover>
              </TableCell>
            </>
          )}
        </TableRow>
      </Slide>
      <TableRow
        sx={{
          background: "white !important",
          boxShadow: "0px 1px 4px 0px grey",
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                sx={{
                  paddingLeft: "12px",
                  fontWeight: "bold",
                  fontSize: "20px",
                  paddingTop: "7px",
                  background: "#F9FAFC",
                }}
              >
                LICENCE LIST OF {license?.firstName?.toUpperCase()}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "3%",
                  paddingTop: "0.5%",
                  paddingLeft: "1%",
                  background: "#F9FAFC",
                  height: "52px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <LicenseTokenIcon sx={{ "color": "blue" }} fontSize='large' /> */}
                  <Typography
                    className="headerTxt"
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#687182",
                    }}
                  >
                    Allocated Licences: {license?.licenseList?.allocated}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="headerTxt"
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#687182",
                    }}
                  >
                    Unallocated Licences: {license?.licenseList?.unallocated}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="headerTxt"
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#687182",
                    }}
                  >
                    Expired Licences: {license?.licenseList?.expired ?? 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="headerTxt"
                    variant="h3"
                    component="h2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#687182",
                    }}
                  >
                    Total Licences: {license?.licenseList?.total ?? 0}
                  </Typography>
                </Box>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: " #171C26",
                        fontWeight: "600",
                      }}
                    >
                      LICENCE NO
                    </TableCell>
                    <TableCell
                      sx={{
                        color: " #171C26",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      LICENCE TYPE&nbsp;
                    </TableCell>

                    <TableCell
                      sx={{
                        color: " #171C26",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      LICENCE KEY&nbsp;
                    </TableCell>

                    <TableCell
                      sx={{ color: " #171C26", fontWeight: "600" }}
                      align="left"
                    >
                      EMAIL&nbsp;
                    </TableCell>

                    <TableCell
                      sx={{ color: " #171C26", fontWeight: "600" }}
                      align="left"
                    >
                      STATUS&nbsp;
                    </TableCell>
                    <TableCell
                      sx={{
                        color: " #171C26",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      MODULE
                    </TableCell>

                    <TableCell
                      sx={{ color: " #171C26", fontWeight: "600" }}
                      colSpan="3"
                      align="center"
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {license.licenseList.licenseList.map((license, index) => {
                    // if (license.status === "Active") {
                    //   return (license.status = "Allocated");
                    // }
                    return (
                      <TableRowWithData
                        // renewSnack={renewSnack}
                        // setRenewSnack={setRenewSnack}
                        setLicenseAl_CTX={setLicenseAl_CTX}
                        setLicenseList_CTX={setLicenseList_CTX}
                        key={index}
                        index={index}
                        license={license}
                        // getPricingData={getPricingData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
    // Table body ui end
  );
}
