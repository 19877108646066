import { useContext, useEffect, useState } from "react";
import OtpField from "react-otp-field";

import {
  Box,
  Typography,
  CircularProgress,
  //   Container,
  Alert,
  Snackbar,
  useMediaQuery,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { resendVerifyCode, verifyOtp } from "../../services/user-controller";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import { ApplicationContext } from "../../context/applicationCtx";
import { ButtonOutlined } from "../../constants/constStyles";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { LoaderSpinner } from "../../components/loader/loader";
// import { ApplicationContext } from '../../context/applicationCtx';

const otpSize = 6;

const MailLink = function (props) {
  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <Box sx={{ display: "block", ...props.sx }}>
        <Box>{props.children}</Box>

        <Typography
          variant="subtitle2"
          component="span"
          sx={{ fontWeight: "light", width: "56%" }}
        >
          Open {props.service}
        </Typography>
      </Box>
    </a>
  );
};

export default function OtpStep(props) {
  const verifyQuery = useMediaQuery("(max-width:453px)");
  const navigate = useNavigate();
  const [OTP, setStepsOTP] = useState({ value: "" });
  const [getLoaderState, setLoaderState] = useState(false);
  const [error, setError] = useState("");
  const [snack, setSnack] = useState(false);
  const [getLoaderStateResend, setLoaderStateResend] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const { setUserModuleLogin, loginToken, usernameLogin,setUsernameLogin, setActiveModule } =
    useContext(ApplicationContext);
  // const {getPassword_CTX} = useContext(ApplicationContext);

  useEffect(() => {
    if (localStorage.getItem("verify") !== "yes") {
      navigate("/login");
    }
  }, [navigate]);

  const onOTPEntered = function (otp) {
    setStepsOTP({ value: otp });
  };

  async function resendPassword() {
    setLoaderStateResend(true);
    console.log(usernameLogin, "userrr");
    try {
      let response = await resendVerifyCode(
        {
          usernameLogin: usernameLogin,
        },
        loginToken
      );
      console.log(response);
      if (response.status === StatusCodes.FORBIDDEN) {
        setLoaderStateResend(false);
        setError(`Verification code is sent to your email`);
        setSnack(true);
        setTimeout(() => {
          setSnack(false);
        }, 3000);
        setResendCode(true);
        setTimeout(() => {
          setResendCode(false);
        }, 30000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (OTP.value.length === otpSize && !getLoaderState) {
      const verifyCode = async function () {
        setLoaderState(true);
        console.log("res");
        try {
          let res = await verifyOtp({ verifyToken: OTP.value }, loginToken);
          console.log("resoooooo");

          if (res.status === StatusCodes.OK) {
            localStorage.setItem("api-key", loginToken);
            setActiveModule(0);
            navigate("/licence");
            setUserModuleLogin(0);
            setUsernameLogin(null)
          }
          console.log(res, "ress");
          if (res.status === StatusCodes.INTERNAL_SERVER_ERROR) {
            setLoaderState(false);
            setStepsOTP({
              value: "",
              hasError: true,
              errorMsg:
                "That code wasn’t valid. Have another go or resend code!",
            });
          }
          if (res.status === StatusCodes.BAD_REQUEST) {
            setLoaderState(false);
            setStepsOTP({
              value: "",
              hasError: true,
              errorMsg:
                "That code wasn’t valid. Have another go or resend code!",
            });
          }
        } catch (e) {
          console.log(e, "resperror");
        }
      };
      verifyCode();
    }
  }, [
    OTP,
    getLoaderState,
    setUserModuleLogin,
    navigate,
    loginToken,
    setActiveModule,
    setUsernameLogin
  ]);

  return (
    <>
      {getLoaderStateResend ? <LoaderSpinner /> : ""}
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "23px",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", padding: "13px" }}>
          <ButtonOutlined
            onClick={() => {
              localStorage.clear();
              setUserModuleLogin(0);
            }}
            sx={{}}
            variant="outlined"
          >
            <ArrowBackIcon />
            Back
          </ButtonOutlined>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={verifyQuery ? "h4" : "h3"}
            component="h2"
            sx={{ fontWeight: "medium", width: "55%" }}
            mt={verifyQuery ? 3 : 6}
          >
            Please check your email
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            // variant="subtitle1"
            component="h2"
            sx={{ fontWeight: "light", width: "56%" }}
          >
            We have send 6 digit verification code to your email. Please provide
            this code below to verify your account
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OtpField
            value={OTP.value}
            onChange={onOTPEntered}
            numInputs={otpSize}
            onChangeRegex={/^([0-9]{0,})$/}
            autoFocus
            separator={<span>-</span>}
            isTypeNumber
            hasErrored={OTP.hasError}
            inputProps={{
              className: "otp-field__input",
              disabled: getLoaderState,
            }}
            sx={{ justifyContent: "center" }}
          />
        </Box>
        {getLoaderState ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{ fontWeight: "light", width: "56%" }}
            >
              Checking OTP. Please wait.....{" "}
              <CircularProgress size="1.2em" sx={{ ml: 2 }} />
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {OTP.hasError ? (
            <Alert
              severity="error"
              sx={{
                padding: "0px 13px 0px 13px",
                alignItems: "center",
                height: "54px",
              }}
            >
              {OTP.errorMsg}
            </Alert>
          ) : (
            ""
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MailLink service="Gmail" link="https://mail.google.com/mail/u/0">
            <svg
              width={256 / 6}
              height={193 / 6}
              viewBox="0 0 256 193"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_194_21)">
                <path
                  d="M58.182 192.05V93.1399L27.507 65.0769L0 49.5039V174.595C0 184.253 7.825 192.05 17.455 192.05H58.182V192.05Z"
                  fill="#4285F4"
                />
                <path
                  d="M197.818 192.051H238.545C248.204 192.051 256 184.225 256 174.596V49.5059L224.844 67.3429L197.818 93.1409V192.051V192.051Z"
                  fill="#34A853"
                />
                <path
                  d="M58.1818 93.1399L54.0078 54.4929L58.1818 17.5039L128 69.8679L197.818 17.5039L202.488 52.4959L197.818 93.1399L128 145.504L58.1818 93.1399Z"
                  fill="#EA4335"
                />
                <path
                  d="M197.818 17.5037V93.1397L256 49.5037V26.2307C256 4.64569 231.36 -7.65931 214.11 5.28569L197.818 17.5037Z"
                  fill="#FBBC04"
                />
                <path
                  d="M0 49.5042L26.759 69.5742L58.182 93.1402V17.5041L41.89 5.28615C24.61 -7.65985 0 4.64615 0 26.2301V49.5031V49.5042Z"
                  fill="#C5221F"
                />
              </g>
              <defs>
                <clipPath id="clip0_194_21">
                  <rect width="256" height="193" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </MailLink>

          <MailLink
            service="Outlook"
            link="https://outlook.live.com/mail/0/inbox"
            sx={{ ml: 10 }}
          >
            <svg
              width={193 / 6}
              height={193 / 6}
              viewBox="0 0 193 193"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M117.516 47.873V80.9062L129.072 88.1739C129.489 88.2656 129.921 88.2656 130.338 88.1739L180.023 54.6763C179.987 53.0506 179.392 51.4869 178.339 50.248C177.286 49.0091 175.838 48.1704 174.24 47.873H117.516Z"
                fill="#0072C6"
              />
              <path
                d="M117.51 93.2259L128.047 100.463C128.54 100.764 129.106 100.923 129.684 100.923C130.262 100.923 130.828 100.764 131.322 100.463C129.512 101.555 180.012 68.0273 180.012 68.0273V128.738C180.143 129.968 180.005 131.212 179.605 132.383C179.206 133.554 178.556 134.624 177.7 135.517C176.844 136.41 175.803 137.106 174.65 137.555C173.497 138.004 172.26 138.196 171.025 138.117H117.504V93.2259H117.51Z"
                fill="#0072C6"
              />
              <path
                d="M62.964 77.9945C61.2133 77.986 59.4929 78.4514 57.9852 79.3412C56.4774 80.2309 55.2385 81.512 54.3996 83.0487C52.1084 87.122 51.0091 91.7576 51.2272 96.426C50.989 101.085 52.0899 105.714 54.3996 109.767C55.235 111.24 56.4434 112.468 57.9035 113.326C59.3636 114.185 61.0239 114.644 62.7176 114.657C64.4113 114.671 66.0787 114.238 67.5522 113.403C69.0257 112.568 70.2535 111.36 71.1122 109.9C73.3967 105.866 74.4748 101.26 74.2183 96.631C74.4799 91.8566 73.4375 87.101 71.2027 82.8737C70.404 81.3872 69.2145 80.1471 67.7626 79.2872C66.3106 78.4273 64.6514 77.9803 62.964 77.9945V77.9945Z"
                fill="#0072C6"
              />
              <path
                d="M12.9824 31.0911V160.323L111.292 180.938V12.0625L12.9824 31.0911ZM78.7713 117.555C76.9323 120.148 74.4869 122.251 71.6486 123.682C68.8102 125.113 65.6649 125.827 62.4869 125.764C59.3889 125.819 56.3235 125.125 53.5513 123.741C50.7791 122.357 48.3822 120.324 46.5644 117.814C42.2532 111.795 40.0965 104.499 40.4427 97.1031C40.076 89.3448 42.2716 81.6812 46.6911 75.2941C48.5533 72.652 51.037 70.5086 53.9231 69.053C56.8093 67.5973 60.0092 66.874 63.2408 66.9469C66.3164 66.8852 69.36 67.5793 72.1046 68.9685C74.8493 70.3576 77.211 72.3992 78.9824 74.9142C83.2505 81.0638 85.3705 88.4499 85.0136 95.927C85.3896 103.629 83.1936 111.238 78.7713 117.555Z"
                fill="#0072C6"
              />
            </svg>
          </MailLink>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ fontWeight: "light" }}
          >
            Can’t find your code? Check your spam folder!
          </Typography>
          {resendCode ? (
            <Typography
              className="resendTxt"
              sx={{
                // display: "none",
                marginRight: "24px",
                color: "#4B4BF6",
                display: "flex",
                fontSize: "12px",
              }}
              //   mt={3}
              variant="subtitle1"
              component="h2"
            >
              wait for{" "}
              {resendCode ? (
                <Box ml={0.5} mr={0.5} sx={{ alignSelf: "center" }}>
                  <CountdownCircleTimer
                    size={16}
                    strokeWidth={0}
                    isPlaying
                    duration={30}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[7, 5, 2, 0]}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </Box>
              ) : (
                ""
              )}{" "}
              seconds to resend code...
            </Typography>
          ) : (
            <Typography
              onClick={() => resendPassword()}
              sx={{
                marginRight: "24px",
                color: "#4B4BF6",
                cursor: "pointer",
                fontSize: "12px",
                // display: "none",
              }}
              //   mt={3}
              variant="subtitle1"
              component="h2"
            >
              Resend Code?
            </Typography>
          )}
        </Box>
        <Box>
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            open={snack}
            message={error}
          />
        </Box>
      </Box>
    </>
  );
}
