import { _isNull } from './dataValidation';
import { API_METHOD } from '../constants/api';

export class ContentType {
    static json = "application/json";
    static formData = "multipart/form-data";
}

export class HttpRequest{
    constructor(data){
        try{
         
            let errorList = [];

            //!Required 
            this.method = !_isNull(data.method) ? data.method : errorList.push({key:"method", error:'method is required'});
            this.url = !_isNull(data.url) ? data.url : errorList.push({key: "url", error:'url is required'});
            this.body = !_isNull(data.body) ? data.body : errorList.push({ key: "body", error: 'body is required' });
            this.headers = data.headers;
            this.contentType = !_isNull(data.contentType) ? data.contentType : ContentType.json;
            this.isAuthenticated =  data.isAuthenticated;
            if(errorList.length > 0){
                new Error(errorList);
            }


            

        }catch(e){
            console.error(e);
        }
    }


    async sendRequest(){
        try{
            
            let res = null;
            if (this.method === API_METHOD.get) {
                let myHeaders = new Headers();
                if(localStorage.getItem('api-key') === null){
                    myHeaders.append('Authorization',null)
                    // console.log('nulls')
                }
                if(this.isAuthenticated){
                    
                    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('api-key')} `)
                    // console.log('yesauth')
                }
                // console.log(myHeaders.get("Authorization"),'auth')
                res = await fetch(this.url, {
                    method: this.method,
                    headers: myHeaders,
                });
            } else {
                
                let myHeaders = new Headers();
                if(this.isAuthenticated){

                    myHeaders.append('Authorization',`Bearer ${localStorage.getItem('api-key')} `)
                }
               
                myHeaders.append('content-type', this.contentType);
                
                if(this.contentType === ContentType.formData){
                    console.log(this.contentType,"content");
                    let formData = new FormData();
                    let keys = Object.keys(this.body);
                    // console.log(keys, 'k');
                    // console.log(this.body, 't')

                    keys.forEach((key)=>{
                        formData.append(key, this.body[key]);
                    });

                    console.log(formData, "formData", this.contentType)

                    res = await fetch(this.url, {
                        method: this.method,
                        body: formData,
                        headers: {
                            Authorization : `Bearer ${localStorage.getItem('api-key')}` ,
                          },
                        // headers: myHeaders,
                    });

                }else{
                    console.log("head",myHeaders);
                    res = await fetch(this.url, {
                        method: this.method,
                        body: JSON.stringify(this.body),
                        headers: myHeaders,
                    });

                }
                
            }

            let data = await res.json();

            return {data, status: res.status};
        }catch(e){
            console.error(e);
            return null;
        }
    }

}