export default function Page404(props) {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <p
          style={{
            fontSize: "60px",
            width: "130px",
            fontWeight: "bold",
            borderRight: "2px solid #4B4BF6",
            color: "#4B4BF6",
          }}
        >
          404
        </p>
        <p>PAGE NOT FOUND</p>
      </div>
    </>
  );
}
