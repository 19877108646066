import styled from "@emotion/styled";
import { Button, Dialog, TextField } from "@mui/material";

export const text_col = {
  col_primary: "#8C57F2",
  col_secondary: "#FFFF",
};

export const ButtonMaterial = styled(Button)(({ theme }) => ({
  "&:hover": { background: text_col.col_primary },
  background: text_col.col_primary,
  color: text_col.col_secondary,
}));

export const ButtonOutlined = styled(Button)(({ theme }) => ({
  borderColor: text_col.col_primary,
  color: text_col.col_primary,
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  backdropFilter: "blur(3px)",
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const CssTextField = styled(TextField)({
  /* Apply custom styles on component */
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "#212121",
  // },
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "#E8E9EB",
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "#212121",
  //   },
  //   "&.Mui-focused fieldset": {
  //     border: "1px solid #212121",
  //   },
  // },
});

export const TextFieldTA = styled(TextField)({
  "& label.Mui-focused": {
    color: text_col.col_primary,
  },
  "& .MuiInput-underline:after": {
    color: text_col.col_primary,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9F9F9",

    "& fieldset": {
      borderColor: "#F9F9F9",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "rgb(233, 222, 255) !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(233, 222, 255) !important",
      // border:"3",
      color: "red",
    },
  },
});
