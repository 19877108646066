import {
  Box,
  Typography,
  CircularProgress,
  Link,
  Snackbar,
  InputAdornment,
  Checkbox,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { useState } from "react";
import { StatusCodes } from "http-status-codes";
import { RegisterUser } from "../../services/user-controller";
import validator from "validator";
// import { FooterSteps } from "../../components/footer/footer";
// import { ApplicationContext } from "../../context/applicationCtx";
import ReCAPTCHA from "react-google-recaptcha";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  ButtonMaterial,
  TextFieldTA,
  text_col,
} from "../../constants/constStyles";
import { useRef } from "react";
import { useEffect } from "react";

export default function Register() {
  const registerQuery = useMediaQuery("(max-width:1193px)");
  const registerQueryBreak = useMediaQuery("(max-width:941px)");
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const [getLoaderState, setLoaderState] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [organisationName, setOrganisationName] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [error, setError] = useState();
  const [registerSnack, setRegisterSnack] = useState();
  const [transition, setTransition] = useState();
  const [ setRecaptcha] = useState();
  const [countryCode, setCountryCode] = useState({
    code: "GB",
    label: "United Kingdom",
    phone: "44",
  });
  const [passwordIcon, setPasswordIcon] = useState(false);
  const [confirmPasswordIcon, setconfirmPasswordIcon] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [registerChange, setRegisterChange] = useState({
    email: "",
    firstName: "",
    lastName: "",
    organisationName: "",
    password: "",
    phoneNo: "",
  });
  // const { setUserModule } = useContext(ApplicationContext);
  console.log(registerChange);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflowX = "hidden";
  }, []);

  const routeSignin = () => {
    navigate("/login");
    // setUserModule(0);
  };
  console.log(`${countryCode?.phone}${phoneNo}`);
  const verifyInputs = async function () {
    let respo;

    if (!password) {
      setPassword("");
    }
    if (!confirmPassword) {
      setconfirmPassword("");
    }
    if (!firstName) {
      setFirstName("");
    }
    if (!lastName) {
      setLastName("");
    }
    if (!organisationName) {
      setOrganisationName("");
    }
    if (!email) {
      setEmail("");
    }
    if (
      password === confirmPassword &&
      validator.isStrongPassword(password) &&
      validator.isEmail(email) &&
      firstName &&
      lastName &&
      organisationName &&
      countryCode.phone &&
      // recaptcha &&
      checkBox &&
      (validator.isMobilePhone(phoneNo ?? "") || !phoneNo)
    ) {
      console.log("api called");
      try {
        setLoaderState(true);

        let response = await RegisterUser({
          email: email,
          firstName: firstName,
          lastName: lastName,
          organisationName: organisationName,
          password: password,
          phoneNum: phoneNo ? `${countryCode?.phone}${phoneNo}` : "",
        });
        respo = response;
        if (!response) {
          // alert('Something went wrong!')
          setError(`**Unable to connect to server!**`);
          setLoaderState(false);
        } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
          setError("**Fields cant be empty**");
          setLoaderState(false);
        } else if (respo.status === StatusCodes.OK) {
          //* Login User In

          setLoaderState(false);
          const setSnack = (Transition) => {
            setTransition(() => Transition);
            setRegisterSnack(true);
          };
          setSnack();
          setTimeout(() => {
            setRegisterSnack(false);
            navigate("/login");
            // setUserModule(0);
          }, 3000);
        } else {
          throw response;
        }
      } catch (err) {
        // console.log("ERR =>",err);
        setLoaderState(false);
        if (err.data) {
          // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
        } else {
          if (err.errorMsg) {
            // setStepLicenseKey(err)
            console.log(err.errorMsg);
          } else {
            console.error("UNKNOWN ERROR", err);
          }
        }
      }
      if (validator.isEmail(email)) {
        setError("");

        if (respo.status === StatusCodes.BAD_REQUEST) {
          setError("Email Already Used! Try Another ");
          setLoaderState(false);
        }
        setLoaderState(false);
      } else {
        // setError("Email Not Valid!");
      }
    } else {
      setError("");
    }
  };
  // console.log(password)
  const handleFirstName = (e) => {
    setFirstName(e.target.value.replace(/[^A-Za-z]/gi, ""));
  };
  console.log(firstName);
  const handleLastName = (e) => {
    setLastName(e.target.value.replace(/[^A-Za-z]/gi, ""));
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleOrganisationName = (e) => {
    setOrganisationName(e.target.value);
  };
  const handlePhoneNo = (e) => {
    setPhoneNo(e.target.value.replace(/[^0-9|^+]/gi, ""));
  };

  console.log(email, "emaillog");

//   let paragraph = [
//     {
//       header: "Solitera Test Automation ",
//       para: `All-in-one platform for automation across web, desktop, and mobile interfaces.”  Seamless Test Automation Without Coding Begin your 2-month complimentary trial and discover a code-free automation solution tailored for comprehensive testing. No credit card or prior test automation expertise required.
// Get started swiftly! Packed with cutting-edge capabilities like "auto script creation," "self-healing during execution," and "auto element generation."`,
//     },
//   ];
  return (
    <>
      <Box
        className="mainBoxReg"
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: registerQueryBreak ? "column" : "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#FAFAFA",
          // gap: "1%",
          // paddingTop: "1%",
        }}
      >
        <Box
          className="mainBoxReg"
          sx={{
            width: registerQueryBreak ? "91%" : "77%",
            maxHeight: "96%",
            display: "flex",
            flexDirection: registerQueryBreak ? "column" : "row-reverse",
            alignItems: "center",
            background: "white",
            borderRadius: "19px",
            overflow: "hidden",
            boxShadow: "0px 1px 22px 0px #e9e9e9",
            // gap: "1%",
            position: "relative",
            // paddingTop: "1%",
          }}
        >
          <Box
            display="flex"
            width={registerQueryBreak ? "97%" : "55%"}
            // borderLeft={registerQueryBreak ? "0" : "2px solid #d5d1d1"}
            height="100%"
            gap="14px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            padding="46px 0px"
          >
            {" "}
            <Box
              display="flex"
              width={registerQueryBreak ? "97%" : "70%"}
              height={registerQueryBreak ? "95%" : "87%"}
              gap="16px"
              flexDirection="column"
              alignItems="center"
              // justifyContent="center"
            >
              <Box
                display="flex"
                // flexDirection="column"
                gap="12px"
                alignItems="center"
                // justifyContent="center"
              >
                <Typography
                  variant="h3"
                  component="h2"
                  sx={{
                    fontWeight: "medium",
                    fontSize: "32px",
                    color: text_col.col_primary,
                    lineHeight: "21px",
                  }}
                >
                  Sign up
                </Typography>
              </Box>
              {/* <Typography
          className="regHeadTxt"
          variant="h3"
          component="h2"
          sx={{
            fontWeight: "medium",
            fontSize: "41px",
            textAlign: "center",
            lineHeight: "32px",
          }}
        >
          Please enter your details
        </Typography> */}
              {/* <Typography
          variant="subtitle1"
          component="h2"
          sx={{ fontWeight: "light", fontSize: "13px", textAlign: "center" }}
        >
          We suggest using the email address that you use at work.{" "}
        </Typography> */}
              <Typography
                variant="subtitle1"
                component="h2"
                sx={{
                  fontSize: "10px",
                  color: "red",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                  // minWidth: "350px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  <TextFieldTA
                    size="small"
                    autoComplete="name"
                    // className="text"
                    sx={{ width: "100%" }}
                    fullwidth
                    value={registerChange?.firstName.replace(/[^A-Za-z]/gi, "")}
                    error={
                      firstName === null
                        ? false
                        : firstName === ""
                        ? true
                        : firstName?.length < 3
                        ? true
                        : false
                    }
                    helperText={
                      <Typography sx={{ fontSize: "10px", color: "red" }}>
                        {firstName === null
                          ? ""
                          : firstName === ""
                          ? "First name is required"
                          : firstName?.length < 3
                          ? "Minimum length 3-characters"
                          : ""}
                      </Typography>
                    }
                    type="text"
                    placeholder="First Name *"
                    variant="outlined"
                    onBlur={handleFirstName}
                    onChange={(e) =>
                      setRegisterChange({
                        ...registerChange,
                        firstName: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                  />
                  <TextFieldTA
                    size="small"
                    // className="text"
                    value={registerChange?.lastName.replace(/[^A-Za-z]/gi, "")}
                    sx={{ width: "100%" }}
                    error={
                      lastName === null ? false : lastName === "" ? true : false
                    }
                    helperText={
                      <Typography sx={{ fontSize: "10px", color: "red" }}>
                        {lastName === null
                          ? ""
                          : lastName === ""
                          ? "Last name is required"
                          : ""}
                      </Typography>
                    }
                    fullwidth
                    placeholder="Last Name *"
                    variant="outlined"
                    onBlur={handleLastName}
                    onChange={(e) =>
                      setRegisterChange({
                        ...registerChange,
                        lastName: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                  />
                </Box>

                <TextFieldTA
                  size="small"
                  ref={emailRef}
                  // className="organisationField"
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  // sx={{ width: "14.4%" }}
                  // sx={{ width: registerQuery ? "79%" : "29.4%" }}
                  fullwidth
                  placeholder="Work Email *"
                  error={
                    email === null
                      ? false
                      : email === ""
                      ? true
                      : !validator.isEmail(email)
                      ? true
                      : false
                  }
                  helperText={
                    <Typography sx={{ fontSize: "10px", color: "red" }}>
                      {email === null
                        ? ""
                        : email === ""
                        ? "Email is required"
                        : !validator.isEmail(email)
                        ? "Please enter a valid email"
                        : ""}
                    </Typography>
                  }
                  variant="outlined"
                  onBlur={handleEmail}
                  onChange={(e) => {
                    setRegisterChange({
                      ...registerChange,
                      email: e.target.value,
                    });
                  }}
                />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  <TextFieldTA
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    ref={passRef}
                    size="small"
                    sx={{ width: "100%" }}
                    // fullwidth
                    // className="text"
                    type={passwordIcon ? "text" : "password"}
                    required
                    error={
                      password === null
                        ? false
                        : password === ""
                        ? true
                        : password !== null &&
                          !validator.isStrongPassword(password)
                        ? true
                        : false
                    }
                    helperText={
                      password === null
                        ? ""
                        : password === ""
                        ? "Password is required"
                        : password !== null &&
                          !validator.isStrongPassword(password)
                        ? "Password min 8-char, 1-upper & lowercase, 1-symbol, 1-number"
                        : ""
                    }
                    onBlur={handlePassword}
                    onChange={(e) =>
                      setRegisterChange({
                        ...registerChange,
                        password: e.target.value,
                      })
                    }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {passwordIcon ? (
                            <VisibilityOff
                              sx={{ cursor: "pointer" }}
                              onClick={() => setPasswordIcon(false)}
                            />
                          ) : (
                            <Visibility onClick={() => setPasswordIcon(true)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Password"
                  />
                  <TextFieldTA
                    size="small"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    // className="text"
                    type={confirmPasswordIcon ? "text" : "password"}
                    required
                    sx={{ width: "100%" }}
                    // fullwidth
                    error={
                      confirmPassword === null
                        ? false
                        : confirmPassword === ""
                        ? true
                        : confirmPassword !== password
                        ? true
                        : false
                    }
                    helperText={
                      confirmPassword === null
                        ? ""
                        : confirmPassword === ""
                        ? "Confirm password is required"
                        : confirmPassword !== password
                        ? "Password doesn't match"
                        : ""
                    }
                    onBlur={(e) => {
                      setconfirmPassword(e.target.value);
                      setEmail(registerChange.email);
                      // console.log(confirmPassword);
                      setPassword(registerChange.password);
                    }}
                    onChange={(e) =>
                      setRegisterChange({
                        ...registerChange,
                        confirmPassword: e.target.value,
                      })
                    }
                    // console.log(confirmPassword);

                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {confirmPasswordIcon ? (
                            <VisibilityOff
                              sx={{ cursor: "pointer" }}
                              onClick={() => setconfirmPasswordIcon(false)}
                            />
                          ) : (
                            <Visibility
                              sx={{ cursor: "pointer" }}
                              onClick={() => setconfirmPasswordIcon(true)}
                            />
                          )}
                          {/* </IconButton> */}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Confirm Password"
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  <Autocomplete
                    id="country-select-demo"
                    // sx={{ width: 300 }}
                    // fullWidth
                    sx={{ width: "50%" }}
                    disableClearable
                    value={countryCode}
                    // getOptionSelected={(option, value) => option.phone === value.phone}
                    // valuek
                    getOptionplaceholder={(option) => option?.label}
                    getOptionValue={(option) => option?.phone}
                    isOptionEqualToValue={(option, value) =>
                      option?.phone === value?.phone
                    }
                    onChange={(event, val) => {
                      setCountryCode(val);
                    }}
                    componentsProps={{
                      popper: { style: { width: "400px" } },
                    }}
                    size="small"
                    options={countries}
                    autoHighlight
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option?.code}) +{option?.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextFieldTA
                        error={!countryCode && phoneNo ? true : false}
                        helperText={
                          !countryCode && phoneNo ? "Country name required" : ""
                        }
                        {...params}
                        placeholder="Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <TextFieldTA
                    size="small"
                    // className="organisationField"
                    sx={{ width: "50%" }}
                    fullwidth
                    autoComplete="new-password"
                    error={
                      phoneNo === null
                        ? false
                        : phoneNo === ""
                        ? false
                        : !validator.isMobilePhone(phoneNo)
                        ? true
                        : false
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {countryCode?.phone ? "+" : ""}
                          {countryCode?.phone}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <Typography sx={{ fontSize: "10px", color: "red" }}>
                        {phoneNo === null
                          ? ""
                          : phoneNo === ""
                          ? ""
                          : !validator.isMobilePhone(phoneNo)
                          ? "Phone number is not valid"
                          : ""}
                      </Typography>
                    }
                    placeholder="Phone No"
                    variant="outlined"
                    onBlur={handlePhoneNo}
                    onChange={(e) =>
                      setRegisterChange({
                        ...registerChange,
                        phoneNo: e.target.value.replace(/[^0-9|^+]/gi, ""),
                      })
                    }
                    value={registerChange?.phoneNo.replace(/[^0-9|^+]/gi, "")}
                  />
                </Box>

                <TextFieldTA
                  size="small"
                  // className="organisationField"
                  // sx={{ width: registerQuery ? "79%" : "29.4%" }}
                  fullwidth
                  placeholder="Company*"
                  error={
                    organisationName === null
                      ? false
                      : organisationName === ""
                      ? true
                      : organisationName?.length < 2
                      ? true
                      : false
                  }
                  helperText={
                    <Typography sx={{ fontSize: "10px", color: "red" }}>
                      {organisationName === null
                        ? ""
                        : organisationName === ""
                        ? "Companty name is required"
                        : organisationName?.length < 2
                        ? "Minimum length 2-characters"
                        : ""}
                    </Typography>
                  }
                  variant="outlined"
                  onBlur={handleOrganisationName}
                  onChange={(e) =>
                    setRegisterChange({
                      ...registerChange,
                      organisationName: e.target.value,
                    })
                  }
                />
                <Box
                  className="checkBoxReg"
                  sx={{
                    // width: registerQuery ? "73%" : "29.4%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: registerQuery ? "5%" : "2%",
                    gap: "0.2%",
                  }}
                >
                  <Checkbox
                    size="small"
                    color="default"
                    sx={{ width: "1%", color: "#9428FF" }}
                    checked={checkBox}
                    disableRipple
                    onChange={(e) => setCheckBox(e.target.checked)}
                  />
                  <Box
                    onClick={() => {
                      setCheckBox(!checkBox);
                      console.log("yess");
                      console.log(checkBox);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        width: "100%",
                        color: "#9428FF",
                        display: "flex",
                        gap: "4px",
                        flexWrap: "wrap",
                      }}
                    >
                      By clicking here you are agree with{" "}
                      <a
                        href="https://soliterata.com/terms-conditions/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#9428FF" }}
                      >
                        <Typography
                          sx={{
                            borderBottom: "1px dotted #9428FF",
                            fontSize: "12px",
                          }}
                        >
                          terms & conditions.
                        </Typography>
                      </a>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0.5%",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "35px",
                  }}
                  // pt={4}
                >
                  <ReCAPTCHA
                    sitekey="6LeVrkUpAAAAAJL7nMGr_7JqITswCQrxHlmxLLkJ"
                    onChange={(value) => {
                      setRecaptcha(value);
                    }}
                    theme="light"
                    size="invisible"
                  />
                  <ButtonMaterial
                    // className="regButton"
                    disabled={getLoaderState ? true : false}
                    variant="contained"
                    disableElevation
                    fullWidth
                    sx={{ height: "45px" }}
                    onClick={() => {
                      verifyInputs();
                    }}
                  >
                    Continue{" "}
                    {getLoaderState ? (
                      <CircularProgress
                        size="1.2em"
                        sx={{
                          position: "absolute",
                          right: "0",
                          mr: 2,
                          color: "white",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </ButtonMaterial>
                </Box>
                <Typography
                  variant="subtitle1"
                  component="h2"
                  sx={{
                    fontWeight: "light",
                    display: "flex",
                    whiteSpace: "nowrap",
                    gap: "2%",
                    zIndex: 1,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Already registered?{" "}
                  <Link>
                    <Typography
                      onClick={routeSignin}
                      color="secondary"
                      variant="subtitle1"
                      component="h2"
                      sx={{ fontWeight: "light", cursor: "pointer" }}
                    >
                      Login
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            </Box>
            {/* <Box
          className="regFooter"
          sx={{ width: "100%", flex:1,display:'flex',position:"sticky",bottom:'0px',backdropFilter:'blur(9px)',zIndex:100 }}
        >
          <FooterSteps />
        </Box> */}
            {/* </Box> */}
          </Box>
          <Box
            display={registerQueryBreak ? "none" : "flex"}
            width={registerQueryBreak ? "90%" : "45%"}
            height="100%"
            justifyContent="center"
            flexDirection="column"
            alignItems={"center"}
            position="absolute"
            backgroundColor={text_col.col_primary}
            left="0px"
            // sx={{backgroundImage:'url(https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-3.svg)',backgroundSize:'cover'}}
          >
            <a
              rel="noopener noreferrer"
              target={"_blank"}
              href="https://soliterata.com/"
            >
              <img
                width="190"
                height="190"
                style={{
                  borderRadius: "6px",
                  zIndex: 2,
                  position: "absolute",
                  left: "12px",
                  top: "-60px",
                }}
                src="https://soliterata.com/wp-content/uploads/2024/01/Untitled-design14.svg"
                alt="SoliteraTA"
                loading="lazy"
                // srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>
            <img
            alt='SoliteraTA'
              src="https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-3.svg"
              width={"100%"}
              height="100%"
            />
            <Typography
              color="white"
              fontSize="12px"
              sx={{ cursor: "pointer" }}
              position="absolute"
              bottom="18px"
              left="30px"
              onClick={() => window.open("https://soliterata.com/", "__blank")}
            >
              www.soliterata.com
            </Typography>
            {/* <RegSvg /> */}
            {/* <RegSvgInverted inverted={true}/> */}
            {/* <Box
          display="flex"
          width="75%"
          height="23%"
          gap="34px"
          zIndex={200}
          // justifyContent="center"
          flexDirection="column"
          // alignItems="center"
        >
          
          <Box width="100%" display="flex" flexDirection="column" gap="34px">
            {paragraph.map((data, i) => {
              return (
                <>
                  <Box display="flex" flexDirection="column" gap="12px">
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{
                        fontSize: registerQuery ? "24px" : "33px",
                        // fontFamily: "Montserrat, sans-serif",
                        // color: ,
                        fontWeight: "bold",
                      }}
                    >
                      {data.header}
                    </Typography>
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        opacity: "0.7",
                        fontSize: registerQuery ? "13px" : "13px",
                        // fontFamily: "Montserrat, sans-serif",
                        // color: ,
                      }}
                    >
                      {data.para}
                    </Typography>
                  </Box>
                </>
              );
            })}
          </Box>
        </Box> */}
          </Box>

          <Snackbar
            style={{ justifyContent: "center", width: "100%" }}
            open={registerSnack}
            TransitionComponent={transition}
            message="Registered successfully! Redirecting to login"
            key={transition ? transition.name : ""}
            autoHideDuration={2000}
            bodyStyle={{ minWidth: 20 }}
          />
        </Box>
      </Box>
      {/* <Box sx={{width:'100%',height:'10%',background:'red',display:''}}> */}
    </>
  );
}

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },  
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];
